import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';

const LanguageContext = createContext();

// Mock translations
const translations = {
  en: {
    common: {
      appName: '247KAN',
      searchPlaceholder: 'Search for movies, TV shows, anime...',
      search: 'Search',
      browse: 'Browse',
      movies: 'Movies',
      tvShows: 'TV Shows',
      anime: 'Anime',
      myList: 'My List',
      profile: 'Profile',
      settings: 'Settings',
      logout: 'Logout',
      play: 'Play',
      moreInfo: 'More Info',
      like: 'Like',
      comment: 'Comment',
      share: 'Share',
      language: 'Language',
      toggleTheme: 'Toggle Theme',
      home: 'Home',
      showMore: 'Show More',
      showLess: 'Show Less',
      addFavorite: 'Add to Favorites',
      removeFavorite: 'Remove from Favorites',
      backToHome: 'Back to Home',
      viewAll: 'View All',
      loadMore: 'Load More',
      trending: 'Trending',
      announcement: 'Announcement',
      previous: 'Previous',
      next: 'Next',
      pleaseLoginFirst: 'Please login first to use this feature',
      loading: 'Loading...',
      turnstileRequired: 'Please complete the security verification',
      remove: 'Remove',
      delete: 'Delete',
      cancel: 'Cancel',
      clear: 'Clear',
      details: 'Details',
      source: 'Source',
      addToWatchLater: 'Add to Watch Later',
      removeFromWatchLater: 'Remove from Watch Later',
      guestbookError: 'Failed to submit your message. Please try again.',
      searchHistory: 'Search History',
    },
    filters: {
      trending: 'Trending',
      trendingMovies: 'Trending Movies',
      trendingTV: 'Trending TV Shows',
      top250: 'Douban Top 250 Movies',
      title: 'Filters',
      area: 'Area',
      language: 'Language',
      year: 'Year',
      version: 'Version',
      sortBy: 'Sort By',
      clearAll: 'Clear All Filters',
      search: 'Search',
      actor: 'Actor',
      director: 'Director',
      state: 'Type',
      letter: 'Letter',
      tag: 'Tag',
      genre: 'Genre',
      quickFilters: 'Quick Filters',
      basicFilters: 'Basic Filters',
      advancedFilters: 'Advanced Filters',
      sortOptions: 'Sort Options',
      sortBy: 'Sort By',
      clearAllFilters: 'Clear All Filters',
      filterCount: 'Filter',
      gridView: 'Grid View',
      listView: 'List View',
      resultsCount: '${count} results'
    },
    home: {
      trending: 'Trending Now',
      popularMovies: 'Popular Movies',
      popularShows: 'Popular TV Shows',
      popularAnime: 'Popular Anime',
      continueWatching: 'Continue Watching',
      hotRecommendations: 'Hot Recommendations',
      latestReleases: 'Latest Releases',
      loadError: 'Failed to load content. Please try refreshing the page.',
      watchNow: 'Watch Now',
      moreInfo: 'More Info',
      viewAll: 'View All',
    },
    browse: {
      browseTitle: 'Browse',
      all: 'All',
      sortBy: 'Sort By',
      genre: 'Genre',
      mostPopular: 'Most Popular',
      recentlyAdded: 'Recently Added',
      highestRated: 'Highest Rated',
      releaseYear: 'Release Year',
      categories: 'Categories',
      noContent: 'No content available',
      noVideosFound: 'No videos found',
      tryDifferentFilters: 'Try different filters or clear existing ones',
      results: 'results',
      latest: 'Latest',
      all: 'All',
      mostViewed: 'Most Viewed',
      byYear: 'By Year',
      clearFilters: 'Clear All Filters'
    },
    discovery: {
      title: 'Discover New Content',
      trending: 'Trending Now',
      topRated: 'Top Rated',
      upcoming: 'Coming Soon',
      viewMore: 'View More',
      relatedContent: 'Related Content',
      recommendedForYou: 'Recommended For You',
      popularInGenre: 'Popular in ${genre}',
      similarTo: 'Similar to ${title}',
      basedOnYourHistory: 'Based on Your History',
      newReleases: 'New Releases',
      apiKeyError: 'TMDB API key is not configured. Please set REACT_APP_TMDB_API_KEY in your environment.',
      fetchError: 'Failed to fetch movies: ${message}',
      noMoviesFound: 'No movies found',
      loading: 'Loading movies...',
      error: 'Error: ${message}',
      trending: {
        undefined: 'Trending Now'
      },
      topRated: {
        undefined: 'Top Rated'
      },
      upcoming: {
        undefined: 'Coming Soon'
      },
      movieType: 'Movies',
      tvType: 'TV Shows',
      genre: 'Genre',
      allGenres: 'All',
      sortBy: 'Sort By',
      tabs: {
        trending: 'Trending Now',
        topRated: 'Top Rated',
        upcoming: 'Coming Soon',
        discover: 'Discover'
      },
      sortOptions: {
        popularityDesc: 'Popularity (High to Low)',
        popularityAsc: 'Popularity (Low to High)',
        ratingDesc: 'Rating (High to Low)',
        ratingAsc: 'Rating (Low to High)',
        releaseDateDesc: 'Release Date (New to Old)',
        releaseDateAsc: 'Release Date (Old to New)',
        revenueDesc: 'Revenue (High to Low)',
        voteCountDesc: 'Vote Count (High to Low)'
      },
      loading: 'Loading content...',
      error: 'Error: ${message}',
      apiKeyError: 'TMDB API key is not configured',
      fetchError: 'Failed to fetch content: ${message}',
      noMoviesFound: 'No content found'
    },
    search: {
      searchTitle: 'Search',
      searchPlaceholder: 'Search for movies, TV shows, anime...',
      resultsFor: 'Results for',
      results: 'results',
      noResults: 'No results found for',
      tryDifferentSearch: 'This appears to be a network error. Please try refreshing the page and search again. If the problem persists, please report it in the guestbook.',
      filterAll: 'All',
      filterMovies: 'Movies',
      filterTVShows: 'TV Shows',
      filterAnime: 'Anime',
      filterRecent: 'Recently Added',
      search: 'Search',
      requestContent: 'Report Search Issue',
    },
    watch: {
      episodes: 'Episodes',
      description: 'Description',
      comments: 'Comments',
      recommendations: 'Recommendations',
      noComments: 'No comments yet',
      routeSelection: 'Route Selection',
      shareSuccess: 'Link copied to clipboard',
      shareFailed: 'Failed to share, please try again later',
      videoNotFound: 'Video not found',
      noPlayableUrl: 'No playable URL found',
      loadError: 'Failed to load video',
      writeComment: 'Write a comment...',
      postComment: 'Post Comment',
      anonymous: 'Anonymous',
      reply: 'Reply',
      replyPlaceholder: 'Write your reply...',
      submitting: 'Submitting...',
      enterVerificationCode: 'Enter verification code',
      clickToRefresh: 'Click to refresh',
      verificationCodeError: 'Failed to get verification code',
      invalidVerificationCode: 'Please enter verification code',
      autoplayNext: 'Autoplay Next Episode',
      autoplayEnabled: 'Autoplay enabled',
      autoplayDisabled: 'Autoplay disabled',
      showDescription: 'Show description',
      hideDescription: 'Hide description',
      sortBy: 'Sort By',
      newest: 'Newest',
      mostLiked: 'Most Liked',
    },
    movieDetail: {
      play: 'Play',
      plot: 'Plot',
      noPlot: 'No plot available',
      castAndCrew: 'Cast & Crew',
      director: 'Director',
      cast: 'Cast',
      status: 'Status',
      version: 'Version',
      episodes: 'Episodes',
      genres: 'Genres',
      stats: 'Statistics',
      views: 'Total Views',
      dailyViews: 'Daily Views',
      weeklyViews: 'Weekly Views',
      monthlyViews: 'Monthly Views',
      notFound: 'Movie not found',
      playNow: 'Play Now',
      director: 'Director',
      cast: 'Cast',
      episodes: 'Episodes',
      watchTrailer: 'Watch Trailer',
      addToFavorites: 'Add to Favorites',
      removeFromFavorites: 'Remove from Favorites',
      share: 'Share',
      showMore: 'Show More',
      showLess: 'Show Less',
      addedToWatchLater: 'Added to Watch Later',
      addToWatchLater: 'Add to Watch Later',
      removeFromWatchLater: 'Remove from Watch Later',
      showDescription: 'Show description',
      hideDescription: 'Hide description',
      sortBy: 'Sort By',
      newest: 'Newest',
      mostLiked: 'Most Liked',
      reportIssue: 'Report Issue',
      reportIssueTitle: 'Report Content Issue',
      reportIssueDescription: 'Found incorrect or missing information? Help us improve by reporting it.',
      reportIssueNote: "You'll be redirected to the guestbook where you can provide details about the issue.",
      confirmReport: 'Report Issue',
    },
    categories: {
      '电影': 'Movies',
      '连续剧': 'TV Series',
      '综艺': 'Variety Shows',
      '动漫': 'Animation',
      '短剧': 'Short Series',
      '记录片': 'Documentary',
      // Movie subcategories
      '动作片': 'Action Movies',
      '喜剧片': 'Comedy Movies',
      '爱情片': 'Romance Movies',
      '科幻片': 'Sci-Fi Movies',
      '恐怖片': 'Horror Movies',
      '剧情片': 'Drama Movies',
      '战争片': 'War Movies',
      // TV Series subcategories
      '国产剧': 'Chinese Dramas',
      '香港剧': 'Hong Kong Dramas',
      '韩国剧': 'Korean Dramas',
      '欧美剧': 'Western Dramas',
      '台湾剧': 'Taiwan Dramas',
      '日本剧': 'Japanese Dramas',
      '海外剧': 'International Dramas',
      '泰国剧': 'Thai Dramas',
      // Variety Show subcategories
      '大陆综艺': 'Chinese Variety Shows',
      '港台综艺': 'HK & Taiwan Variety Shows',
      '日韩综艺': 'Japanese & Korean Variety Shows',
      '欧美综艺': 'Western Variety Shows',
      // Animation subcategories
      '国产动漫': 'Chinese Animation',
      '日韩动漫': 'Japanese & Korean Animation',
      '欧美动漫': 'Western Animation',
      '港台动漫': 'HK & Taiwan Animation',
      '海外动漫': 'International Animation',
      // Main categories
      movies: 'Movies',
      tvSeries: 'TV Series',
      variety: 'Variety Shows',
      animation: 'Animation',
      shortSeries: 'Short Series',
      documentary: 'Documentary',
      // TV Series subcategories
      mainlandSeries: 'Mainland Series',
      hongKongSeries: 'Hong Kong Series',
      koreanSeries: 'Korean Series',
      westernSeries: 'Western Series',
      taiwanSeries: 'Taiwan Series',
      japaneseSeries: 'Japanese Series',
      overseasSeries: 'Overseas Series',
      thaiSeries: 'Thai Series'
    },
    contentCard: {
      score: 'Score',
      status: 'Status',
      year: 'Year',
      originalTitle: 'Original Title',
      genres: 'Genres',
      watchNow: 'Watch Now',
      moreInfo: 'More Info'
    },
    videoPlayer: {
      routeSelection: 'Route Selection',
      ffm3u8: 'Blu-ray F',
      heimuer: 'Blu-ray H',
      tym3u8: 'Blu-ray T',
      ikm3u8: 'Blu-ray I',
      '1080zyk': 'Blu-ray Y',
      bfzym3u8: 'Backup B',
      okm3u8: 'Backup O',
    },
    history: {
      watchHistory: 'Watch History',
      recentlyWatched: 'Recently Watched',
      clearHistory: 'Clear History',
      clearAll: 'Clear All History',
      episodeNumber: (number) => `Episode ${number}`,
      movie: 'Movie',
      deleteFromHistory: 'Delete from History',
      noHistory: 'No watch history',
    },
    footer: {
      quickLinks: 'Quick Links',
      home: 'Home',
      browse: 'Browse',
      legal: 'Legal',
      privacyPolicy: 'Privacy Policy',
      termsOfService: 'Terms of Service',
      description: 'Your one-stop streaming platform for movies, TV shows, anime, and more. Watch anytime, anywhere.',
      copyright: 'All rights reserved.',
      sitemap: 'Sitemap'
    },
    nav: {
      guestbook: 'Guestbook',
      memberPortal: 'Member Portal',
    },
    guestbook: {
      title: 'Guestbook',
      placeholder: 'Share your thoughts, suggestions, or feedback...',
      submit: 'Submit',
      submitting: 'Submitting...',
      noEntries: 'No entries yet. Be the first to leave a message!',
      adminReply: 'Admin Reply',
      loadMore: 'Load More',
      error: 'Failed to submit your message. Please try again.',
    },
    login: {
      title: 'Login',
      usernameOrEmail: 'Username or Email',
      usernameOrEmailPlaceholder: 'Enter your username or email',
      password: 'Password',
      submit: 'Login',
      noAccount: "Don't have an account?",
      register: 'Register Now'
    },
    register: {
      title: 'Register',
      username: 'Username',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      submit: 'Register',
      alreadyHaveAccount: 'Already have an account? Login',
      passwordsDoNotMatch: 'Passwords do not match',
      registrationSuccess: 'Registration successful! Please login.'
    },
    member: {
      welcome: 'Welcome, ${username}',
      favorites: 'Favorites',
      watchLater: 'Watch Later',
      history: 'History',
      noFavorites: 'No favorites yet',
      noWatchLater: 'No items in watch later',
      noHistory: 'No watch history',
      confirmDelete: 'Confirm Delete',
      deleteConfirmMessage: 'Are you sure you want to remove "${name}"?',
      confirmClearHistory: 'Clear Watch History',
      clearHistoryConfirmMessage: 'Are you sure you want to clear your entire watch history?',
      removedFromFavorites: 'Removed from favorites',
      removedFromWatchLater: 'Removed from watch later',
      removedFromHistory: 'Removed from history',
      historyCleared: 'Watch history cleared',
      clearHistory: 'Clear History',
      watchDuration: 'Watch Duration',
      lastWatched: 'Last Watched',
    },
    movie: {
      addToFavorites: 'Add to Favorites',
      removeFromFavorites: 'Remove from Favorites',
      addToWatchLater: 'Add to Watch Later',
      removeFromWatchLater: 'Remove from Watch Later',
      addedToWatchLater: 'Added to Watch Later',
    },
  },
  zhcn: {
    common: {
      appName: '247看',
      searchPlaceholder: '搜索电影、电视剧、动漫...',
      search: '搜索',
      browse: '浏览',
      movies: '电影',
      tvShows: '电视剧',
      anime: '动漫',
      myList: '我的列表',
      profile: '个人资料',
      settings: '设置',
      logout: '退出登录',
      play: '播放',
      moreInfo: '更多信息',
      like: '点赞',
      comment: '评论',
      share: '分享',
      language: '语言',
      toggleTheme: '切换主题',
      home: '首页',
      showMore: '展开更多',
      showLess: '收起',
      addFavorite: '已加入收藏',
      removeFavorite: '取消收藏',
      backToHome: '返回首页',
      viewAll: '查看全部',
      loadMore: '加载更多',
      trending: '热门推荐',
      announcement: '公告',
      previous: '上一页',
      next: '下一页',
      pleaseLoginFirst: '请先登录后使用此功能',
      loading: '加载中...',
      turnstileRequired: '请完成安全验证',
      remove: '移除',
      delete: '删除',
      cancel: '取消',
      clear: '清空',
      details: '详情',
      source: '线路',
      addToWatchLater: '加入稍后观看',
      removeFromWatchLater: '从稍后观看移除',
      addToFavorites: '加入收藏',
      removeFromFavorites: '取消收藏',
      guestbookError: '提交失败，请重试。',
      searchHistory: '搜索历史',
    },
    filters: {
      trending: '热门',
      trendingMovies: '热门电影',
      trendingTV: '热门电视剧',
      top250: '豆瓣电影Top 250',
      title: '筛选',
      area: '地区',
      language: '语言',
      year: '年份',
      version: '版本',
      sortBy: '排序方式',
      clearAll: '清除所有筛选',
      search: '搜索',
      actor: '演员',
      director: '导演',
      state: '类型',
      letter: '字母',
      tag: '标签',
      genre: '类型',
      quickFilters: '快速筛选',
      basicFilters: '基本筛选',
      advancedFilters: '高级筛选',
      sortOptions: '排序选项',
      sortBy: '排序方式',
      clearAllFilters: '清除所有筛选',
      filterCount: '筛选',
      gridView: '网格视图',
      listView: '列表视图',
      resultsCount: '${count}个结果'
    },
    home: {
      trending: '正在流行',
      popularMovies: '热门电影',
      popularShows: '热门电视剧',
      popularAnime: '热门动漫',
      continueWatching: '继续观看',
      hotRecommendations: '热门推荐',
      latestReleases: '最新发布',
      loadError: '内容加载失败。请刷新页面。',
      watchNow: '立即观看',
      moreInfo: '更多信息',
      viewAll: '查看全部',
    },
    browse: {
      browseTitle: '浏览',
      all: '全部',
      sortBy: '排序方式',
      genre: '类型',
      mostPopular: '最受欢迎',
      recentlyAdded: '最近添加',
      highestRated: '豆瓣评分',
      releaseYear: '发行年份',
      categories: '分类',
      noContent: '暂无内容',
      noVideosFound: '没有找到相关视频',
      tryDifferentFilters: '请尝试其他筛选条件或清除现有筛选',
      results: '个结果',
      latest: '最新',
      all: '全部',
      mostViewed: '最多观看',
      byYear: '按年份',
      clearFilters: '清除筛选'
    },
    discovery: {
      title: '发现新内容',
      trending: '热门',
      topRated: '评分最高',
      upcoming: '即将上映',
      viewMore: '查看更多',
      relatedContent: '相关内容',
      recommendedForYou: '为你推荐',
      popularInGenre: '${genre}热门',
      similarTo: '类似${title}',
      basedOnYourHistory: '基于你的历史',
      newReleases: '最新发布',
      apiKeyError: 'TMDB API密钥未配置。请在环境中设置REACT_APP_TMDB_API_KEY。',
      fetchError: '获取电影失败：${message}',
      noMoviesFound: '未找到电影',
      loading: '正在加载电影...',
      error: '错误：${message}',
      trending: {
        undefined: '热门'
      },
      topRated: {
        undefined: '评分最高'
      },
      upcoming: {
        undefined: '即将上映'
      },
      movieType: '电影',
      tvType: '电视剧',
      genre: '类型',
      allGenres: '全部',
      sortBy: '排序方式',
      tabs: {
        trending: '热门',
        topRated: '评分最高',
        upcoming: '即将上映',
        discover: '发现'
      },
      sortOptions: {
        popularityDesc: '人气（从高到低）',
        popularityAsc: '人气（从低到高）',
        ratingDesc: '评分（从高到低）',
        ratingAsc: '评分（从低到高）',
        releaseDateDesc: '上映日期（从新到旧）',
        releaseDateAsc: '上映日期（从旧到新）',
        revenueDesc: '票房（从高到低）',
        voteCountDesc: '投票数（从高到低）'
      },
      loading: '正在加载电影...',
      error: '错误：${message}',
      apiKeyError: 'TMDB API密钥未配置。请在环境中设置REACT_APP_TMDB_API_KEY。',
      fetchError: '获取电影失败：${message}',
      noMoviesFound: '未找到电影'
    },
    search: {
      searchTitle: '搜索',
      searchPlaceholder: '搜索电影、电视剧、动漫...',
      resultsFor: '搜索结果：',
      results: '个结果',
      noResults: '未找到相关结果：',
      tryDifferentSearch: '看起来这是一个网络错误。请尝试刷新页面重新搜索，如果问题仍然存在，请在留言区反馈。',
      filterAll: '全部',
      filterMovies: '电影',
      filterTVShows: '电视剧',
      filterAnime: '动漫',
      filterRecent: '最近添加',
      search: '搜索',
      requestContent: '反馈搜索问题',
    },
    watch: {
      episodes: '剧集',
      description: '简介',
      comments: '评论',
      recommendations: '推荐观看',
      noComments: '暂无评论',
      routeSelection: '线路选择',
      shareSuccess: '链接已复制到剪贴板',
      shareFailed: '分享失败，请稍后重试',
      videoNotFound: '视频未找到',
      noPlayableUrl: '未找到可播放的URL',
      loadError: '视频加载失败',
      writeComment: '写评论...',
      postComment: '发表评论',
      anonymous: '匿名',
      reply: '回复',
      replyPlaceholder: '写下你的评论...',
      submitting: '提交中...',
      enterVerificationCode: '请输入验证码',
      clickToRefresh: '点击刷新',
      verificationCodeError: '获取验证码失败',
      invalidVerificationCode: '请输入验证码',
      autoplayNext: '自动播放下一集',
      autoplayEnabled: '已开启自动播放',
      autoplayDisabled: '已关闭自动播放',
      showDescription: '展开剧情简介',
      hideDescription: '收起剧情简介',
      sortBy: '排序方式',
      newest: '最新',
      mostLiked: '最多点赞',
    },
    movieDetail: {
      play: '播放',
      plot: '剧情',
      noPlot: '暂无剧情简介',
      castAndCrew: '演职人员',
      director: '导演',
      cast: '演员',
      status: '状态',
      version: '版本',
      episodes: '剧集',
      genres: '类型',
      stats: '统计',
      views: '总观看次数',
      dailyViews: '日观看次数',
      weeklyViews: '周观看次数',
      monthlyViews: '月观看次数',
      notFound: '未找到影片',
      playNow: '立即播放',
      director: '导演',
      cast: '演员',
      episodes: '剧集',
      watchTrailer: '观看预告片',
      addToFavorites: '已加入收藏',
      removeFromFavorites: '取消收藏',
      share: '分享',
      showMore: '展开更多',
      showLess: '收起',
      addedToWatchLater: '已加入稍后观看',
      addToWatchLater: '加入稍后观看',
      removeFromWatchLater: '从稍后观看移除',
      showDescription: '展开剧情简介',
      hideDescription: '收起剧情简介',
      sortBy: '排序方式',
      newest: '最新',
      mostLiked: '最多点赞',
      reportIssue: '报告问题',
      reportIssueTitle: '报告内容问题',
      reportIssueDescription: '发现信息错误或缺失？帮助我们改进。',
      reportIssueNote: '您将被重定向到留言区，在那里您可以提供问题的详细信息。',
      confirmReport: '提交报告',
    },
    categories: {
      '电影': '电影',
      '连续剧': '电视剧',
      '综艺': '综艺节目',
      '动漫': '动漫',
      '短剧': '短剧',
      '记录片': '纪录片',
      '国产动漫': '国产动漫',
      '日韩动漫': '日韩动漫',
      '欧美动漫': '欧美动漫',
      '港台动漫': '港台动漫',
      '海外动漫': '海外动漫',
      // Main categories
      movies: '电影',
      tvSeries: '连续剧',
      variety: '综艺',
      animation: '动漫',
      shortSeries: '短剧',
      documentary: '纪录片',
      // TV Series subcategories
      mainlandSeries: '国产剧',
      hongKongSeries: '香港剧',
      koreanSeries: '韩国剧',
      westernSeries: '欧美剧',
      taiwanSeries: '台湾剧',
      japaneseSeries: '日本剧',
      overseasSeries: '海外剧',
      thaiSeries: '泰国剧'
    },
    contentCard: {
      score: '评分',
      status: '状态',
      year: '年份',
      originalTitle: '原标题',
      genres: '类型',
      watchNow: '立即观看',
      moreInfo: '更多信息'
    },
    videoPlayer: {
      routeSelection: '线路选择',
      ffm3u8: '蓝光F',
      heimuer: '蓝光H',
      tym3u8: '蓝光T',
      ikm3u8: '蓝光I',
      '1080zyk': '蓝光Y',
      bfzym3u8: '备用B',
      okm3u8: '备用O',
    },
    history: {
      watchHistory: '观看历史',
      recentlyWatched: '最近观看',
      clearHistory: '清空历史记录',
      clearAll: '清空所有历史',
      episodeNumber: (number) => `第 ${number} 集`,
      movie: '电影',
      deleteFromHistory: '从历史记录中删除',
      noHistory: '暂无观看记录',
    },
    footer: {
      quickLinks: '快速链接',
      home: '首页',
      browse: '浏览',
      legal: '法律',
      privacyPolicy: '隐私政策',
      termsOfService: '服务条款',
      description: '您的一站式流媒体平台，提供电影、电视剧、动漫等内容。随时随地，想看就看。',
      copyright: '版权所有',
      sitemap: '网站地图'
    },
    nav: {
      guestbook: '留言区',
      memberPortal: '会员中心',
    },
    guestbook: {
      title: '留言区',
      placeholder: '分享您的想法、建议或反馈...',
      submit: '提交',
      submitting: '提交中...',
      noEntries: '暂无留言。成为第一个留言的人！',
      adminReply: '管理员回复',
      loadMore: '加载更多',
      error: '提交失败，请重试。',
    },
    login: {
      title: '登录',
      usernameOrEmail: '用户名或邮箱',
      usernameOrEmailPlaceholder: '请输入用户名或邮箱',
      password: '密码',
      submit: '登录',
      noAccount: '还没有账号？',
      register: '立即注册'
    },
    register: {
      title: '注册',
      username: '用户名',
      email: '邮箱',
      password: '密码',
      confirmPassword: '确认密码',
      submit: '注册',
      alreadyHaveAccount: '已有账号？去登录',
      passwordsDoNotMatch: '两次输入的密码不一致',
      registrationSuccess: '注册成功！请登录。'
    },
    member: {
      welcome: '欢迎, ${username}',
      favorites: '我的收藏',
      watchLater: '稍后观看',
      history: '观看历史',
      noFavorites: '暂无收藏',
      noWatchLater: '暂无稍后观看内容',
      noHistory: '暂无观看历史',
      confirmDelete: '确认删除',
      deleteConfirmMessage: '确定要删除 "${name}" 吗？',
      confirmClearHistory: '清空观看历史',
      clearHistoryConfirmMessage: '确定要清空所有观看历史吗？',
      removedFromFavorites: '已从收藏中移除',
      removedFromWatchLater: '已从稍后观看中移除',
      addedToWatchLater: '已加入稍后观看',
      removedFromHistory: '已从历史记录中移除',
      historyCleared: '观看历史已清空',
      clearHistory: '清空历史',
      watchDuration: '观看时长',
      lastWatched: '最后观看',
    },
    movie: {
      addToFavorites: '加入收藏',
      removeFromFavorites: '取消收藏',
      addToWatchLater: '加入稍后观看',
      removeFromWatchLater: '从稍后观看移除',
      addedToWatchLater: '已加入稍后观看',
    },
  },
  zhtw: {
    common: {
      appName: '247看',
      searchPlaceholder: '搜尋電影、電視劇、動漫...',
      search: '搜尋',
      browse: '瀏覽',
      movies: '電影',
      tvShows: '電視劇',
      anime: '動漫',
      myList: '我的清單',
      profile: '個人資料',
      settings: '設定',
      logout: '登出',
      play: '播放',
      moreInfo: '更多資訊',
      like: '讚',
      comment: '留言',
      share: '分享',
      language: '語言',
      toggleTheme: '切換主題',
      home: '首頁',
      showMore: '展開更多',
      showLess: '收起',
      addFavorite: '加入收藏',
      removeFavorite: '取消收藏',
      backToHome: '返回首頁',
      viewAll: '查看全部',
      loadMore: '加載更多',
      trending: '熱門推薦',
      announcement: '公告',
      previous: '上一頁',
      next: '下一頁',
      pleaseLoginFirst: '請先登入後使用此功能',
      loading: '載入中...',
      turnstileRequired: '請完成安全驗證',
      remove: '移除',
      delete: '刪除',
      cancel: '取消',
      clear: '清空',
      details: '詳情',
      source: '線路',
      addToWatchLater: '加入稍後觀看',
      removeFromWatchLater: '從稍後觀看移除',
      addToFavorites: '加入收藏',
      removeFromFavorites: '取消收藏',
      guestbookError: '提交失敗，請重試。',
      searchHistory: '搜尋歷史',
    },
    filters: {
      trending: '熱門',
      trendingMovies: '熱門電影',
      trendingTV: '熱門電視劇',
      top250: '豆瓣電影Top 250',
      title: '篩選',
      area: '地區',
      language: '語言',
      year: '年份',
      version: '版本',
      sortBy: '排序方式',
      clearAll: '清除所有篩選',
      search: '搜尋',
      actor: '演員',
      director: '導演',
      state: '類型',
      letter: '字母',
      tag: '標籤',
      genre: '類型',
      quickFilters: '快速篩選',
      basicFilters: '基本篩選',
      advancedFilters: '進階篩選',
      sortOptions: '排序選項',
      sortBy: '排序方式',
      clearAllFilters: '清除所有篩選',
      filterCount: '篩選',
      gridView: '網格視圖',
      listView: '列表視圖',
      resultsCount: '${count}個結果'
    },
    home: {
      trending: '正在流行',
      popularMovies: '熱門電影',
      popularShows: '熱門電視劇',
      popularAnime: '熱門動漫',
      continueWatching: '繼續觀看',
      hotRecommendations: '熱門推薦',
      latestReleases: '最新發布',
      loadError: '內容加載失敗。請重新整理頁面。',
      watchNow: '立即觀看',
      moreInfo: '更多資訊',
      viewAll: '查看全部',
    },
    browse: {
      browseTitle: '瀏覽',
      all: '全部',
      sortBy: '排序方式',
      genre: '類型',
      mostPopular: '最受歡迎',
      recentlyAdded: '最近新增',
      highestRated: '豆瓣評分',
      releaseYear: '發行年份',
      categories: '分類',
      noContent: '暫無內容',
      noVideosFound: '沒有找到相關視頻',
      tryDifferentFilters: '請嘗試其他篩選條件或清除現有篩選',
      results: '個結果',
      latest: '最新',
      all: '全部',
      mostViewed: '最多觀看',
      byYear: '按年份',
      clearFilters: '清除篩選'
    },
    discovery: {
      title: '發現新內容',
      trending: '熱門',
      topRated: '評分最高',
      upcoming: '即將上映',
      viewMore: '查看更多',
      relatedContent: '相關內容',
      recommendedForYou: '為你推薦',
      popularInGenre: '${genre}熱門',
      similarTo: '類似${title}',
      basedOnYourHistory: '基於你的歷史',
      newReleases: '最新發布',
      apiKeyError: 'TMDB API密鑰未配置。請在環境中設置REACT_APP_TMDB_API_KEY。',
      fetchError: '獲取電影失敗：${message}',
      noMoviesFound: '未找到電影',
      loading: '正在加載電影...',
      error: '錯誤：${message}',
      trending: {
        undefined: '熱門'
      },
      topRated: {
        undefined: '評分最高'
      },
      upcoming: {
        undefined: '即將上映'
      },
      movieType: '電影',
      tvType: '電視劇',
      genre: '類型',
      allGenres: '全部',
      sortBy: '排序方式',
      tabs: {
        trending: '熱門',
        topRated: '評分最高',
        upcoming: '即將上映',
        discover: '發現'
      },
      sortOptions: {
        popularityDesc: '人氣順',
        popularityAsc: '人氣逆順',
        ratingDesc: '評分順',
        ratingAsc: '評分逆順',
        releaseDateDesc: '上映日期順',
        releaseDateAsc: '上映日期逆順',
        revenueDesc: '票房順',
        voteCountDesc: '投票數順'
      },
      loading: '正在加載電影...',
      error: '錯誤：${message}',
      apiKeyError: 'TMDB API密鑰未配置。請在環境中設置REACT_APP_TMDB_API_KEY。',
      fetchError: '獲取電影失敗：${message}',
      noMoviesFound: '未找到電影'
    },
    search: {
      searchTitle: '搜尋',
      searchPlaceholder: '搜尋電影、電視劇、動漫...',
      resultsFor: '搜尋結果：',
      results: '個結果',
      noResults: '未找到相關結果：',
      tryDifferentSearch: '看起來這是一個網絡錯誤。請嘗試刷新頁面重新搜索，如果問題仍然存在，請在留言區反饋。',
      filterAll: '全部',
      filterMovies: '電影',
      filterTVShows: '電視劇',
      filterAnime: '動漫',
      filterRecent: '最近新增',
      search: '搜尋',
      requestContent: '反饋搜索問題',
    },
    watch: {
      episodes: '劇集',
      description: '簡介',
      comments: '評論',
      recommendations: '推薦觀看',
      noComments: '暫無評論',
      routeSelection: '線路選擇',
      shareSuccess: '連結已複製到剪貼板',
      shareFailed: '分享失敗，請稍後重試',
      videoNotFound: '視頻未找到',
      noPlayableUrl: '未找到可播放的URL',
      loadError: '視頻加載失敗',
      writeComment: '寫評論...',
      postComment: '發表評論',
      anonymous: '匿名',
      reply: '回覆',
      replyPlaceholder: '寫下你的評論...',
      submitting: '提交中...',
      enterVerificationCode: '請輸入驗證碼',
      clickToRefresh: '點擊刷新',
      verificationCodeError: '獲取驗證碼失敗',
      invalidVerificationCode: '請輸入驗證碼',
      autoplayNext: '自動播放下一集',
      autoplayEnabled: '已開啟自動播放',
      autoplayDisabled: '已關閉自動播放',
      showDescription: '展開劇情簡介',
      hideDescription: '收起劇情簡介',
      sortBy: '排序方式',
      newest: '最新',
      mostLiked: '最多讚',
    },
    movieDetail: {
      play: '播放',
      plot: '劇情',
      noPlot: '暫無劇情簡介',
      castAndCrew: '演職人員',
      director: '導演',
      cast: '演員',
      status: '狀態',
      version: '版本',
      episodes: '劇集',
      genres: '類型',
      stats: '統計',
      views: '總觀看次數',
      dailyViews: '日觀看次數',
      weeklyViews: '週觀看次數',
      monthlyViews: '月觀看次數',
      notFound: '未找到影片',
      playNow: '立即播放',
      director: '導演',
      cast: '演員',
      episodes: '劇集',
      watchTrailer: '觀看預告片',
      addToFavorites: '加入收藏',
      removeFromFavorites: '取消收藏',
      share: '分享',
      showMore: '展開更多',
      showLess: '收起',
      addedToWatchLater: '已加入稍後觀看',
      addToWatchLater: '加入稍後觀看',
      removeFromWatchLater: '從稍後觀看移除',
      showDescription: '展開劇情簡介',
      hideDescription: '收起劇情簡介',
      sortBy: '排序方式',
      newest: '最新',
      mostLiked: '最多讚',
      reportIssue: '報告問題',
      reportIssueTitle: '報告內容問題',
      reportIssueDescription: '發現資訊錯誤或缺失？幫助我們改進。',
      reportIssueNote: '您將被重定向到留言區，在那裡您可以提供問題的詳細資訊。',
      confirmReport: '提交報告',
    },
    categories: {
      '电影': '電影',
      '连续剧': '電視劇',
      '综艺': '綜藝節目',
      '动漫': '動漫',
      '短剧': '短劇',
      '记录片': '紀錄片',
      '国产动漫': '國產動漫',
      '日韩动漫': '日韓動漫',
      '欧美动漫': '歐美動漫',
      '港台动漫': '港台動漫',
      '海外动漫': '海外動漫',
      // Main categories
      movies: '電影',
      tvSeries: '連續劇',
      variety: '綜藝',
      animation: '動漫',
      shortSeries: '短劇',
      documentary: '紀錄片',
      // TV Series subcategories
      mainlandSeries: '國產劇',
      hongKongSeries: '香港劇',
      koreanSeries: '韓國劇',
      westernSeries: '歐美劇',
      taiwanSeries: '台灣劇',
      japaneseSeries: '日本劇',
      overseasSeries: '海外劇',
      thaiSeries: '泰國劇'
    },
    contentCard: {
      score: '評分',
      status: '狀態',
      year: '年份',
      originalTitle: '原標題',
      genres: '類型',
      watchNow: '立即觀看',
      moreInfo: '更多資訊'
    },
    videoPlayer: {
      routeSelection: '線路選擇',
      ffm3u8: '藍光F',
      heimuer: '藍光H',
      tym3u8: '藍光T',
      ikm3u8: '藍光I',
      '1080zyk': '藍光Y',
      bfzym3u8: '備用B',
      okm3u8: '備用O',
    },
    history: {
      watchHistory: '觀看歷史',
      recentlyWatched: '最近觀看',
      clearHistory: '清空歷史記錄',
      clearAll: '清空所有歷史',
      episodeNumber: (number) => `第 ${number} 集`,
      movie: '電影',
      deleteFromHistory: '從歷史記錄中刪除',
      noHistory: '暫無觀看記錄',
    },
    footer: {
      quickLinks: '快速链接',
      home: '首頁',
      browse: '瀏覽',
      legal: '法律',
      privacyPolicy: '隱私政策',
      termsOfService: '服務條款',
      description: '您的一站式流媒體平台，提供電影、電視劇、動漫等內容。隨時隨地，想看就看。',
      copyright: '版權所有',
      sitemap: '網站地圖'
    },
    nav: {
      guestbook: '留言區',
      memberPortal: '會員中心',
    },
    guestbook: {
      title: '留言區',
      placeholder: '分享您的想法、建議或反饋...',
      submit: '提交',
      submitting: '提交中...',
      noEntries: '暫無留言。成為第一個留言的人！',
      adminReply: '管理員回复',
      loadMore: '加載更多',
      error: '提交失敗，請重試。',
    },
    login: {
      title: '登录',
      usernameOrEmail: '用户名或邮箱',
      usernameOrEmailPlaceholder: '請輸入用戶名或郵箱',
      password: '密码',
      submit: '登录',
      noAccount: '還沒有帳號？',
      register: '立即注册'
    },
    register: {
      title: '注册',
      username: '用户名',
      email: '邮箱',
      password: '密码',
      confirmPassword: '確認密碼',
      submit: '注册',
      alreadyHaveAccount: '已有帳號？去登录',
      passwordsDoNotMatch: '兩次輸入的密碼不一致',
      registrationSuccess: '注册成功！请登录。'
    },
    member: {
      welcome: '歡迎, ${username}',
      favorites: '我的收藏',
      watchLater: '稍後觀看',
      history: '觀看歷史',
      noFavorites: '暫無收藏',
      noWatchLater: '暫無稍後觀看內容',
      noHistory: '暫無觀看歷史',
      confirmDelete: '確認刪除',
      deleteConfirmMessage: '確定要刪除 "${name}" 嗎？',
      confirmClearHistory: '清空觀看歷史',
      clearHistoryConfirmMessage: '確定要清空所有觀看歷史嗎？',
      removedFromFavorites: '已從收藏中移除',
      removedFromWatchLater: '已從稍後觀看中移除',
      addedToWatchLater: '已加入稍後觀看',
      removedFromHistory: '已從歷史記錄中移除',
      historyCleared: '觀看歷史已清空',
      clearHistory: '清空歷史',
      watchDuration: '觀看時長',
      lastWatched: '最後觀看',
    },
    movie: {
      addToFavorites: '加入收藏',
      removeFromFavorites: '取消收藏',
      addToWatchLater: '加入稍後觀看',
      removeFromWatchLater: '從稍後觀看移除',
      addedToWatchLater: '已加入稍後觀看',
    },
  },
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    const savedLang = Cookies.get('language');
    return savedLang || 'zhcn';
  });

  const value = {
    language,
    setLanguage: (newLang) => {
      setLanguage(newLang);
      Cookies.set('language', newLang, { expires: 365 }); // Store for 1 year
    },
    t: (section, key, params) => {
      // Special handling for categories to remove the "categories." prefix
      if (section === 'categories') {
        const translation = translations[language]?.categories?.[key];
        return translation || key;
      }
      // Normal translation handling for other sections
      let translation = translations[language]?.[section]?.[key];
      if (translation === undefined) {
        translation = translations.en?.[section]?.[key];
      }
      if (translation === undefined) {
        return `${section}.${key}`;
      }
      // Handle parameter substitution
      if (params) {
        Object.keys(params).forEach(param => {
          translation = translation.replace(`\${${param}}`, params[param]);
        });
      }
      return translation;
    },
    availableLanguages: [
      { code: 'zhcn', name: '简体中文' },
      { code: 'zhtw', name: '繁體中文' },
      { code: 'en', name: 'English' },
    ],
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};