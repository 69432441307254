import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Tabs,
  Tab,
  Alert,
  CircularProgress,
  useTheme,
  alpha,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Tooltip,
  Stack
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import InfoIcon from '@mui/icons-material/Info';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { useAuth } from '../contexts/AuthContext';
import { useLanguage } from '../contexts/LanguageContext';
import {
  getFavorites,
  getWatchLater,
  removeFromFavorites,
  removeFromWatchLater,
  getUserHistory,
  clearHistory,
  deleteHistoryItem
} from '../services/api';
import Layout from '../components/Layout';

const routeNames = {
  'heimuer': '蓝光H',
  'tym3u8': '蓝光T',
  'ffm3u8': '蓝光F',
  'ikm3u8': '蓝光I',
  '1080zyk': '蓝光Y',
  'bfzym3u8': '备用B',
  'okm3u8': '备用O',
};

const Member = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const { t } = useLanguage();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(0);
  const [favorites, setFavorites] = useState([]);
  const [watchLater, setWatchLater] = useState([]);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [clearDialogOpen, setClearDialogOpen] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { state: { from: { pathname: '/member' } } });
      return;
    }

    const fetchUserData = async () => {
      try {
        const [favoritesData, watchLaterData, historyData] = await Promise.all([
          getFavorites(),
          getWatchLater(),
          getUserHistory()
        ]);
        setFavorites(favoritesData.data || []);
        setWatchLater(watchLaterData.data || []);
        setHistory(historyData.data || []);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [isAuthenticated, navigate]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleRemoveFavorite = async (vodId) => {
    try {
      await removeFromFavorites(vodId);
      setFavorites(favorites.filter(item => item.vod_id !== vodId));
      setSuccess(t('member', 'removedFromFavorites'));
    } catch (error) {
      setError(error.message);
    }
  };

  const handleRemoveWatchLater = async (vodId) => {
    try {
      await removeFromWatchLater(vodId);
      setWatchLater(watchLater.filter(item => item.vod_id !== vodId));
      setSuccess(t('member', 'removedFromWatchLater'));
    } catch (error) {
      setError(error.message);
    }
  };

  const handleClearHistory = async () => {
    try {
      await clearHistory();
      setHistory([]);
      setSuccess(t('member', 'historyCleared'));
      setClearDialogOpen(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleRemoveHistory = async (vodId) => {
    try {
      await deleteHistoryItem(vodId);
      setHistory(history.filter(item => item.vod_id !== vodId));
      setSuccess(t('member', 'removedFromHistory'));
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteClick = (item, type) => {
    setItemToDelete({ item, type });
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!itemToDelete) return;

    try {
      if (itemToDelete.type === 'favorite') {
        await handleRemoveFavorite(itemToDelete.item.vod_id);
      } else if (itemToDelete.type === 'watchLater') {
        await handleRemoveWatchLater(itemToDelete.item.vod_id);
      } else if (itemToDelete.type === 'history') {
        await handleRemoveHistory(itemToDelete.item.vod_id);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setDeleteDialogOpen(false);
      setItemToDelete(null);
    }
  };

  const renderVideoGrid = (items, type) => (
    <Grid container spacing={2}>
      {items.map((item) => (
        <Grid item xs={6} sm={6} md={4} lg={3} key={item.vod_id}>
          <Card
            onClick={() => navigate(`/detail/${item.vod_id}`)}
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              cursor: 'pointer',
              '&:hover': {
                transform: 'translateY(-4px)',
                transition: 'transform 0.2s ease-in-out',
                boxShadow: theme.shadows[4]
              }
            }}
          >
            <Box
              sx={{
                position: 'relative',
                paddingTop: '150%'
              }}
            >
              <CardMedia
                component="img"
                image={item.vod_pic}
                alt={item.vod_name}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  bgcolor: 'rgba(0,0,0,0.5)',
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  '&:hover': {
                    opacity: 1
                  }
                }}
              >
                <Tooltip title={t('common', 'play')}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/watch/${item.vod_id}${type === 'history' ? 
                        `?route=${item.route_index || 0}&ep=${item.episode || 1}&start_time=${item.watch_duration || 0}` : 
                        ''}`);
                    }}
                    sx={{ color: 'white' }}
                  >
                    <PlayArrowIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('common', 'details')}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/detail/${item.vod_id}`);
                    }}
                    sx={{ color: 'white' }}
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <CardContent sx={{ flexGrow: 1, pb: 1 }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                noWrap
                sx={{ 
                  fontWeight: 500,
                  fontSize: { xs: '0.875rem', sm: '1rem' }
                }}
              >
                {item.vod_name}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {item.vod_content || '暂无数据'}
              </Typography>
              {type === 'history' && (
                <Stack spacing={0.5} sx={{ mt: 1 }}>
                  {item.watch_duration > 0 && (
                    <Typography variant="caption" color="text.secondary">
                      {t('member', 'watchDuration')}: {new Date(item.watch_duration * 1000).toISOString().substr(11, 8)}
                    </Typography>
                  )}
                  {item.last_watched && (
                    <Typography variant="caption" color="text.secondary">
                      {t('member', 'lastWatched')}: {new Date(item.last_watched).toLocaleDateString()}
                    </Typography>
                  )}
                  {item.episode && (
                    <Typography variant="caption" color="text.secondary">
                      {t('history', 'episodeNumber')(item.episode)}
                    </Typography>
                  )}
                  {item.route && (
                    <Typography variant="caption" color="text.secondary">
                      {t('common', 'source')}: {routeNames[item.route] || item.route}
                    </Typography>
                  )}
                </Stack>
              )}
            </CardContent>
            <Tooltip title={t('common', 'remove')}>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(item, type);
                }}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  bgcolor: alpha(theme.palette.background.paper, 0.8),
                  '&:hover': {
                    bgcolor: alpha(theme.palette.error.main, 0.1)
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  if (loading) {
    return (
      <Layout>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '70vh'
          }}
        >
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container maxWidth="lg" sx={{ 
        mt: { xs: 6, sm: 7, md: 8 }, 
        mb: { xs: 2, sm: 4 }, 
        minHeight: '70vh', 
        pt: { xs: 2, sm: 3 },
        px: { xs: 1, sm: 2, md: 3 }
      }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          sx={{ pt: { xs: 1, sm: 2 } }}
        >
          <Typography 
            variant="h4" 
            component="h1"
            sx={{
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }
            }}
          >
            {t('member', 'welcome', { username: user?.username })}
          </Typography>
          {activeTab === 2 && history.length > 0 && (
            <Button
              startIcon={<DeleteSweepIcon />}
              onClick={() => setClearDialogOpen(true)}
              color="error"
              variant="outlined"
            >
              {t('member', 'clearHistory')}
            </Button>
          )}
        </Stack>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError('')}>
            {error}
          </Alert>
        )}

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label={t('member', 'favorites')} />
            <Tab label={t('member', 'watchLater')} />
            <Tab label={t('member', 'history')} />
          </Tabs>
        </Box>

        <Box sx={{ mt: 2 }}>
          {activeTab === 0 && (
            <>
              {favorites.length === 0 ? (
                <Typography color="text.secondary">
                  {t('member', 'noFavorites')}
                </Typography>
              ) : (
                renderVideoGrid(favorites, 'favorite')
              )}
            </>
          )}

          {activeTab === 1 && (
            <>
              {watchLater.length === 0 ? (
                <Typography color="text.secondary">
                  {t('member', 'noWatchLater')}
                </Typography>
              ) : (
                renderVideoGrid(watchLater, 'watchLater')
              )}
            </>
          )}

          {activeTab === 2 && (
            <>
              {history.length === 0 ? (
                <Typography color="text.secondary">
                  {t('member', 'noHistory')}
                </Typography>
              ) : (
                renderVideoGrid(history, 'history')
              )}
            </>
          )}
        </Box>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>
            {t('member', 'confirmDelete')}
          </DialogTitle>
          <DialogContent>
            <Typography>
              {t('member', 'deleteConfirmMessage', {
                name: itemToDelete?.item?.vod_name
              })}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)}>
              {t('common', 'cancel')}
            </Button>
            <Button onClick={handleDeleteConfirm} color="error">
              {t('common', 'delete')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Clear History Confirmation Dialog */}
        <Dialog
          open={clearDialogOpen}
          onClose={() => setClearDialogOpen(false)}
        >
          <DialogTitle>
            {t('member', 'confirmClearHistory')}
          </DialogTitle>
          <DialogContent>
            <Typography>
              {t('member', 'clearHistoryConfirmMessage')}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setClearDialogOpen(false)}>
              {t('common', 'cancel')}
            </Button>
            <Button onClick={handleClearHistory} color="error">
              {t('common', 'clear')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Success Snackbar */}
        <Snackbar
          open={!!success}
          autoHideDuration={3000}
          onClose={() => setSuccess('')}
          message={success}
        />
      </Container>
    </Layout>
  );
};

export default Member; 