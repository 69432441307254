import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Chip,
  Stack,
  useTheme,
  IconButton,
  Fade,
  Skeleton,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useLanguage } from '../contexts/LanguageContext';

const ContentCardSkeleton = () => {
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Skeleton 
        variant="rectangular" 
        sx={{ 
          width: '100%',
          paddingTop: '150%', // 2:3 aspect ratio
        }} 
      />
      <CardContent>
        <Stack spacing={1}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="60%" />
        </Stack>
      </CardContent>
    </Card>
  );
};

const ContentCard = ({ content, loading }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = React.useState(false);
  const [imageError, setImageError] = React.useState(false);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const { t } = useLanguage();

  // Process and validate content data
  const processedContent = React.useMemo(() => {
    if (!content) return null;

    // Extract and clean the type from either vod_class or type_name
    const genres = Array.isArray(content.vod_class) 
      ? content.vod_class 
      : (typeof content.vod_class === 'string' 
        ? content.vod_class.split(',').map(g => g.trim()).filter(Boolean) 
        : []);
    const typeFromName = content.type_name || '';
    const cleanType = genres.length > 0 ? genres : (typeFromName ? [typeFromName] : []);

    // Get the best available score (prefer Douban score if available)
    const doubanScore = parseFloat(content.vod_douban_score || '0');
    const regularScore = parseFloat(content.vod_score || '0');
    const bestScore = doubanScore > 0 ? doubanScore : regularScore;

    return {
      vod_id: content.vod_id || '',
      vod_name: content.vod_name || '',
      vod_pic: content.vod_pic || '/placeholder.jpg',
      vod_score: bestScore,
      vod_douban_score: doubanScore,
      vod_year: content.vod_year || '',
      vod_remarks: content.vod_remarks?.trim() || '',
      vod_en: content.vod_en || '',
      type_name: cleanType,
      vod_area: content.vod_area || '',
      vod_lang: content.vod_lang || '',
      vod_content: content.vod_content || content.vod_blurb || '',
      is_anime: cleanType.some(type => type.includes('动漫') || type.includes('漫画')),
      vod_class: genres
    };
  }, [content]);

  // Format score only if it's valid
  const formattedScore = React.useMemo(() => {
    if (!processedContent) return null;
    const score = processedContent.vod_douban_score || processedContent.vod_score;
    return !isNaN(score) && score > 0 ? score.toFixed(1) : null;
  }, [processedContent]);

  const handleImageLoad = React.useCallback(() => {
    setImageLoaded(true);
  }, []);

  const handleImageError = React.useCallback(() => {
    setImageError(true);
    setImageLoaded(true);  // Even on error, we want to show something
  }, []);

  const handleCardClick = React.useCallback((e) => {
    if (!processedContent?.vod_id) return;
    
    // If it's a link click, let the default behavior handle it
    if (e.target.closest('a')) return;
    
    // Middle mouse button click - open in new tab
    if (e.button === 1) {
      e.preventDefault();
      window.open(`/detail/${processedContent.vod_id}`, '_blank');
      return;
    }
    
    // Left click - navigate in current tab
    if (e.button === 0) {
      navigate(`/detail/${processedContent.vod_id}`);
    }
  }, [navigate, processedContent?.vod_id]);

  // Show skeleton while loading or if content is not properly processed
  if (loading || !processedContent) {
    return <ContentCardSkeleton />;
  }

  // Early validation with more detailed logging
  if (!processedContent.vod_id || !processedContent.vod_name) {
    console.warn('Invalid content data:', { content, processedContent });
    return <ContentCardSkeleton />;
  }

  const {
    vod_id,
    vod_name,
    vod_pic,
    vod_remarks,
    vod_en,
    type_name,
    vod_year,
    is_anime
  } = processedContent;

  return (
    <Card
      component={Box}
      onClick={handleCardClick}
      onMouseDown={handleCardClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: 'background.paper',
        borderRadius: 2,
        overflow: 'hidden',
        transition: theme.transitions.create(['transform', 'box-shadow'], {
          duration: theme.transitions.duration.standard,
        }),
        '&:hover': {
          '@media (hover: hover)': {
            transform: 'scale(1.04)',
            boxShadow: theme.shadows[8],
            '& .MuiCardMedia-root': {
              filter: 'brightness(0.7)',
            },
          }
        },
        cursor: 'pointer',
        width: {
          xs: '160px',
          sm: '180px',
          md: '220px'
        },
        flexShrink: 0,
        mx: 'auto',
      }}
    >
      <Box 
        sx={{ 
          position: 'relative',
          paddingTop: '150%', // 2:3 aspect ratio
          width: '100%',
          overflow: 'hidden',
          bgcolor: 'action.hover',
        }}
      >
        {/* Show logo overlay when image fails to load */}
        {imageError && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
              gap: 2,
            }}
          >
            <Box
              component="img"
              src="/icon.png"
              alt="247看"
              sx={{
                width: '40%',
                height: 'auto',
                maxWidth: 120,
                opacity: 0.8,
              }}
            />
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 600,
                letterSpacing: '.05rem',
                opacity: 0.8,
              }}
            >
              247看
            </Typography>
          </Box>
        )}

        {/* Show actual image if available */}
        {!imageError && (
          <CardMedia
            component="img"
            image={vod_pic}
            alt={vod_name}
            onError={handleImageError}
            onLoad={handleImageLoad}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              transition: theme.transitions.create(['filter', 'opacity'], {
                duration: theme.transitions.duration.standard,
              }),
              opacity: imageLoaded ? 1 : 0,
            }}
          />
        )}
        
        {/* Hover Overlay */}
        <Fade in={isHovered}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              zIndex: 2,
              bgcolor: 'rgba(0,0,0,0.5)',
            }}
          >
            <IconButton
              component={Link}
              to={`/watch/${vod_id}`}
              sx={{
                bgcolor: 'primary.main',
                color: 'white',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
                padding: {
                  xs: 1.5,
                  sm: 1.2,
                  md: 1
                },
                '& .MuiSvgIcon-root': {
                  fontSize: {
                    xs: '1.5rem',
                    sm: '1.3rem',
                    md: '1.2rem'
                  }
                }
              }}
            >
              <PlayArrowIcon />
            </IconButton>
            <IconButton
              component={Link}
              to={`/detail/${vod_id}`}
              sx={{
                bgcolor: 'background.paper',
                color: 'text.primary',
                '&:hover': {
                  bgcolor: 'action.hover',
                },
              }}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </Box>
        </Fade>

        {/* Score Badge */}
        {formattedScore && (
          <Box
            sx={{
              position: 'absolute',
              top: 8,
              left: 8,
              backgroundColor: 'rgba(0,0,0,0.75)',
              color: 'warning.light',
              padding: {
                xs: '2px 6px',
                sm: '4px 8px'
              },
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              zIndex: 3,
              fontSize: {
                xs: '0.8rem',
                sm: '0.85rem',
                md: '0.95rem'
              },
              fontWeight: 700,
              '& .MuiSvgIcon-root': {
                fontSize: {
                  xs: '0.9rem',
                  sm: '1rem',
                  md: '1.1rem'
                }
              }
            }}
          >
            <StarIcon />
            {formattedScore}
          </Box>
        )}

        {/* Status Badge */}
        {vod_remarks && (
          <Chip
            label={vod_remarks}
            size="medium"
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              backgroundColor: is_anime ? 'secondary.main' : 'primary.main',
              color: 'primary.contrastText',
              fontWeight: 600,
              fontSize: {
                xs: '0.7rem',
                sm: '0.75rem',
                md: '0.85rem'
              },
              height: {
                xs: '24px',
                sm: '26px',
                md: '28px'
              },
              '& .MuiChip-label': {
                px: {
                  xs: 1,
                  sm: 1.25,
                  md: 1.5
                },
                // Limit text length on mobile
                maxWidth: {
                  xs: '100px',
                  sm: '140px',
                  md: '160px'
                },
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }
            }}
          />
        )}
      </Box>

      <CardContent 
        sx={{ 
          flexGrow: 1, 
          p: 1.5,
          '&:last-child': { pb: 1.5 },
          background: theme.palette.mode === 'dark' 
            ? 'linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0))'
            : 'linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0))',
        }}
      >
        <Stack spacing={0.5}>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{
              fontWeight: 600,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              color: 'text.primary',
              lineHeight: 1.2,
              letterSpacing: '0.01em',
              fontSize: {
                xs: '0.875rem',
                sm: '0.925rem',
                md: '1rem'
              }
            }}
          >
            {vod_name}
            {vod_en && (
              <Typography
                variant="caption"
                component="div"
                sx={{
                  color: 'text.secondary',
                  mt: 0.5,
                }}
              >
                {vod_en}
              </Typography>
            )}
          </Typography>
          
          <Stack direction="row" spacing={1} alignItems="center" sx={{ flexWrap: 'wrap', gap: 0.5 }}>
            {processedContent.vod_class && processedContent.vod_class.map((genre, index) => (
              <Typography
                key={index}
                variant="caption"
                sx={{
                  color: 'text.secondary',
                  fontWeight: 500,
                  bgcolor: 'action.hover',
                  px: {
                    xs: 0.5,
                    sm: 0.75,
                    md: 1
                  },
                  py: 0.25,
                  borderRadius: 1,
                  fontSize: {
                    xs: '0.7rem',
                    sm: '0.75rem',
                    md: '0.8rem'
                  }
                }}
              >
                {t('categories', genre)}
              </Typography>
            ))}
            {vod_year && (
              <Typography
                variant="caption"
                sx={{
                  color: 'text.secondary',
                  fontWeight: 500,
                }}
              >
                {vod_year}
              </Typography>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ContentCard; 