import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Fade,
  useTheme,
  useMediaQuery,
  Stack,
  Chip,
  Divider,
  alpha,
  Rating,
  Link,
  Skeleton,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import CategoryIcon from '@mui/icons-material/Category';
import StarIcon from '@mui/icons-material/Star';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PublicIcon from '@mui/icons-material/Public';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PersonIcon from '@mui/icons-material/Person';
import TranslateIcon from '@mui/icons-material/Translate';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';

const LazyImage = ({ src, alt, style, onLoad }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
      {!loaded && !error && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: 1,
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
            backgroundImage: `linear-gradient(90deg, 
              ${alpha('#fff', 0.1)} 25%, 
              ${alpha('#fff', 0.15)} 37%, 
              ${alpha('#fff', 0.1)} 63%
            )`,
            backgroundSize: '400% 100%',
          }}
        />
      )}
      <Box
        component="img"
        src={src}
        alt={alt}
        onLoad={() => {
          setLoaded(true);
          onLoad?.();
        }}
        onError={() => setError(true)}
        sx={{
          ...style,
          opacity: loaded ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: 1,
          display: error ? 'none' : 'block'
        }}
      />
      {error && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: (theme) => alpha(theme.palette.background.paper, 0.5),
            borderRadius: 1,
          }}
        >
          <Typography variant="caption" color="text.secondary">
            加载失败
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const CustomMovieDetail = ({ movie, isMobile }) => {
  const theme = useTheme();
  const [imageLoaded, setImageLoaded] = useState(false);

  const renderInfoItem = (icon, label, value, onClick) => {
    if (!value) return null;
    const content = (
      <Stack 
        direction="row" 
        spacing={1} 
        alignItems="center"
        sx={{ 
          color: 'text.secondary',
          cursor: onClick ? 'pointer' : 'default',
          '&:hover': onClick ? {
            color: 'primary.main',
          } : {}
        }}
        onClick={onClick}
      >
        {icon}
        <Typography variant="body2" component="span" sx={{ color: 'text.secondary', mr: 0.5 }}>
          {label}:
        </Typography>
        <Typography 
          variant="body2" 
          component="span" 
          sx={{ 
            color: onClick ? 'primary.main' : 'text.primary',
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {value}
        </Typography>
      </Stack>
    );

    return onClick ? (
      <Tooltip title={`查看更多${label}`}>
        {content}
      </Tooltip>
    ) : content;
  };

  return (
    <Stack spacing={3}>
      {/* Thumbnail */}
      <Box sx={{ 
        position: 'relative',
        width: '80%',
        maxWidth: '300px',
        paddingTop: '120%',
        borderRadius: 2,
        overflow: 'hidden',
        boxShadow: theme.shadows[4],
        mx: 'auto'
      }}>
        {!imageLoaded && (
          <Skeleton 
            variant="rectangular" 
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              height: '100%',
              animation: 'wave'
            }}
          />
        )}
        <Box
          component="img"
          src={movie.vod_pic}
          alt={movie.vod_name}
          onLoad={() => setImageLoaded(true)}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: imageLoaded ? 1 : 0,
            transform: imageLoaded ? 'scale(1)' : 'scale(1.1)',
            filter: imageLoaded ? 'blur(0)' : 'blur(10px)',
            transition: theme.transitions.create(
              ['opacity', 'transform', 'filter'],
              { duration: 400 }
            )
          }}
        />
        {/* Gradient overlay */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '50%',
            background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)',
            display: 'flex',
            alignItems: 'flex-end',
            p: 2,
          }}
        >
          <Stack spacing={0.5} sx={{ width: '100%' }}>
            <Typography
              variant="h6"
              sx={{
                color: 'white',
                textShadow: '0 2px 4px rgba(0,0,0,0.5)',
                fontWeight: 600
              }}
            >
              {movie.vod_name}
            </Typography>
            {movie.vod_remarks && (
              <Chip 
                label={movie.vod_remarks}
                size="small"
                sx={{ 
                  height: 20,
                  maxWidth: 200,
                  bgcolor: 'primary.main',
                  color: 'white',
                  '& .MuiChip-label': {
                    px: 1,
                    fontSize: '0.75rem'
                  }
                }}
              />
            )}
          </Stack>
        </Box>
      </Box>

      {/* Rating and Basic Info */}
      <Stack spacing={2}>
        {/* Rating and Year */}
        <Stack direction="row" spacing={2} alignItems="center">
          {movie.vod_douban_score > 0 && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Rating
                value={movie.vod_douban_score / 2}
                precision={0.1}
                readOnly
                size="small"
              />
              <Typography 
                variant="body2" 
                color="primary"
                sx={{ fontWeight: 600 }}
              >
                {movie.vod_douban_score}
              </Typography>
            </Stack>
          )}
          {movie.vod_year && (
            <Chip 
              label={movie.vod_year}
              size="small"
              variant="outlined"
              sx={{ height: 24 }}
            />
          )}
        </Stack>

        {/* Info Grid */}
        <Stack spacing={1.5}>
          {movie.vod_class && renderInfoItem(
            <LocalOfferIcon fontSize="small" />,
            "类型",
            movie.vod_class
          )}
          {movie.vod_area && renderInfoItem(
            <PublicIcon fontSize="small" />,
            "地区",
            movie.vod_area
          )}
          {movie.vod_lang && renderInfoItem(
            <TranslateIcon fontSize="small" />,
            "语言",
            movie.vod_lang
          )}
          {movie.vod_actor && renderInfoItem(
            <PersonIcon fontSize="small" />,
            "主演",
            Array.isArray(movie.vod_actor) 
              ? movie.vod_actor.slice(0, 3).join(', ')
              : movie.vod_actor.split(',').slice(0, 3).join(', ')
          )}
          {movie.vod_director && renderInfoItem(
            <LocalMoviesIcon fontSize="small" />,
            "导演",
            Array.isArray(movie.vod_director)
              ? movie.vod_director.slice(0, 2).join(', ')
              : movie.vod_director.split(',').slice(0, 2).join(', ')
          )}
        </Stack>

        {/* Description */}
        {movie.vod_content && (
          <Box>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineHeight: 1.6
              }}
            >
              {movie.vod_content}
            </Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

const RandomOverlay = ({ 
  open, 
  onClose, 
  currentVideo, 
  onTryAnother, 
  onWatch,
  loading,
  currentCategory,
  activeFilters = {},
  isCategoryLoading = false
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Helper function to get readable filter names
  const getFilterName = (key) => {
    const filterNames = {
      area: '地区',
      year: '年份',
      actor: '演员',
      director: '导演',
      genre: '类型',
      tag: '标签',
      sort: '排序',
    };
    return filterNames[key] || key;
  };

  // Helper function to get sort name
  const getSortName = (sortValue) => {
    const sortNames = {
      '-vod_level': '热门',
      '-vod_time': '最新',
      '-vod_douban_score': '高分',
      'vod_top250_rank': 'Top250'
    };
    return sortNames[sortValue] || sortValue;
  };

  // Helper function to format filter value
  const formatFilterValue = (key, value) => {
    if (!value) return '';
    
    switch (key) {
      case 'area':
      case 'genre':
        return Array.isArray(value) ? [...new Set(value)].join(',') : value;
      case 'year':
        if (typeof value === 'object') {
          const start = value.start || '不限';
          const end = value.end || '不限';
          return `${start} - ${end}`;
        }
        return value;
      case 'sort':
        return getSortName(value);
      default:
        return value;
    }
  };

  // Filter out empty values and format for display
  const activeFiltersList = Object.entries(activeFilters || {}).filter(([key, value]) => {
    if (key === 'category' || key === 'limit' || value === 'false') return false;
    if (Array.isArray(value)) return value.length > 0;
    if (key === 'year') return value?.start || value?.end;
    return value;
  }).map(([key, value]) => ({
    key,
    label: getFilterName(key),
    value: formatFilterValue(key, value)
  }));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      fullScreen={false}
      TransitionComponent={Fade}
      transitionDuration={400}
      PaperProps={{
        sx: {
          borderRadius: 2,
          background: theme.palette.background.paper,
          backgroundImage: `linear-gradient(${alpha(theme.palette.background.paper, 0.9)}, ${alpha(theme.palette.background.paper, 0.9)})`,
          backdropFilter: 'blur(10px)',
          maxHeight: '90vh',
          m: 2,
          width: { xs: 'calc(100% - 32px)', sm: '360px' }
        }
      }}
    >
      {/* Header */}
      <Box sx={{ 
        p: 2, 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: 1,
        borderColor: 'divider'
      }}>
        <Typography variant="h6" sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          color: 'primary.main',
          fontWeight: 600
        }}>
          <ShuffleIcon />
          随机影片
        </Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Current Category and Filters Info */}
      <Box sx={{ 
        px: 2, 
        py: 1.5,
        borderBottom: 1,
        borderColor: 'divider',
        bgcolor: alpha(theme.palette.primary.main, 0.04)
      }}>
        <Stack spacing={1}>
          {/* Category */}
          <Stack direction="row" spacing={1} alignItems="center">
            <CategoryIcon color="action" fontSize="small" />
            <Typography variant="body2" color="text.secondary">
              当前分类:
            </Typography>
            <Chip
              label={currentCategory?.type_name || '全部'}
              size="small"
              color="primary"
              variant="outlined"
              sx={{ height: 24 }}
            />
          </Stack>

          {/* Active Filters */}
          {activeFiltersList.length > 0 && (
            <Stack direction="row" spacing={1} alignItems="center">
              <FilterListIcon color="action" fontSize="small" />
              <Typography variant="body2" color="text.secondary">
                筛选条件:
              </Typography>
              <Stack 
                direction="row" 
                spacing={0.5} 
                flexWrap="wrap" 
                sx={{ gap: 0.5 }}
              >
                {activeFiltersList.map(({ key, label, value }) => (
                  <Chip
                    key={key}
                    label={`${label}: ${value}`}
                    size="small"
                    variant="outlined"
                    sx={{ height: 24 }}
                  />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Box>

      {/* Content */}
      <DialogContent sx={{ 
        pt: 2,
        height: 'auto',
        maxHeight: { xs: '60vh', sm: '70vh' },
        overflow: 'auto'
      }}>
        {loading ? (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 300,
            gap: 2
          }}>
            <CircularProgress size={48} />
            <Typography variant="body1" color="text.secondary">
              正在为您挑选影片...
            </Typography>
          </Box>
        ) : currentVideo ? (
          <CustomMovieDetail 
            movie={currentVideo} 
            isMobile={isMobile}
          />
        ) : (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 300,
            gap: 2
          }}>
            <Typography variant="body1" color="text.secondary">
              暂无影片
            </Typography>
          </Box>
        )}
      </DialogContent>

      {/* Actions */}
      <DialogActions sx={{ 
        p: 2, 
        gap: 1,
        borderTop: 1,
        borderColor: 'divider',
        bgcolor: theme.palette.background.paper
      }}>
        <Button
          variant="outlined"
          onClick={onTryAnother}
          disabled={loading}
          startIcon={<ShuffleIcon />}
          sx={{
            animation: loading ? 'none' : 'pulse 2s infinite',
            '@keyframes pulse': {
              '0%': {
                boxShadow: '0 0 0 0 rgba(25, 118, 210, 0.4)'
              },
              '70%': {
                boxShadow: '0 0 0 10px rgba(25, 118, 210, 0)'
              },
              '100%': {
                boxShadow: '0 0 0 0 rgba(25, 118, 210, 0)'
              }
            }
          }}
        >
          换一部
        </Button>
        <Button
          variant="contained"
          onClick={(e) => {
            e.preventDefault(); // Prevent default navigation
            window.open(`/detail/${currentVideo.vod_id}`, '_blank');
          }}
          disabled={loading || !currentVideo}
          startIcon={<InfoOutlinedIcon />}
        >
          查看详情
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RandomOverlay; 