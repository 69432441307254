import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Stack,
  Typography,
  Alert,
  Snackbar,
  Card,
  Grid,
  CardMedia,
  Tab,
  Tabs,
  useMediaQuery,
  Container,
  useTheme,
  alpha,
  IconButton,
  CircularProgress,
  Button,
  Switch,
  FormControlLabel,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';
import StarIcon from '@mui/icons-material/Star';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Layout from '../components/Layout';
import RouteSelector from '../components/VideoPlayer/RouteSelector';
import EpisodeSelector from '../components/VideoPlayer/EpisodeSelector';
import {
  getVideoById,
  getMovieRecommendations,
  getSimilarMovies,
  getComments,
  addComment,
  updateCommentVote,
  addToHistory,
  addToFavorites,
  removeFromFavorites,
  addToWatchLater,
  removeFromWatchLater,
  getFavorites,
  getWatchLater
} from '../services/api';
import Cookies from 'js-cookie';
import { useLanguage } from '../contexts/LanguageContext';
import { useAuth } from '../contexts/AuthContext';
import Comments from '../components/Comments/Comments';
import MovieIcon from '@mui/icons-material/Movie';
import castingService from '../services/castingService';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Watch = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useLanguage();
  const { isAuthenticated } = useAuth();
  const [isFavorite, setIsFavorite] = useState(false);
  const [isWatchLater, setIsWatchLater] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [watchDuration, setWatchDuration] = useState(0);
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [watchLaterLoading, setWatchLaterLoading] = useState(false);
  const [autoplayEnabled, setAutoplayEnabled] = useState(() => {
    return Cookies.get('autoplayEnabled') === 'true';
  });
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [useBackupPlayer, setUseBackupPlayer] = useState(() => {
    const preference = Cookies.get('preferBackupPlayer');
    return preference === 'true';
  });
  
  // Function to find the first 蓝光 route index
  const findFirstBluRayRouteIndex = (routes) => {
    if (!routes?.length) return 0;
    const bluRayPriority = ['heimuer', 'ffm3u8', 'tym3u8', 'ikm3u8', '1080zyk'];
    for (const route of bluRayPriority) {
      const index = routes.indexOf(route);
      if (index !== -1) return index;
    }
    return 0;
  };

  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(parseInt(searchParams.get('route')) || 0);
  const [selectedEpisode, setSelectedEpisode] = useState(parseInt(searchParams.get('ep')) || 1);
  const [currentTab, setCurrentTab] = useState(0);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const [similarMovies, setSimilarMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Function to get the current video URL
  const getVideoUrl = () => video?.vod_play_url || '';

  useEffect(() => {
    // Get start_time from URL params
    const urlParams = new URLSearchParams(location.search);
    const startTimeParam = urlParams.get('start_time');
    const startTime = startTimeParam ? parseInt(startTimeParam, 10) : 0;
    
    // Reset watchDuration to the new start_time
    setWatchDuration(startTime);
  }, [location.search]); // Depend on location.search to detect URL parameter changes

  useEffect(() => {
    const fetchVideo = async () => {
      setLoading(true);
      try {
        const data = await getVideoById(id);
        if (!data) {
          throw new Error('Video not found');
        }
        
        // Use the route from URL params, or find first 蓝光 route
        const routeParam = searchParams.has('route') 
          ? parseInt(searchParams.get('route')) 
          : findFirstBluRayRouteIndex(data.available_routes);
        const episodeParam = parseInt(searchParams.get('ep')) || 1;
        
        setSelectedRoute(routeParam);
        setSelectedEpisode(episodeParam);
        
        // Get start_time from URL params
        const startTimeParam = searchParams.get('start_time');
        const startTime = startTimeParam ? parseInt(startTimeParam, 10) : 0;
        setWatchDuration(startTime); // Set initial watchDuration
        
        const currentEpisode = data.episodes?.find(
          ep => ep.episode === episodeParam && ep.route === data.available_routes[routeParam]
        );

        const playUrl = currentEpisode?.url || data.vod_play_url;
        if (!playUrl) {
          throw new Error('No playable URL found');
        }

        if (data.vod_tmdb_id) {
          try {
            const [recommendationsData, similarData] = await Promise.all([
              getMovieRecommendations(data.vod_tmdb_id),
              getSimilarMovies(data.vod_tmdb_id)
            ]);
            setRecommendations(recommendationsData || []);
            setSimilarMovies(similarData || []);
          } catch (err) {
            console.error('Error fetching recommendations:', err);
            // If TMDB recommendations fail, try getting recommendations by vod_class
            try {
              // Ensure vod_class is a string - use the first class if it's an array
              const vodClass = Array.isArray(data.vod_class) ? data.vod_class[0] : data.vod_class;
              const fallbackData = await getSimilarMovies(null, vodClass);
              setSimilarMovies(fallbackData || []);
            } catch (fallbackErr) {
              console.error('Error fetching fallback recommendations:', fallbackErr);
            }
          }
        } else {
          // If no TMDB ID, get recommendations by vod_class
          try {
            // Ensure vod_class is a string - use the first class if it's an array
            const vodClass = Array.isArray(data.vod_class) ? data.vod_class[0] : data.vod_class;
            const fallbackData = await getSimilarMovies(null, vodClass);
            setSimilarMovies(fallbackData || []);
          } catch (err) {
            console.error('Error fetching fallback recommendations:', err);
          }
        }

        // Only update URL if route or episode params are missing
        const currentParams = new URLSearchParams(window.location.search);
        if (!currentParams.has('route') || !currentParams.has('ep')) {
          const newParams = new URLSearchParams(currentParams.toString());
          if (!currentParams.has('route')) {
            newParams.set('route', routeParam.toString());
          }
          if (!currentParams.has('ep')) {
            newParams.set('ep', episodeParam.toString());
          }
          if (currentParams.toString() !== newParams.toString()) {
            window.history.replaceState(null, '', `${window.location.pathname}?${newParams.toString()}`);
          }
        }
        
        setVideo({
          ...data,
          vod_play_url: playUrl,
          currentEpisode: currentEpisode || null
        });
      } catch (err) {
        console.error('Error fetching video:', err);
        setError(err.message || 'Failed to load video');
        setShowError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo();
  }, [id, searchParams]); // Include searchParams in dependencies

  useEffect(() => {
    if (!video?.vod_play_url) return;

    setIsLoading(true);
    
    // Create iframe element
    const iframe = document.createElement('iframe');
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';
    
    // Get URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const startTimeParam = urlParams.get('start_time');
    
    // Convert start_time to number and validate
    const startTime = startTimeParam ? parseInt(startTimeParam, 10) : 0;
    
    // Only set watchDuration if it's not already set and startTime is valid
    if (watchDuration === 0 && !isNaN(startTime) && startTime > 0) {
      setWatchDuration(startTime);
    }
    
    // Make sure all parameters are properly encoded
    const encodedUrl = encodeURIComponent(video.vod_play_url);
    const encodedName = encodeURIComponent(video.vod_name);
    const encodedEnName = encodeURIComponent(video.vod_en || '');
    const currentEpisode = video.currentEpisode;
    const episodeNumber = currentEpisode?.episode || selectedEpisode || 1;
    
    // Get the episode name
    const episodeName = currentEpisode?.name || video.episodes?.find(ep => 
      ep.episode === episodeNumber && ep.route === video.available_routes[selectedRoute]
    )?.name || currentEpisode?.episode_name || `第${episodeNumber}期`;
    
    const encodedEpisodeName = encodeURIComponent(episodeName);
    const currentRoute = video.available_routes[selectedRoute];
    const routeParam = `&route=${encodeURIComponent(currentRoute)}`;
    
    // Use validated startTime
    const startTimeString = `&start_time=${startTime}`;
    
    iframe.src = `/artplayer.html?url=${encodedUrl}&name=${encodedName}&en_name=${encodedEnName}&episode_name=${encodedEpisodeName}&episode=${episodeNumber}${routeParam}${startTimeString}`;
    
    // Handle iframe load event and messages
    iframe.onload = () => {
      setIsLoading(false);
      // Pass casting service to iframe
      iframe.contentWindow.castingService = castingService;
    };

    // Listen for time update messages from the player
    const handleTimeUpdate = (event) => {
      if (event.data && event.data.type === 'timeupdate') {
        setWatchDuration(Math.floor(event.data.currentTime));
      }
    };
    window.addEventListener('message', handleTimeUpdate);

    const container = document.getElementById('player-container');
    if (container) {
      // Clear only iframe elements from the container
      const existingIframes = container.getElementsByTagName('iframe');
      if (existingIframes.length > 0) {
        Array.from(existingIframes).forEach(iframe => iframe.remove());
      }
      container.appendChild(iframe);
    }

    return () => {
      if (container) {
        const existingIframes = container.getElementsByTagName('iframe');
        if (existingIframes.length > 0) {
          Array.from(existingIframes).forEach(iframe => iframe.remove());
        }
      }
      window.removeEventListener('message', handleTimeUpdate);
      setIsLoading(false);
    };
  }, [video?.vod_play_url, video?.vod_name, video?.vod_en, video?.currentEpisode?.episode_name, selectedRoute, video?.available_routes]);

  const updateVideoAndUrl = (newEpisodeData, newRoute, newEpisode) => {
    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.set('route', newRoute.toString());
    newSearchParams.set('ep', newEpisode.toString());
    
    const newUrl = `/watch/${id}?${newSearchParams.toString()}`;
    window.history.replaceState(null, '', newUrl);

    setVideo(prev => ({
      ...prev,
      vod_play_url: newEpisodeData.url,
      currentEpisode: newEpisodeData
    }));

    // Update video URL and metadata in iframe if needed
    const iframe = document.querySelector('#player-container iframe');
    if (iframe && iframe.contentWindow) {
      const startTime = newSearchParams.get('start_time') || '0';
      const encodedUrl = encodeURIComponent(newEpisodeData.url);
      const encodedName = encodeURIComponent(video.vod_name);
      const encodedEnName = encodeURIComponent(video.vod_en || '');
      const encodedEpisodeName = encodeURIComponent(newEpisodeData.episode_name || '');
      const episodeNumber = newEpisodeData.episode || newEpisode || 1;
      const routeParam = `&route=${encodeURIComponent(video.available_routes[newRoute])}`;
      const startTimeParam = `&start_time=${startTime}`;
      
      iframe.src = `/artplayer.html?url=${encodedUrl}&name=${encodedName}&en_name=${encodedEnName}&episode_name=${encodedEpisodeName}&episode=${episodeNumber}${routeParam}${startTimeParam}`;
    }
  };

  // Memoize handleEpisodeSelect
  const handleEpisodeSelect = React.useCallback((episode) => {
    const currentRoute = video?.available_routes[selectedRoute];
    const episodeData = video?.episodes?.find(
      ep => ep.episode === episode && ep.route === currentRoute
    );
    
    if (episodeData) {
      setSelectedEpisode(episode);
      // Reset watch duration when changing episodes
      setWatchDuration(0);
      
      // Create new search params and explicitly set start_time to 0 for episode changes
      const newSearchParams = new URLSearchParams(window.location.search);
      newSearchParams.set('route', selectedRoute.toString());
      newSearchParams.set('ep', episode.toString());
      newSearchParams.set('start_time', '0'); // Force start time to 0 for new episodes
      
      const newUrl = `/watch/${id}?${newSearchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);

      setVideo(prev => ({
        ...prev,
        vod_play_url: episodeData.url,
        currentEpisode: episodeData
      }));

      // Update video URL and metadata in iframe
      const iframe = document.querySelector('#player-container iframe');
      if (iframe && iframe.contentWindow) {
        const encodedUrl = encodeURIComponent(episodeData.url);
        const encodedName = encodeURIComponent(video.vod_name);
        const encodedEnName = encodeURIComponent(video.vod_en || '');
        const encodedEpisodeName = encodeURIComponent(episodeData.episode_name || '');
        const episodeNumber = episodeData.episode || selectedEpisode || 1;
        const routeParam = `&route=${encodeURIComponent(currentRoute)}`;
        
        // Always set start_time to 0 for new episodes
        iframe.src = `/artplayer.html?url=${encodedUrl}&name=${encodedName}&en_name=${encodedEnName}&episode_name=${encodedEpisodeName}&episode=${episodeNumber}${routeParam}&start_time=0`;
      }
    }
  }, [video, selectedRoute, id]);

  const handleRouteSelect = (routeIndex) => {
    setSelectedRoute(routeIndex);
    const newRoute = video.available_routes[routeIndex];
    
    // First try to find the same episode in the new route
    let targetEpisode = video.episodes.find(
      ep => ep.episode === selectedEpisode && ep.route === newRoute
    );

    // If not found, get the latest episode available in the new route
    if (!targetEpisode) {
      const routeEpisodes = video.episodes.filter(ep => ep.route === newRoute);
      targetEpisode = routeEpisodes.reduce((latest, current) => 
        current.episode > latest.episode ? current : latest
      , routeEpisodes[0]);
    }
    
    if (targetEpisode) {
      const isEpisodeChanged = targetEpisode.episode !== selectedEpisode;
      
      // Reset watch duration if episode changed
      if (isEpisodeChanged) {
        setWatchDuration(0);
      }
      
      setSelectedEpisode(targetEpisode.episode);
      
      // Create new search params
      const newSearchParams = new URLSearchParams(window.location.search);
      newSearchParams.set('route', routeIndex.toString());
      newSearchParams.set('ep', targetEpisode.episode.toString());
      
      // Only preserve start_time if it's the same episode
      if (isEpisodeChanged) {
        newSearchParams.set('start_time', '0');
      }
      
      const newUrl = `/watch/${id}?${newSearchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);

      setVideo(prev => ({
        ...prev,
        vod_play_url: targetEpisode.url,
        currentEpisode: targetEpisode
      }));

      // Update video URL and metadata in iframe
      const iframe = document.querySelector('#player-container iframe');
      if (iframe && iframe.contentWindow) {
        const encodedUrl = encodeURIComponent(targetEpisode.url);
        const encodedName = encodeURIComponent(video.vod_name);
        const encodedEnName = encodeURIComponent(video.vod_en || '');
        const encodedEpisodeName = encodeURIComponent(targetEpisode.episode_name || '');
        const episodeNumber = targetEpisode.episode || selectedEpisode || 1;
        const routeParam = `&route=${encodeURIComponent(newRoute)}`;
        
        // Set start_time to 0 if episode changed, otherwise preserve current time
        const startTime = isEpisodeChanged ? '0' : watchDuration.toString();
        const startTimeParam = `&start_time=${startTime}`;
        
        iframe.src = `/artplayer.html?url=${encodedUrl}&name=${encodedName}&en_name=${encodedEnName}&episode_name=${encodedEpisodeName}&episode=${episodeNumber}${routeParam}${startTimeParam}`;
      }
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: video?.vod_name,
          text: video?.vod_content,
          url: window.location.href,
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
        setError('链接已复制到剪贴板');
        setShowError(true);
      }
    } catch (err) {
      console.error('Error sharing:', err);
      if (err.name !== 'AbortError') {
        setError('分享失败，请稍后重试');
        setShowError(true);
      }
    }
  };

  useEffect(() => {
    const saveToHistory = async () => {
      if (!video) return;
      
      const currentRoute = video.available_routes?.[selectedRoute] || video.available_routes?.[0] || 'heimuer';
      
      if (isAuthenticated) {
        try {
          // Save to database for authenticated users
          await addToHistory(
            video.vod_id,
            selectedEpisode,
            currentRoute,
            watchDuration,
            selectedRoute
          );
        } catch (error) {
          console.error('Error saving to DB history:', error);
        }
      } else {
        // Only save to cookie for non-authenticated users
        const historyItem = {
          vodId: video.vod_id,
          name: video.vod_name,
          url: `/watch/${video.vod_id}`,
          episode: selectedEpisode,
          route: currentRoute,
          watchDuration,
          routeIndex: selectedRoute
        };
        
        const existingHistory = Cookies.get('watchHistory');
        let history = existingHistory ? JSON.parse(existingHistory) : [];
        
        // Remove if already exists
        history = history.filter(item => item.vodId !== historyItem.vodId);
        
        // Add to beginning of array
        history.unshift(historyItem);
        
        // Keep only last 10 items
        history = history.slice(0, 10);
        
        // Save back to cookie (expires in 30 days)
        Cookies.set('watchHistory', JSON.stringify(history), { expires: 30 });
      }
    };

    // Save history every 30 seconds instead of every minute
    const intervalId = setInterval(saveToHistory, 30000);

    // Save history when component unmounts or when episode/route changes
    return () => {
      clearInterval(intervalId);
      if (watchDuration > 0) {
        saveToHistory();
      }
    };
  }, [video, selectedEpisode, selectedRoute, isAuthenticated, watchDuration]);

  // Add a new effect to save history when user leaves the page
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (watchDuration > 0) {
        const currentRoute = video?.available_routes?.[selectedRoute] || video?.available_routes?.[0] || 'heimuer';
        if (isAuthenticated && video) {
          addToHistory(
            video.vod_id,
            selectedEpisode,
            currentRoute,
            watchDuration,
            selectedRoute
          ).catch(error => {
            console.error('Error saving to DB history:', error);
          });
        }
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [video, selectedEpisode, selectedRoute, isAuthenticated, watchDuration]);

  useEffect(() => {
    const checkUserLists = async () => {
      if (!isAuthenticated || !video?.vod_id) return;

      try {
        const [favoritesResponse, watchLaterResponse] = await Promise.all([
          getFavorites(),
          getWatchLater()
        ]);

        const favorites = favoritesResponse.data || [];
        const watchLater = watchLaterResponse.data || [];

        setIsFavorite(favorites.some(item => item.vod_id === video.vod_id));
        setIsWatchLater(watchLater.some(item => item.vod_id === video.vod_id));
      } catch (error) {
        console.error('Error checking user lists:', error);
      }
    };

    checkUserLists();
  }, [isAuthenticated, video?.vod_id]);

  const handleFavoriteClick = async () => {
    if (!isAuthenticated) {
      setSnackbarMessage(t('common', 'pleaseLoginFirst'));
      return;
    }

    if (favoriteLoading) return;

    setFavoriteLoading(true);
    try {
      if (isFavorite) {
        await removeFromFavorites(video.vod_id);
        setSnackbarMessage(t('movieDetail', 'removeFromFavorites'));
      } else {
        await addToFavorites(video.vod_id);
        setSnackbarMessage(t('movieDetail', 'addToFavorites'));
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error('Error updating favorites:', error);
      setSnackbarMessage(error.message || t('common', 'errorOccurred'));
    } finally {
      setFavoriteLoading(false);
    }
  };

  const handleWatchLaterClick = async () => {
    if (!isAuthenticated) {
      setSnackbarMessage(t('common', 'pleaseLoginFirst'));
      return;
    }

    if (watchLaterLoading) return;

    setWatchLaterLoading(true);
    try {
      if (isWatchLater) {
        await removeFromWatchLater(video.vod_id);
        setSnackbarMessage(t('member', 'removedFromWatchLater'));
      } else {
        await addToWatchLater(video.vod_id);
        setSnackbarMessage(t('member', 'addedToWatchLater'));
      }
      setIsWatchLater(!isWatchLater);
    } catch (error) {
      console.error('Error updating watch later:', error);
      setSnackbarMessage(error.message || t('common', 'errorOccurred'));
    } finally {
      setWatchLaterLoading(false);
    }
  };

  // Update routeNames object to use translations
  const routeNames = {
    'heimuer': t('videoPlayer', 'heimuer'),
    'tym3u8': t('videoPlayer', 'tym3u8'),
    'ffm3u8': t('videoPlayer', 'ffm3u8'),
    'ikm3u8': t('videoPlayer', 'ikm3u8'),
    '1080zyk': t('videoPlayer', '1080zyk'),
    'bfzym3u8': t('videoPlayer', 'bfzym3u8'),
    'okm3u8': t('videoPlayer', 'okm3u8'),
  };

  useEffect(() => {
    // Add Turnstile error handler
    const handleTurnstileError = (event) => {
      if (event.type === 'turnstile-error') {
        const overlay = document.getElementById('turnstile-error-overlay');
        if (overlay) {
          overlay.classList.add('error');
        }
        console.error('Turnstile error:', event.detail);
      }
    };

    window.addEventListener('turnstile-error', handleTurnstileError);

    return () => {
      window.removeEventListener('turnstile-error', handleTurnstileError);
    };
  }, []);

  const handleCloseError = () => {
    setShowError(false);
    setError(null);
  };

  // Memoize playNextEpisode
  const playNextEpisode = React.useCallback(() => {
    if (!video || !autoplayEnabled) return;
    
    const currentRoute = video.available_routes[selectedRoute];
    const routeEpisodes = video.episodes
      .filter(ep => ep.route === currentRoute)
      .sort((a, b) => a.episode - b.episode);
    
    const currentEpisodeIndex = routeEpisodes.findIndex(ep => ep.episode === selectedEpisode);
    
    if (currentEpisodeIndex !== -1 && currentEpisodeIndex < routeEpisodes.length - 1) {
      const nextEpisode = routeEpisodes[currentEpisodeIndex + 1];
      handleEpisodeSelect(nextEpisode.episode);
      setSnackbarMessage(t('watch', 'playingNextEpisode'));
    }
  }, [video, autoplayEnabled, selectedRoute, selectedEpisode, handleEpisodeSelect, t]);

  // Add message listener for video end event
  useEffect(() => {
    const handleVideoEnd = (event) => {
      if (event.data && event.data.type === 'videoEnded') {
        playNextEpisode();
      }
    };
    
    window.addEventListener('message', handleVideoEnd);
    return () => window.removeEventListener('message', handleVideoEnd);
  }, [playNextEpisode, autoplayEnabled]);

  // Handle autoplay toggle
  const handleAutoplayToggle = React.useCallback((e) => {
    const newValue = e.target.checked;
    setAutoplayEnabled(newValue);
    Cookies.set('autoplayEnabled', newValue.toString(), { expires: 30 });
    setSnackbarMessage(newValue ? t('watch', 'autoplayEnabled') : t('watch', 'autoplayDisabled'));
  }, [t]);

  useEffect(() => {
    // Initialize casting service callbacks
    castingService.setErrorCallback((error) => {
      setSnackbarMessage(error.message || 'Casting Error');
      setShowError(true);
    });

    castingService.setSessionConnectedCallback((session) => {
      console.log('Cast session connected:', session);
    });

    // Initialize AirPlay
    castingService.initializeAirPlay();

    return () => {
      // Clean up casting service
      castingService.stopCasting().catch(console.warn);
    };
  }, []);

  const handleOpenHelpDialog = () => {
    setOpenHelpDialog(true);
  };

  const handleCloseHelpDialog = () => {
    setOpenHelpDialog(false);
  };

  const handlePrevEpisode = () => {
    if (!video) return;
    
    const currentRoute = video.available_routes[selectedRoute];
    const routeEpisodes = video.episodes
      .filter(ep => ep.route === currentRoute)
      .sort((a, b) => a.episode - b.episode);
    
    const currentIndex = routeEpisodes.findIndex(ep => ep.episode === selectedEpisode);
    if (currentIndex > 0) {
      const prevEpisode = routeEpisodes[currentIndex - 1];
      handleEpisodeSelect(prevEpisode.episode);
    }
  };

  const handleNextEpisode = () => {
    if (!video) return;
    
    const currentRoute = video.available_routes[selectedRoute];
    const routeEpisodes = video.episodes
      .filter(ep => ep.route === currentRoute)
      .sort((a, b) => a.episode - b.episode);
    
    const currentIndex = routeEpisodes.findIndex(ep => ep.episode === selectedEpisode);
    if (currentIndex !== -1 && currentIndex < routeEpisodes.length - 1) {
      const nextEpisode = routeEpisodes[currentIndex + 1];
      handleEpisodeSelect(nextEpisode.episode);
    }
  };

  // Add message listener for episode navigation
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'prevEpisode') {
        handlePrevEpisode();
      } else if (event.data.type === 'nextEpisode') {
        handleNextEpisode();
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [video, selectedRoute, selectedEpisode]);

  // Update the video URL update code to include episode name
  useEffect(() => {
    if (!video?.vod_play_url) return;

    setIsLoading(true);
    
    // Create iframe element
    const iframe = document.createElement('iframe');
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';
    
    // Make sure all parameters are properly encoded
    const encodedUrl = encodeURIComponent(video.vod_play_url);
    const encodedName = encodeURIComponent(video.vod_name);
    const encodedEnName = encodeURIComponent(video.vod_en || '');
    const currentEpisode = video.currentEpisode;
    const episodeNumber = currentEpisode?.episode || selectedEpisode || 1;
    
    // Get the episode name from the current episode or find it in episodes array
    const episodeName = currentEpisode?.name || video.episodes?.find(ep => 
      ep.episode === episodeNumber && ep.route === video.available_routes[selectedRoute]
    )?.name || currentEpisode?.episode_name || `第${episodeNumber}期`;
    
    const encodedEpisodeName = encodeURIComponent(episodeName);
    
    // Add route parameter to iframe URL
    const currentRoute = video.available_routes[selectedRoute];
    const routeParam = `&route=${encodeURIComponent(currentRoute)}`;
    
    // Get start time from URL if available
    const urlParams = new URLSearchParams(window.location.search);
    const startTime = urlParams.get('start_time') || '0';
    const startTimeParam = `&start_time=${startTime}`;
    
    iframe.src = `/artplayer.html?url=${encodedUrl}&name=${encodedName}&en_name=${encodedEnName}&episode_name=${encodedEpisodeName}&episode=${episodeNumber}${routeParam}${startTimeParam}`;
    
    // Handle iframe load event and messages
    iframe.onload = () => {
      setIsLoading(false);
      // Pass casting service to iframe
      iframe.contentWindow.castingService = castingService;
    };

    // Listen for time update messages from the player
    const handleTimeUpdate = (event) => {
      if (event.data && event.data.type === 'timeupdate') {
        setWatchDuration(Math.floor(event.data.currentTime));
      }
    };
    window.addEventListener('message', handleTimeUpdate);

    const container = document.getElementById('player-container');
    if (container) {
      // Clear only iframe elements from the container
      const existingIframes = container.getElementsByTagName('iframe');
      if (existingIframes.length > 0) {
        Array.from(existingIframes).forEach(iframe => iframe.remove());
      }
      container.appendChild(iframe);
    }

    return () => {
      if (container) {
        const existingIframes = container.getElementsByTagName('iframe');
        if (existingIframes.length > 0) {
          Array.from(existingIframes).forEach(iframe => iframe.remove());
        }
      }
      window.removeEventListener('message', handleTimeUpdate);
      setIsLoading(false);
    };
  }, [video?.vod_play_url, video?.vod_name, video?.vod_en, video?.currentEpisode?.episode_name, selectedRoute, video?.available_routes]);

  const renderVideoActions = () => (
    <Stack 
      direction="row"
      spacing={2}
      sx={{ 
        width: '100%',
        mt: 2,
        flexWrap: 'wrap',
        gap: { xs: 1.5, sm: 1.5 },
        alignItems: 'center',
        justifyContent: { xs: 'space-between', sm: 'flex-start' }
      }}
    >
      {/* Main action buttons group */}
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 1.5 }}
        sx={{
          width: { xs: '100%', sm: 'auto' },
          '& > button': {
            width: { xs: '100%', sm: 'auto' },
            height: { xs: '36px', sm: '36px' },
            px: { xs: 2, sm: 2 },
            fontSize: { xs: '0.875rem', sm: '0.875rem' },
            minWidth: { sm: '120px' },
            '& .MuiButton-startIcon': {
              mr: { xs: 1, sm: 1 }
            }
          }
        }}
      >
        <Button
          variant={isFavorite ? "contained" : "outlined"}
          onClick={handleFavoriteClick}
          disabled={favoriteLoading}
          size="small"
          startIcon={isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          sx={{
            bgcolor: isFavorite ? 'error.main' : 'transparent',
            color: isFavorite ? 'error.contrastText' : 'error.main',
            borderColor: 'error.main',
            '&:hover': {
              bgcolor: isFavorite ? 'error.dark' : alpha(theme.palette.error.main, 0.1),
              borderColor: 'error.main'
            }
          }}
        >
          {t('movie', isFavorite ? 'removeFromFavorites' : 'addToFavorites')}
        </Button>

        <Button
          variant={isWatchLater ? "contained" : "outlined"}
          onClick={handleWatchLaterClick}
          disabled={watchLaterLoading}
          size="small"
          startIcon={isWatchLater ? <WatchLaterIcon /> : <WatchLaterOutlinedIcon />}
        >
          {t('movie', isWatchLater ? 'removeFromWatchLater' : 'addToWatchLater')}
        </Button>

        <Button
          variant={useBackupPlayer ? "contained" : "outlined"}
          onClick={togglePlayer}
          size="small"
          startIcon={<MovieIcon />}
        >
          {useBackupPlayer ? '切换默认播放器' : '切换备用播放器'}
        </Button>
      </Stack>

      {/* Right side controls group */}
      <Stack 
        direction="row" 
        spacing={2}
        alignItems="center"
        sx={{
          width: { xs: '100%', sm: 'auto' },
          justifyContent: { xs: 'space-between', sm: 'flex-end' },
          ml: { sm: 'auto' }
        }}
      >
        {/* Share and Help buttons */}
        <Stack 
          direction="row" 
          spacing={1.5}
        >
          <IconButton 
            onClick={handleShare}
            size="small"
            sx={{ 
              color: 'text.secondary',
              width: { xs: '36px', sm: '36px' },
              height: { xs: '36px', sm: '36px' }
            }}
          >
            <ShareIcon fontSize="small" />
          </IconButton>

          <IconButton 
            onClick={handleOpenHelpDialog}
            size="small"
            sx={{ 
              color: 'text.secondary',
              width: { xs: '36px', sm: '36px' },
              height: { xs: '36px', sm: '36px' }
            }}
          >
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </Stack>

        {/* Autoplay switch */}
        {video?.episodes && video.episodes.length > 1 && (
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={autoplayEnabled}
                onChange={handleAutoplayToggle}
              />
            }
            label={
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{
                  fontSize: '0.875rem'
                }}
              >
                {t('watch', 'autoplayNext')}
              </Typography>
            }
            sx={{
              m: 0,
              userSelect: 'none'
            }}
          />
        )}
      </Stack>
    </Stack>
  );

  const togglePlayer = () => {
    const newValue = !useBackupPlayer;
    setUseBackupPlayer(newValue);
    
    // Set cookie with proper configuration
    Cookies.set('preferBackupPlayer', String(newValue), {
        expires: 30,
        path: '/',
        sameSite: 'Lax'
    });
    
    // Remove existing iframe
    const container = document.getElementById('player-container');
    if (!container) return;
    
    const existingIframe = container.querySelector('iframe');
    if (existingIframe) {
        existingIframe.remove();
    }
    
    setIsLoading(true);
    
    // Create and append new iframe with enhanced fullscreen support
    const newIframe = document.createElement('iframe');
    newIframe.src = `/${newValue ? 'backup-player' : 'artplayer'}.html?${new URLSearchParams({
        url: encodeURIComponent(video?.vod_play_url || ''),
        name: encodeURIComponent(video?.vod_name || ''),
        episode_name: encodeURIComponent(video?.currentEpisode?.episode_name || ''),
        episode: selectedEpisode.toString(),
        route: encodeURIComponent(video?.available_routes?.[selectedRoute] || ''),
        start_time: watchDuration.toString()
    }).toString()}`;
    
    // Add all necessary attributes for fullscreen support
    newIframe.allowFullscreen = true;
    newIframe.webkitallowfullscreen = true;
    newIframe.mozallowfullscreen = true;
    newIframe.allow = 'autoplay; fullscreen; picture-in-picture';
    newIframe.style.cssText = `
        border: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000;
    `;
    
    newIframe.onload = () => {
        setIsLoading(false);
    };
    
    container.appendChild(newIframe);
  };

  // Update the useEffect that handles player initialization
  useEffect(() => {
    if (!video?.vod_play_url) return;
    
    const container = document.getElementById('player-container');
    if (!container) return;
    
    // Clear any existing iframes
    const existingIframe = container.querySelector('iframe');
    if (existingIframe) {
        existingIframe.remove();
    }
    
    setIsLoading(true);
    
    // Get the current preference from cookie to ensure consistency
    const preferBackupPlayer = Cookies.get('preferBackupPlayer') === 'true';
    
    // Update state if it doesn't match cookie (without triggering re-render)
    if (preferBackupPlayer !== useBackupPlayer) {
        setUseBackupPlayer(preferBackupPlayer);
    }
    
    const iframe = document.createElement('iframe');
    iframe.src = `/${preferBackupPlayer ? 'backup-player' : 'artplayer'}.html?${new URLSearchParams({
        url: encodeURIComponent(video.vod_play_url),
        name: encodeURIComponent(video.vod_name || ''),
        episode_name: encodeURIComponent(video.currentEpisode?.episode_name || ''),
        episode: selectedEpisode.toString(),
        route: encodeURIComponent(video.available_routes?.[selectedRoute] || ''),
        start_time: watchDuration.toString()
    }).toString()}`;
    
    // Add all necessary attributes for fullscreen support
    iframe.allowFullscreen = true;
    iframe.webkitallowfullscreen = true;
    iframe.mozallowfullscreen = true;
    iframe.allow = 'autoplay; fullscreen; picture-in-picture';
    iframe.style.cssText = `
        border: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000;
    `;
    
    iframe.onload = () => {
        setIsLoading(false);
    };
    
    container.appendChild(iframe);

    // Cleanup function
    return () => {
        const iframe = container.querySelector('iframe');
        if (iframe) {
            iframe.remove();
        }
    };
  }, [video?.vod_play_url, useBackupPlayer]); // Only depend on video URL and player preference

  // Add a separate effect to handle player type changes
  useEffect(() => {
    if (!video?.vod_play_url) return;
    
    const container = document.getElementById('player-container');
    if (!container) return;
    
    const existingIframe = container.querySelector('iframe');
    if (!existingIframe) return;
    
    const currentPlayerType = existingIframe.src.includes('backup-player') ? 'backup' : 'art';
    const desiredPlayerType = useBackupPlayer ? 'backup' : 'art';
    
    if (currentPlayerType !== desiredPlayerType) {
      togglePlayer();
    }
  }, [useBackupPlayer]);

  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        
        <title>{video ? `${video.vod_name} - 247看 在线观看` : '247看 - 在线观看'}</title>
        
        <meta property="og:title" content={`${video?.vod_name || ''} - 247看 在线观看`} />
        <meta property="og:description" content={`立即在247看观看${video?.vod_name || ''}`} />
        <meta property="og:image" content={video?.vod_pic || ''} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Snackbar 
        open={Boolean(showError && error)}
        autoHideDuration={6000} 
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseError} severity="error">
          {error || 'An error occurred'}
        </Alert>
      </Snackbar>

      <Box sx={{ minHeight: '100vh', bgcolor: 'background.default', position: 'relative' }}>
        {/* Video Player Container */}
        <Box 
          id="player-container"
          sx={{ 
            width: '100%',
            height: { xs: '40vh', sm: '50vh', md: '70vh' },
            bgcolor: 'black',
            position: 'relative',
            overflow: 'hidden',
            marginTop: { xs: '56px', sm: '64px' },
            '& iframe': {
              width: '100% !important',
              height: '100% !important',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              border: 'none',
              pointerEvents: 'auto',
              zIndex: 1,
              background: '#000'
            }
          }}
        >
          {/* Loading Overlay */}
          {isLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                bgcolor: 'rgba(0, 0, 0, 0.9)',
                zIndex: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pointerEvents: isLoading ? 'auto' : 'none'
              }}
            >
              <Stack
                spacing={2}
                alignItems="center"
              >
                <MovieIcon
                  sx={{
                    fontSize: '3rem',
                    color: 'primary.main',
                    animation: 'pulse 2s infinite',
                    '@keyframes pulse': {
                      '0%': {
                        transform: 'scale(1)',
                        opacity: 1,
                      },
                      '50%': {
                        transform: 'scale(1.2)',
                        opacity: 0.7,
                      },
                      '100%': {
                        transform: 'scale(1)',
                        opacity: 1,
                      },
                    },
                  }}
                />
                <Typography
                  variant="h4"
                  component="div"
                  sx={{
                    color: 'primary.main',
                    fontWeight: 600,
                    fontFamily: "'Inter', sans-serif",
                    letterSpacing: '.05rem',
                  }}
                >
                  247看
                </Typography>
                <CircularProgress
                  size={40}
                  thickness={4}
                  sx={{
                    color: 'primary.main',
                    mt: 2,
                  }}
                />
                <Typography
                  variant="body2"
                  align="center"
                  sx={{
                    color: 'text.secondary',
                    maxWidth: '280px',
                    mt: 2,
                    lineHeight: 1.6,
                  }}
                >
                  如果一直显示此画面，请尝试切换其他线路。若所有线路均无法播放，可能是您的浏览器不支持广告移除插件，建议使用Chrome或者Safari浏览器，谢谢。
                </Typography>
              </Stack>
            </Box>
          )}
        </Box>

        {/* Floating Help Button */}
        <Box
          sx={{
            position: 'fixed',
            bottom: { xs: 24, sm: 32 },
            left: { xs: 16, sm: 32 },
            zIndex: 1000,
          }}
        >
          <Button
            variant="contained"
            onClick={handleOpenHelpDialog}
            startIcon={<HelpOutlineIcon />}
            sx={{
              borderRadius: 8,
              px: 2,
              py: 1,
              bgcolor: alpha(theme.palette.primary.main, 0.9),
              backdropFilter: 'blur(8px)',
              boxShadow: theme.shadows[4],
              '&:hover': {
                bgcolor: theme.palette.primary.main,
                boxShadow: theme.shadows[8],
              },
              transition: theme.transitions.create(['background-color', 'box-shadow', 'transform'], {
                duration: theme.transitions.duration.short
              }),
              '&:active': {
                transform: 'scale(0.98)'
              },
              textTransform: 'none',
              fontSize: { xs: '0.875rem', sm: '1rem' },
              fontWeight: 500,
              border: 1,
              borderColor: alpha(theme.palette.common.white, 0.1),
              '@media (max-width: 600px)': {
                minWidth: 'auto',
                px: 1.5,
                '& .MuiButton-startIcon': {
                  mr: 0.5
                }
              }
            }}
          >
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>遇到问题？</Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>帮助</Box>
          </Button>
        </Box>

        {/* Dialog component */}
        <Dialog
          open={openHelpDialog}
          onClose={handleCloseHelpDialog}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2,
              bgcolor: 'background.paper',
              backgroundImage: 'none',
              maxWidth: { xs: '95%', sm: '500px' }
            }
          }}
        >
          <DialogTitle sx={{ 
            pb: 1,
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
            fontWeight: 600,
            color: 'primary.main',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}>
            <HelpOutlineIcon sx={{ fontSize: { xs: '1.5rem', sm: '1.75rem' } }} />
            使用帮助与提示
          </DialogTitle>
          <DialogContent>
            <Stack spacing={3}>
              <Box>
                <Typography variant="subtitle1" color="primary" gutterBottom sx={{ fontWeight: 600 }}>
                  播放器选择
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  如果默认播放器无法正常播放，请尝试点击 <MovieIcon sx={{ fontSize: '1rem', verticalAlign: 'middle' }}/> 按钮切换到备用播放器。
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  备用播放器采用更简单的播放方式，兼容性更好，适合部分第三方浏览器或者安卓系统。
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle1" color="primary" gutterBottom sx={{ fontWeight: 600 }}>
                  浏览器兼容
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  如果播放器无法全屏或遇到其他问题，请尝试使用以下浏览器：Chrome、Safari、Edge 或 Firefox。这些浏览器都能很好地支持我们的播放器功能。
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle1" color="primary" gutterBottom sx={{ fontWeight: 600 }}>
                  投屏说明
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  使用AirPlay投屏时，无法调整播放速度，这是AirPlay协议的限制。
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  投屏到电视时会出现广告，因为广告移除插件在电视端无法生效。建议直接在电视端浏览247看网站在线观看。
                </Typography>
                <Typography variant="body2" color="error" sx={{ fontWeight: 500 }}>
                  请勿误触投屏按钮，因为投屏功能暂不支持去广告功能，会导致视频刷新使用原始m3u8源从而出现广告。如果不小心点击，请刷新页面即可恢复去广告功能。
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle1" color="error" gutterBottom sx={{ fontWeight: 600 }}>
                  广告提醒
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  请勿相信影片中任何浮水印跑马灯广告等非本站提供。本站没有任何广告，如发现任何插播广告十分影响观影，请到留言区留言视频链接反馈。
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle1" color="info.main" gutterBottom sx={{ fontWeight: 600 }}>
                  画质说明
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  如果当前视频画质不清晰/模糊/卡顿，请尝试切换其他线路观看。
                </Typography>
              </Box>

              <Box sx={{ 
                mt: 2,
                p: 2, 
                bgcolor: alpha(theme.palette.primary.main, 0.08),
                borderRadius: 2,
                border: 1,
                borderColor: alpha(theme.palette.primary.main, 0.2),
              }}>
                <Typography variant="subtitle1" color="primary" gutterBottom sx={{ 
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1 
                }}>
                  还是有问题？
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  如果以上建议都无法解决您的问题，请到留言板向我们反馈详细情况，我们会尽快处理。
                </Typography>
                <Button
                  component={Link}
                  to="/guestbook"
                  variant="outlined"
                  size="small"
                  onClick={handleCloseHelpDialog}
                  sx={{
                    mt: 1,
                    textTransform: 'none',
                    borderRadius: 1.5,
                    fontSize: '0.875rem'
                  }}
                >
                  前往留言板
                </Button>
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 2 }}>
            <Button 
              onClick={handleCloseHelpDialog} 
              variant="contained"
              sx={{
                minWidth: 100,
                textTransform: 'none',
                borderRadius: 1.5
              }}
            >
              知道了
            </Button>
          </DialogActions>
        </Dialog>

        {/* Content Info & Episodes */}
        <Container 
          maxWidth="xl" 
          sx={{ 
            mt: { xs: 2, sm: 3 },
            mb: { xs: 4, sm: 6 },
            px: { xs: 1, sm: 2, md: 3 }
          }}
        >
          <Grid container spacing={{ xs: 2, sm: 3 }}>
            {/* Left Column - Video Info */}
            <Grid item xs={12} md={8}>
              <Stack spacing={{ xs: 2, sm: 3 }}>
                {/* Title, Description & Actions */}
                <Box 
                  sx={{ 
                    p: { xs: 1.5, sm: 2 },
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    boxShadow: 1
                  }}
                >
                  <Stack 
                    direction={{ xs: 'column', sm: 'column' }}
                    justifyContent="space-between" 
                    alignItems="flex-start"
                    spacing={{ xs: 1.5, sm: 2 }}
                    sx={{ width: '100%' }}
                  >
                    {/* Left side: Title and metadata */}
                    <Box sx={{ 
                      flex: 1,
                      minWidth: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: { xs: 1, sm: 1.5 }
                    }}>
                      {/* Title and metadata row */}
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2 }}
                        alignItems={{ xs: 'flex-start', sm: 'center' }}
                        justifyContent="space-between"
                      >
                        {/* Title and metadata in a row */}
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          sx={{ 
                            flex: 1,
                            minWidth: 0,
                            flexWrap: 'wrap',
                            gap: { xs: 0.5, sm: 1 }
                          }}
                        >
                          <Typography 
                            variant="h5" 
                            sx={{ 
                              fontWeight: 700,
                              fontSize: { xs: '1.125rem', sm: '1.5rem' },
                              lineHeight: 1.4,
                              wordBreak: 'break-word',
                              mr: 1
                            }}
                          >
                            {video?.vod_name}
                          </Typography>

                          {video?.vod_remarks && (
                            <Typography 
                              component="span"
                              variant="body2"
                              sx={{ 
                                px: { xs: 1, sm: 1.5 },
                                py: { xs: 0.25, sm: 0.5 },
                                bgcolor: 'primary.main',
                                color: 'primary.contrastText',
                                borderRadius: '4px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                fontSize: { xs: '0.75rem', sm: '0.875rem' },
                                fontWeight: 500,
                                lineHeight: 1.2,
                                whiteSpace: 'nowrap',
                                flexShrink: 0,
                                height: { xs: '20px', sm: '24px' }
                              }}
                            >
                              {video.vod_remarks}
                            </Typography>
                          )}
                        </Stack>

                        {/* Episode navigation buttons */}
                        {video?.episodes && video.episodes.length > 1 && (
                          <Stack 
                            direction="row" 
                            spacing={1}
                            alignItems="center"
                            sx={{
                              flexShrink: 0,
                              ml: { xs: 0, sm: 2 }
                            }}
                          >
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={handlePrevEpisode}
                              disabled={!video?.episodes?.some(ep => 
                                ep.route === video.available_routes[selectedRoute] && 
                                ep.episode === selectedEpisode - 1
                              )}
                              sx={{
                                minWidth: { xs: '60px', sm: '100px' },
                                height: { xs: '28px', sm: '36px' },
                                px: { xs: 0.5, sm: 2 },
                                fontSize: { xs: '0.75rem', sm: '1rem' },
                                borderRadius: 1,
                                color: 'text.secondary',
                                borderColor: 'divider',
                                '&:hover': {
                                  borderColor: 'primary.main',
                                  color: 'primary.main',
                                  bgcolor: alpha(theme.palette.primary.main, 0.04)
                                }
                              }}
                            >
                              上一集
                            </Button>
                            <Typography
                              variant="body2"
                              sx={{
                                color: 'text.primary',
                                fontSize: { xs: '0.75rem', sm: '1rem' },
                                fontWeight: 500,
                                minWidth: { xs: '1.5rem', sm: '2.5rem' },
                                textAlign: 'center'
                              }}
                            >
                              {selectedEpisode}
                            </Typography>
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={handleNextEpisode}
                              disabled={!video?.episodes?.some(ep => 
                                ep.route === video.available_routes[selectedRoute] && 
                                ep.episode === selectedEpisode + 1
                              )}
                              sx={{
                                minWidth: { xs: '60px', sm: '100px' },
                                height: { xs: '28px', sm: '36px' },
                                px: { xs: 0.5, sm: 2 },
                                fontSize: { xs: '0.75rem', sm: '1rem' },
                                borderRadius: 1,
                                color: 'text.secondary',
                                borderColor: 'divider',
                                '&:hover': {
                                  borderColor: 'primary.main',
                                  color: 'primary.main',
                                  bgcolor: alpha(theme.palette.primary.main, 0.04)
                                }
                              }}
                            >
                              下一集
                            </Button>
                          </Stack>
                        )}
                      </Stack>
                    </Box>

                    {/* Description */}
                    <Box sx={{ 
                      mt: 2,
                      width: '100%',
                      borderTop: 1,
                      borderColor: 'divider',
                      pt: 2
                    }}>
                      {/* Always visible first line */}
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ 
                          fontSize: { xs: '0.875rem', sm: '0.9375rem' },
                          lineHeight: 1.6,
                          display: '-webkit-box',
                          WebkitLineClamp: descriptionExpanded ? 'unset' : 1,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          mb: descriptionExpanded ? 1 : 0
                        }}
                      >
                        {video?.vod_content || t('common', 'noDescription')}
                      </Typography>

                      {/* Show button only if content is longer than one line */}
                      {((video?.vod_content || '').length > 50) && (
                        <Button
                          onClick={() => setDescriptionExpanded(!descriptionExpanded)}
                          endIcon={descriptionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          sx={{
                            color: 'text.secondary',
                            textTransform: 'none',
                            mt: 0.5,
                            '&:hover': {
                              bgcolor: 'action.hover'
                            }
                          }}
                        >
                          {descriptionExpanded ? t('common', 'showLess') : t('common', 'showMore')}
                        </Button>
                      )}
                    </Box>

                    {/* Right side: Action buttons - Move this after the description */}
                    {renderVideoActions()}
                  </Stack>
                </Box>

                {/* Episodes Section */}
                <Box sx={{ 
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                  boxShadow: 1,
                  p: { xs: 2, sm: 3 }
                }}>
                  <Stack spacing={{ xs: 2, sm: 3 }}>
                    <RouteSelector 
                      routes={video?.available_routes || []}
                      selectedRoute={selectedRoute}
                      onRouteSelect={handleRouteSelect}
                      routeNames={routeNames}
                    />
                    
                    {video?.episodes && (
                      <EpisodeSelector 
                        episodes={video.episodes}
                        selectedEpisode={selectedEpisode}
                        selectedRoute={selectedRoute}
                        onEpisodeSelect={handleEpisodeSelect}
                        video={video}
                      />
                    )}
                  </Stack>
                </Box>

                {/* Comments Section */}
                <Box sx={{ 
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                  boxShadow: 1,
                  p: { xs: 2, sm: 3 },
                  mt: { xs: 2, sm: 3 }
                }}>
                  <Box 
                    sx={{ 
                      position: 'relative',
                      mt: -1,
                    }}
                  >
                    <Comments videoId={id} />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'none',
                        '&.error': {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'rgba(0, 0, 0, 0.5)',
                          color: 'white',
                          zIndex: 1000
                        }
                      }}
                      id="turnstile-error-overlay"
                    >
                      <Typography variant="body1">
                        验证加载失败，请刷新页面重试
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </Grid>

            {/* Right Column - Recommendations */}
            <Grid item xs={12} md={4}>
              <Stack spacing={{ xs: 2, sm: 3 }}>
                {/* Similar Movies */}
                {(similarMovies.length > 0 || recommendations.length > 0) && (
                  <Box sx={{ 
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    boxShadow: 1,
                    p: { xs: 1.5, sm: 2 }
                  }}>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontWeight: 600, 
                        mb: { xs: 1.5, sm: 2 },
                        fontSize: { xs: '1.125rem', sm: '1.25rem' }
                      }}
                    >
                      {t('watch', 'recommendations')}
                    </Typography>
                    <Stack spacing={{ xs: 1.5, sm: 2 }}>
                      {Array.from(new Set([...recommendations, ...similarMovies].map(m => m.vod_id)))
                        .slice(0, 5)
                        .map(vodId => {
                          const item = [...recommendations, ...similarMovies].find(m => m.vod_id === vodId);
                          return (
                            <Card
                              key={item.vod_id}
                              component={Link}
                              to={`/watch/${item.vod_id}`}
                              sx={{ 
                                display: 'flex',
                                bgcolor: 'background.paper',
                                textDecoration: 'none',
                                '&:hover': {
                                  bgcolor: alpha(theme.palette.primary.main, 0.1),
                                },
                              }}
                            >
                              <CardMedia
                                component="img"
                                sx={{ 
                                  width: { xs: 100, sm: 120 },
                                  height: { xs: 56, sm: 68 },
                                  objectFit: 'cover' 
                                }}
                                image={item.vod_pic}
                                alt={item.vod_name}
                              />
                              <Box sx={{ p: { xs: 1, sm: 1.5 }, flex: 1, minWidth: 0 }}>
                                <Typography 
                                  variant="subtitle2" 
                                  gutterBottom 
                                  noWrap
                                  sx={{ 
                                    color: 'text.primary',
                                    fontSize: { xs: '0.813rem', sm: '0.875rem' }
                                  }}
                                >
                                  {item.vod_name}
                                </Typography>
                                <Stack direction="row" spacing={1} alignItems="center">
                                  {item.vod_remarks && (
                                    <Typography 
                                      variant="caption" 
                                      sx={{ 
                                        color: 'text.secondary',
                                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                                        px: 0.5,
                                        py: 0.25,
                                        borderRadius: 0.5,
                                        fontSize: { xs: '0.688rem', sm: '0.75rem' }
                                      }}
                                    >
                                      {item.vod_remarks}
                                    </Typography>
                                  )}
                                  {item.vod_score && !isNaN(parseFloat(item.vod_score)) && parseFloat(item.vod_score) > 0 && (
                                    <Typography 
                                      variant="caption" 
                                      sx={{ 
                                        color: 'warning.main',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 0.5,
                                        fontSize: { xs: '0.688rem', sm: '0.75rem' }
                                      }}
                                    >
                                      <StarIcon sx={{ fontSize: { xs: 10, sm: 12 } }} />
                                      {parseFloat(item.vod_score).toFixed(1)}
                                    </Typography>
                                  )}
                                </Stack>
                              </Box>
                            </Card>
                          );
                        })}
                    </Stack>
                  </Box>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Snackbar
        open={Boolean(snackbarMessage)}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          bottom: { xs: '24px', sm: '32px' },
          width: { xs: 'calc(100% - 32px)', sm: 'auto' },
          maxWidth: { xs: '100%', sm: '400px' }
        }}
        ContentProps={{
          sx: {
            bgcolor: theme => snackbarMessage === t('common', 'pleaseLoginFirst') 
              ? alpha(theme.palette.error.main, theme.palette.mode === 'dark' ? 0.9 : 0.95)
              : theme.palette.mode === 'dark' 
                ? alpha(theme.palette.primary.main, 0.85)
                : alpha(theme.palette.primary.main, 0.9),
            color: 'common.white',
            fontWeight: { xs: 400, sm: 500 },
            fontSize: { xs: '0.9375rem', sm: '1rem' },
            px: { xs: 2, sm: 3 },
            py: { xs: 1.25, sm: 1.5 },
            borderRadius: { xs: 1.5, sm: 2 },
            backdropFilter: 'blur(8px)',
            border: 1,
            borderColor: theme => alpha(theme.palette.common.white, theme.palette.mode === 'dark' ? 0.1 : 0.15),
            boxShadow: theme => theme.palette.mode === 'dark'
              ? `0 4px 20px ${alpha('#000', 0.4)}`
              : `0 4px 16px ${alpha(theme.palette.primary.main, 0.16)}`,
            '& .MuiSnackbarContent-message': {
              textAlign: 'center',
              width: '100%',
              lineHeight: 1.5
            }
          }
        }}
      />
    </Layout>
  );
};

export default Watch; 