import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Chip,
  CircularProgress,
  useTheme,
  Tabs,
  Tab,
  Paper,
  Drawer,
  IconButton,
  useMediaQuery,
  Fade,
  Skeleton,
  Alert,
  Button,
  Tooltip,
  Divider,
  Badge,
  Pagination,
  Link,
  Card,
  CardContent,
  CardMedia,
  TextField,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
  List,
  ListItemButton,
  ListItemText,
  Checkbox
} from '@mui/material';
import { useLanguage } from '../contexts/LanguageContext';
import Layout from '../components/Layout';
import ContentCard from '../components/ContentCard';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import HomeIcon from '@mui/icons-material/Home';
import TuneIcon from '@mui/icons-material/Tune';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StarIcon from '@mui/icons-material/Star';
import UpdateIcon from '@mui/icons-material/Update';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import { getTypes, getVideosByCategory, getCategories, searchVideos, getGenresByCategory, getAreasByCategory } from '../services/api';
import { alpha } from '@mui/material/styles';
import MovieDetail from '../pages/MovieDetail';
import debounce from 'lodash/debounce';
import ContentDiscovery from '../components/ContentDiscovery';
import ExploreIcon from '@mui/icons-material/Explore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RandomOverlay from '../components/RandomOverlay';

// Define translations with fallbacks
const translations = {
  filters: {
    title: '影视筛选 - 高清电影、连续剧、动漫、综艺节目在线观看',
    area: '地区',
    language: '语言',
    year: '年份',
    version: '版本',
    sortBy: '排序',
    clearAll: '清除所有筛选',
    search: '搜索',
    actor: '演员',
    director: '导演',
    state: '类型',
    letter: '字母',
    tag: '标签',
    genre: '类型',
    trending: '热门内容',
    top250: '豆瓣Top 250',
    all: '全部',
    quickFilters: '快速筛选',
    basicFilters: '基本筛选',
    advancedFilters: '高级筛选',
    sortOptions: '排序选项',
    clearAllFilters: '清除所有筛选',
    filterCount: '筛选',
    gridView: '网格视图',
    listView: '列表视图',
    resultsCount: '${count}个结果'
  },
  browse: {
    all: '全部影视',
    latest: '最新上线',
    mostViewed: '热播榜单',
    highestRated: '豆瓣电影Top 250',
    noContent: '暂无内容',
    noVideosFound: '没有找到相关影视',
    tryDifferentFilters: '请尝试其他筛选条件',
    allContent: '全部内容',
    clearFilters: '清除筛选',
    byYear: '按年份',
    results: '个结果',
    metaTitle: '247看 - 免费在线观看高清电影、电视剧、动漫、综艺',
    metaDescription: '海量高清影视资源，1080P蓝光画质，支持手机观看，无广告，免费在线观看，实时更新，国产剧、韩剧、美剧、日剧、港剧、台剧应有尽有',
    metaKeywords: '在线影视,高清电影,连续剧在线,动漫视频,综艺节目,纪录片,短剧,剧集,1080P高清,蓝光画质,无广告播放,免费观看,在线播放,直接观看,无需下载,手机观看,免费高清,追剧,追番,最新电影,热播剧集,热门动漫,新番动漫,热映电影,同步更新,实时更新',
    categories: {
      movie: '电影',
      drama: '电视剧',
      anime: '动漫',
      variety: '综艺',
      documentary: '纪录片'
    },
    features: {
      hd: '1080P高清',
      bluray: '蓝光画质',
      noAds: '无广告',
      free: '免费观看',
      mobile: '手机观看',
      update: '实时更新'
    }
  },
  errors: {
    failedToLoadVideos: '加载视频失败，请稍后重试'
  }
};

const LoadingSkeleton = ({ viewMode = 'grid', isMobile }) => (
  <Grid container spacing={2}>
    {[...Array(12)].map((_, index) => (
      <Grid 
        item 
        xs={viewMode === 'list' ? 12 : 6} 
        sm={viewMode === 'list' ? 12 : 6} 
        md={viewMode === 'list' ? 12 : 4} 
        lg={viewMode === 'list' ? 12 : 2.4} 
        key={index}
      >
        {viewMode === 'list' ? (
          <Skeleton 
            variant="rectangular" 
            height={isMobile ? 100 : 120} 
            sx={{ 
              borderRadius: 2,
              transform: 'none',
              animation: 'wave',
            }} 
          />
        ) : (
          <Skeleton 
            variant="rectangular" 
            height={isMobile ? 200 : 300} 
            sx={{ 
              borderRadius: 2,
              transform: 'none',
              animation: 'wave',
            }} 
          />
        )}
      </Grid>
    ))}
  </Grid>
);

const Browse = () => {
  const { t } = useLanguage();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get('category');
  
  // Add these two lines here
  const [randomDialogOpen, setRandomDialogOpen] = useState(false);
  const [randomVideo, setRandomVideo] = useState(null);

  // Add a helper function to scroll to top smoothly
  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [videos, setVideos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [filters, setFilters] = useState({
    area: searchParams.get('area')?.split(',').filter(Boolean) || [],
    year: {
      start: searchParams.get('yearStart') || '',
      end: searchParams.get('yearEnd') || ''
    },
    actor: searchParams.get('actor') || '',
    director: searchParams.get('director') || '',
    letter: searchParams.get('letter') || '',
    tag: searchParams.get('tag') || '',
    genre: searchParams.get('genre')?.split(',').filter(Boolean) || [],
    sort: searchParams.get('sort') || '-vod_level',
    top250: searchParams.get('top250') === 'true'
  });
  const [selectedMasterCategory, setSelectedMasterCategory] = useState(null);
  const [subcategories, setSubcategories] = useState([]);

  // Add cache for videos
  const [videoCache, setVideoCache] = useState({});
  
  // Create a cache key based on current filters and page
  const cacheKey = useMemo(() => {
    return JSON.stringify({
      categoryId,
      page,
      filters,
    });
  }, [categoryId, page, filters]);

  // Debounced filter change handler
  const debouncedFilterChange = useCallback(
    debounce((key, value) => {
      setPage(1);
      setFilters(prev => ({
        ...prev,
        [key]: value
      }));
    }, 300),
    []
  );

  // Calculate active filters count
  const activeFiltersCount = useMemo(() => {
    let count = 0;
    if (filters.area?.length > 0) count += filters.area.length;
    if (filters.genre?.length > 0) count += filters.genre.length;
    if (filters.year?.start || filters.year?.end) count += 1;
    if (filters.actor) count += 1;
    if (filters.director) count += 1;
    if (filters.tag) count += 1;
    if (filters.top250) count += 1;
    return count;
  }, [filters]);

  // Update URL when filters change
  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    
    // Add category if exists
    if (categoryId) {
      newSearchParams.set('category', categoryId);
    }
    
    // Add sort
    if (filters.sort) {
      newSearchParams.set('sort', filters.sort);
    }
    
    // Add area and genre (ensure unique values)
    if (filters.area?.length > 0) {
      const uniqueAreas = [...new Set(filters.area)];
      newSearchParams.set('area', uniqueAreas.join(','));
    }
    
    if (filters.genre?.length > 0) {
      const uniqueGenres = [...new Set(filters.genre)];
      newSearchParams.set('genre', uniqueGenres.join(','));
    }
    
    // Add year range
    if (filters.year?.start) {
      newSearchParams.set('yearStart', filters.year.start);
    }
    if (filters.year?.end) {
      newSearchParams.set('yearEnd', filters.year.end);
    }
    
    // Add other filters
    if (filters.actor) newSearchParams.set('actor', filters.actor);
    if (filters.director) newSearchParams.set('director', filters.director);
    if (filters.tag) newSearchParams.set('tag', filters.tag);
    if (filters.top250) newSearchParams.set('top250', 'true');
    
    // Add page if not first page
    if (page > 1) {
      newSearchParams.set('page', page.toString());
    }
    
    setSearchParams(newSearchParams, { replace: true });
  }, [filters, page, categoryId]);

  // Add effect to handle tag parameter from URL
  useEffect(() => {
    const tagFromUrl = searchParams.get('tag');
    if (tagFromUrl && tagFromUrl !== filters.tag) {
      setFilters(prev => ({
        ...prev,
        tag: tagFromUrl
      }));
      // Reset page when tag changes
      setPage(1);
    }
  }, [searchParams]);

  const processVideoData = (video) => {
    const cleanList = (value) => {
      if (!value || value === null || value === undefined) return [];
      if (Array.isArray(value)) return value.map(item => String(item || '').trim());
      if (typeof value === 'string') return value.split(',').map(item => item.trim());
      return [String(value || '').trim()];
    };

    return {
      ...video,
      vod_actor: cleanList(video.vod_actor),
      vod_director: cleanList(video.vod_director),
      vod_class: cleanList(video.vod_class)
    };
  };

  // Update fetchVideos to use cache
  useEffect(() => {
    const fetchVideos = async () => {
      // Check cache first
      if (videoCache[cacheKey]) {
        setVideos(videoCache[cacheKey].videos.map(processVideoData));
        setTotalPages(videoCache[cacheKey].totalPages);
        return;
      }

      setLoading(true);
      setError(null);
      try {
        let result;
        const pageSize = 20;
        
        if (filters.top250) {
          result = await getVideosByCategory(
            categoryId || 'all',
            page,
            pageSize,
            'vod_top250_rank',
            { 
              ...filters,
              top250: true,
              page,
              limit: pageSize,
              minTop250Rank: 1,
              maxTop250Rank: 250
            }
          );
        } else if (filters.sort === '-vod_douban_score') {
          result = await getVideosByCategory(
            categoryId || 'all',
            page,
            pageSize,
            '-vod_douban_score',
            {
              ...filters,
              page,
              limit: pageSize,
              minVodDoubanScore: 0
            }
          );
        } else {
          result = await getVideosByCategory(
            categoryId || 'all',
            page,
            pageSize,
            filters.sort,
            {
              ...filters,
              page,
              limit: pageSize
            }
          );
        }

        if (result && result.videos) {
          let processedVideos;
          
          if (filters.top250) {
            processedVideos = result.videos
              .filter(video => video.vod_top250_rank >= 1 && video.vod_top250_rank <= 250)
              .sort((a, b) => a.vod_top250_rank - b.vod_top250_rank)
              .map(processVideoData);
          } else if (filters.sort === '-vod_douban_score') {
            processedVideos = result.videos
              .filter(video => video.vod_douban_score > 0)
              .sort((a, b) => b.vod_douban_score - a.vod_douban_score)
              .map(processVideoData);
          } else if (filters.sort === '-vod_level') {
            processedVideos = result.videos
              .sort((a, b) => {
                // First sort by vod_level
                if (a.vod_level !== b.vod_level) {
                  return b.vod_level - a.vod_level;
                }
                // If vod_level is the same (especially for 0), sort by vod_hits
                return b.vod_hits - a.vod_hits;
              })
              .map(processVideoData);
          } else {
            processedVideos = result.videos.map(processVideoData);
          }

          // Update cache
          setVideoCache(prev => ({
            ...prev,
            [cacheKey]: {
              videos: processedVideos,
              totalPages: Math.ceil(result.pagination.total / pageSize)
            }
          }));

          setVideos(processedVideos);
          setTotalPages(Math.ceil(result.pagination.total / pageSize));
        } else {
          setVideos([]);
          setTotalPages(0);
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
        setError(translations.errors.failedToLoadVideos);
        setVideos([]);
        setTotalPages(0);
      } finally {
        setLoading(false);
      }
    };
    fetchVideos();
  }, [cacheKey]);

  // Add debounced handlers for advanced filters
  const debouncedActorChange = useCallback(
    debounce((value) => {
      setFilters(prev => ({
        ...prev,
        actor: value
      }));
    }, 500),
    []
  );

  const debouncedDirectorChange = useCallback(
    debounce((value) => {
      setFilters(prev => ({
        ...prev,
        director: value
      }));
    }, 500),
    []
  );

  const debouncedTagChange = useCallback(
    debounce((value) => {
      setFilters(prev => ({
        ...prev,
        tag: value
      }));
    }, 500),
    []
  );

  // Add local state for advanced filter inputs
  const [localFilters, setLocalFilters] = useState({
    actor: '',
    director: '',
    tag: ''
  });

  // Update useEffect for URL params to include local filters
  useEffect(() => {
    setLocalFilters({
      actor: searchParams.get('actor') || '',
      director: searchParams.get('director') || '',
      tag: searchParams.get('tag') || ''
    });
  }, [searchParams]);

  // Update the sort value mapping
  const sortValueMapping = {
    '-vod_level': 'trending',
    '-vod_time': 'latest',
    '-vod_douban_score': 'rating'
  };

  const sortApiMapping = {
    'trending': '-vod_level',
    'latest': '-vod_time',
    'rating': '-vod_douban_score'
  };

  // Update handleFilterChange to scroll to top when filters change
  const handleFilterChange = (key, value) => {
    if (key === 'sort') {
      setFilters(prev => ({
        ...prev,
        sort: sortApiMapping[value] || value
      }));
      scrollToTop();
    } else if (key === 'area' || key === 'genre') {
      setFilters(prev => {
        const currentValues = prev[key] || [];
        let newValues;
        
        if (Array.isArray(value)) {
          // If value is an array (from Select's onChange)
          newValues = [...new Set(value)];
        } else {
          // If value is a single item (from chip deletion)
          newValues = currentValues.includes(value)
            ? currentValues.filter(v => v !== value)
            : [...new Set([...currentValues, value])];
        }
        
        return {
          ...prev,
          [key]: newValues
        };
      });
      scrollToTop();
    } else if (key === 'yearStart' || key === 'yearEnd') {
      setFilters(prev => ({
        ...prev,
        year: {
          ...prev.year,
          [key === 'yearStart' ? 'start' : 'end']: value
        }
      }));
      scrollToTop();
    } else {
      debouncedFilterChange(key, value);
      scrollToTop();
    }
  };

  const handleClearFilters = () => {
    setVideoCache({});
    setFilters({
      area: [],
      year: { start: '', end: '' },
      actor: '',
      director: '',
      letter: '',
      tag: '',
      genre: [],
      sort: '-vod_level',
      top250: false
    });
    setPage(1);
    const newParams = new URLSearchParams(searchParams);
    Object.keys(filters).forEach(key => {
      if (key !== 'sort') {
        if (key === 'year') {
          newParams.delete('yearStart');
          newParams.delete('yearEnd');
        } else {
          newParams.delete(key);
        }
      }
    });
    newParams.set('sort', '-vod_level');
    setSearchParams(newParams);
    scrollToTop();
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Get translation with fallback
  const getTranslation = (section, key) => {
    try {
      const translated = t(section, key);
      if (translated) return translated;
      
      // Safely check if translations and section exist
      if (translations && translations[section] && translations[section][key]) {
        return translations[section][key];
      }
      return key; // Fallback to the key itself
    } catch {
      return key;
    }
  };

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const mainCategories = [
          { type_id: 1, type_name: '电影' },
          { type_id: 2, type_name: '连续剧' },
          { type_id: 3, type_name: '综艺' },
          { type_id: 4, type_name: '动漫' },
          { type_id: 5, type_name: '短剧' },
          { type_id: 6, type_name: '纪录片' }
        ];
        setCategories(mainCategories);

        const result = await getTypes();
        if (result) {
          setAllCategories(result);
          
          if (categoryId) {
            const mainCategory = mainCategories.find(cat => String(cat.type_id) === categoryId);
            if (mainCategory) {
              setSelectedMasterCategory(mainCategory);
              setCurrentCategory(mainCategory);
              // Get subcategory range based on main category ID
              const ranges = {
                1: { start: 7, end: 13 },
                2: { start: 14, end: 21 },
                3: { start: 22, end: 25 },
                4: { start: 26, end: 30 }
              };
              const range = ranges[mainCategory.type_id];
              
              const subs = result.filter(cat => {
                const id = parseInt(cat.type_id);
                return range && id >= range.start && id <= range.end;
              });
              setSubcategories(subs);
            } else {
              // Handle subcategory selection
              const catId = parseInt(categoryId);
              // Find parent category based on subcategory ID ranges
              let parentId;
              if (catId >= 7 && catId <= 13) parentId = 1;
              else if (catId >= 14 && catId <= 21) parentId = 2;
              else if (catId >= 22 && catId <= 25) parentId = 3;
              else if (catId >= 26 && catId <= 30) parentId = 4;
              
              const mainCategory = mainCategories.find(cat => cat.type_id === parentId);
              if (mainCategory) {
                setSelectedMasterCategory(mainCategory);
                const ranges = {
                  1: { start: 7, end: 13 },
                  2: { start: 14, end: 21 },
                  3: { start: 22, end: 25 },
                  4: { start: 26, end: 30 }
                };
                const range = ranges[mainCategory.type_id];
                
                const subs = result.filter(cat => {
                  const id = parseInt(cat.type_id);
                  return range && id >= range.start && id <= range.end;
                });
                setSubcategories(subs);
                
                // Find and set the current subcategory
                const currentSubCategory = result.find(cat => String(cat.type_id) === categoryId);
                if (currentSubCategory) {
                  setCurrentCategory(currentSubCategory);
                }
              }
            }
          } else {
            setSelectedMasterCategory(null);
            setCurrentCategory(null);
          }
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError(getTranslation('errors', 'failedToLoadCategories'));
      }
    };
    fetchCategories();
  }, [categoryId]);

  const handleCategoryChange = (newCategoryId) => {
    setSearchParams({ category: newCategoryId });
    setPage(1);
    scrollToTop(); // Add scroll to top
    
    // Find and set current category if it's a subcategory
    if (subcategories.length > 0) {
      const subCategory = subcategories.find(sub => String(sub.type_id) === newCategoryId);
      if (subCategory) {
        setCurrentCategory(subCategory);
      } else if (selectedMasterCategory && String(selectedMasterCategory.type_id) === newCategoryId) {
        setCurrentCategory(selectedMasterCategory);
      }
    }
  };

  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };

  // Add effect to fetch genres when type_id changes
  const [dynamicGenres, setDynamicGenres] = useState([]);
  const [isLoadingGenres, setIsLoadingGenres] = useState(false);
  
  // Update effect to fetch genres using parent category when in subcategory
  useEffect(() => {
    const fetchGenres = async () => {
      setIsLoadingGenres(true);
      try {
        // If we're in a subcategory, use the parent category's genres
        let genreCategoryId = categoryId;
        if (selectedMasterCategory && categoryId !== String(selectedMasterCategory.type_id)) {
          // We're in a subcategory, use the master category ID instead
          genreCategoryId = String(selectedMasterCategory.type_id);
        }
        
        const genres = await getGenresByCategory(genreCategoryId || 'all');
        setDynamicGenres(genres);
      } catch (error) {
        console.error('Error fetching genres:', error);
        setDynamicGenres([]);
      } finally {
        setIsLoadingGenres(false);
      }
    };

    fetchGenres();
  }, [categoryId]);

  // Add state for dynamic areas
  const [dynamicAreas, setDynamicAreas] = useState([]);
  const [isLoadingAreas, setIsLoadingAreas] = useState(false);

  // Update effect to fetch areas when type_id changes
  useEffect(() => {
    const fetchAreas = async () => {
      setIsLoadingAreas(true);
      try {
        // If we're in a subcategory, use the parent category's areas
        let areaCategoryId = categoryId;
        if (selectedMasterCategory && categoryId !== String(selectedMasterCategory.type_id)) {
          // We're in a subcategory, use the master category ID instead
          areaCategoryId = String(selectedMasterCategory.type_id);
        }
        
        const areas = await getAreasByCategory(areaCategoryId || 'all');
        setDynamicAreas(areas);
      } catch (error) {
        console.error('Error fetching areas:', error);
        setDynamicAreas([]);
      } finally {
        setIsLoadingAreas(false);
      }
    };

    fetchAreas();
  }, [categoryId]);

  // Update filterOptions to use dynamic areas
  const filterOptions = {
    area: dynamicAreas,
    year: Array.from({ length: 74 }, (_, i) => (new Date().getFullYear() - i).toString()),
    letter: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
    genre: dynamicGenres
  };

  const [showDiscovery, setShowDiscovery] = useState(false);

  // Enhanced renderFilters function with better organization
  const renderFilters = () => (
    <Stack spacing={2} sx={{ p: 2 }}>
      {/* Basic Filters Section */}
      <Box>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {t('filters', 'basicFilters')}
          <Tooltip title="基本筛选选项">
            <IconButton size="small">
              <FilterListIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <Stack spacing={2}>
          {/* Genre Select */}
          <FormControl fullWidth size="small">
            <InputLabel>{t('filters', 'genre')}</InputLabel>
            <Select
              multiple
              value={filters.genre || []}
              label={t('filters', 'genre')}
              onChange={(e) => handleFilterChange('genre', e.target.value)}
              disabled={isLoadingGenres}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {[...new Set(selected)].map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
              startAdornment={
                isLoadingGenres ? (
                  <InputAdornment position="start">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ) : null
              }
            >
              {filterOptions.genre.map(option => (
                <MenuItem key={option} value={option}>
                  <Checkbox 
                    checked={filters.genre?.includes(option)} 
                    sx={{
                      '&.Mui-checked': {
                        color: 'primary.main'
                      }
                    }}
                  />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Area Select */}
          <FormControl fullWidth size="small">
            <InputLabel>{t('filters', 'area')}</InputLabel>
            <Select
              multiple
              value={filters.area || []}
              label={t('filters', 'area')}
              onChange={(e) => handleFilterChange('area', e.target.value)}
              disabled={isLoadingAreas}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
              startAdornment={
                isLoadingAreas ? (
                  <InputAdornment position="start">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ) : null
              }
            >
              {filterOptions.area.map(option => (
                <MenuItem key={option} value={option}>
                  <Checkbox 
                    checked={filters.area?.includes(option)} 
                    sx={{
                      '&.Mui-checked': {
                        color: 'primary.main'
                      }
                    }}
                  />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {t('filters', 'year')} (范围选择)
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <TextField
                size="small"
                label="起始年份"
                type="number"
                value={filters.year.start}
                onChange={(e) => handleFilterChange('yearStart', e.target.value)}
                inputProps={{
                  min: 1900,
                  max: new Date().getFullYear()
                }}
                sx={{ flex: 1 }}
              />
              <Typography color="text.secondary">至</Typography>
              <TextField
                size="small"
                label="结束年份"
                type="number"
                value={filters.year.end}
                onChange={(e) => handleFilterChange('yearEnd', e.target.value)}
                inputProps={{
                  min: 1900,
                  max: new Date().getFullYear()
                }}
                sx={{ flex: 1 }}
              />
            </Stack>
          </Box>
        </Stack>
      </Box>

      <Divider />

      {/* Advanced Filters Section with improved feedback */}
      <Box>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {t('filters', 'advancedFilters')}
          <Tooltip title="高级筛选选项">
            <IconButton size="small">
              <TuneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <Stack spacing={2}>
          <Tooltip title="按演员筛选">
            <TextField
              fullWidth
              size="small"
              label={t('filters', 'actor')}
              value={localFilters.actor}
              onChange={(e) => {
                const value = e.target.value;
                setLocalFilters(prev => ({ ...prev, actor: value }));
                debouncedActorChange(value);
              }}
              helperText="输入演员名称后等待自动搜索"
            />
          </Tooltip>
          <Tooltip title="按导演筛选">
            <TextField
              fullWidth
              size="small"
              label={t('filters', 'director')}
              value={localFilters.director}
              onChange={(e) => {
                const value = e.target.value;
                setLocalFilters(prev => ({ ...prev, director: value }));
                debouncedDirectorChange(value);
              }}
              helperText="输入导演名称后等待自动搜索"
            />
          </Tooltip>
          <Tooltip title="按标签筛选">
            <TextField
              fullWidth
              size="small"
              label={t('filters', 'tag')}
              value={localFilters.tag}
              onChange={(e) => {
                const value = e.target.value;
                setLocalFilters(prev => ({ ...prev, tag: value }));
                debouncedTagChange(value);
              }}
              helperText="输入标签关键词后等待自动搜索"
            />
          </Tooltip>
        </Stack>
      </Box>

      <Divider />
      {/* Enhanced Clear Filters Button */}
      {activeFiltersCount > 0 && (
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={handleClearFilters}
          startIcon={<ClearAllIcon />}
          sx={{
            mt: 2,
            borderStyle: 'dashed',
            '&:hover': {
              borderStyle: 'solid'
            }
          }}
        >
          {t('filters', 'clearAllFilters')} ({activeFiltersCount})
        </Button>
      )}
    </Stack>
  );

  // Render active filters
  const renderActiveFilters = () => {
    const activeFilters = [];
    
    if (filters.area?.length > 0) {
      activeFilters.push({
        key: 'area',
        type: 'area',
        value: filters.area.join(',')
      });
    }
    
    if (filters.genre?.length > 0) {
      activeFilters.push({
        key: 'genre',
        type: 'genre',
        value: filters.genre.join(',')
      });
    }
    
    // Add year range filter
    if (filters.year?.start || filters.year?.end) {
      const yearLabel = `${filters.year.start || '不限'} - ${filters.year.end || '不限'}`;
      activeFilters.push({
        key: 'year',
        type: 'year',
        value: yearLabel
      });
    }
    
    // Add other filters
    if (filters.actor) {
      activeFilters.push({
        key: 'actor',
        type: 'actor',
        value: filters.actor
      });
    }
    
    if (filters.director) {
      activeFilters.push({
        key: 'director',
        type: 'director',
        value: filters.director
      });
    }
    
    if (filters.tag) {
      activeFilters.push({
        key: 'tag',
        type: 'tag',
        value: filters.tag
      });
    }
    
    if (filters.top250) {
      activeFilters.push({
        key: 'top250',
        type: 'top250',
        value: translations.filters.top250
      });
    }

    if (activeFilters.length === 0) return null;

    return (
      <Box sx={{ mb: 1 }}>
        <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ gap: 1 }}>
          {activeFilters.map(({ key, type, value }) => (
            <Chip
              key={key}
              label={type === 'top250' ? value : `${translations.filters[type]}: ${value}`}
              onDelete={() => {
                if (type === 'year') {
                  handleFilterChange('yearStart', '');
                  handleFilterChange('yearEnd', '');
                } else if (type === 'area' || type === 'genre') {
                  setFilters(prev => ({
                    ...prev,
                    [type]: []
                  }));
                } else {
                  handleFilterChange(type, type === 'top250' ? false : '');
                }
              }}
              color="primary"
              variant="outlined"
              size="small"
              sx={{
                borderRadius: 1,
                '&:hover': {
                  bgcolor: alpha(theme.palette.primary.main, 0.1)
                }
              }}
            />
          ))}
          {activeFilters.length > 0 && (
            <Chip
              icon={<ClearAllIcon />}
              label={translations.filters.clearAll}
              onClick={handleClearFilters}
              color="primary"
              variant="outlined"
              size="small"
              sx={{
                borderRadius: 1,
                '&:hover': {
                  bgcolor: alpha(theme.palette.primary.main, 0.1)
                }
              }}
            />
          )}
        </Stack>
      </Box>
    );
  };

  const handleMasterCategoryChange = (masterId) => {
    if (masterId === 'all') {
      setSelectedMasterCategory(null);
      setSubcategories([]);
      setCurrentCategory(null);
      handleCategoryChange('all');
      scrollToTop(); // Add scroll to top
      return;
    }

    const masterCategory = categories.find(cat => String(cat.type_id) === masterId);
    if (masterCategory) {
      setSelectedMasterCategory(masterCategory);
      setCurrentCategory(masterCategory);
      const mainId = parseInt(masterId);
      const ranges = {
        1: { start: 7, end: 13 },
        2: { start: 14, end: 21 },
        3: { start: 22, end: 25 },
        4: { start: 26, end: 30 }
      };
      const range = ranges[mainId];
      
      const subs = allCategories.filter(cat => {
        const id = parseInt(cat.type_id);
        return range && id >= range.start && id <= range.end;
      });
      setSubcategories(subs);
      handleCategoryChange(masterId);
      scrollToTop(); // Add scroll to top
    }
  };

  // Update the getCategoryKey function to include animation subcategories
  const getCategoryKey = (chineseName) => {
    const categoryMap = {
      // Main categories
      '电影': 'movies',
      '连续剧': 'tvSeries',
      '综艺': 'variety',
      '动漫': 'animation',
      '短剧': 'shortSeries',
      '纪录片': 'documentary',
      // TV Series subcategories
      '国产剧': 'mainlandSeries',
      '香港剧': 'hongKongSeries',
      '韩国剧': 'koreanSeries',
      '欧美剧': 'westernSeries',
      '台湾剧': 'taiwanSeries',
      '日本剧': 'japaneseSeries',
      '海外剧': 'overseasSeries',
      '泰国剧': 'thaiSeries',
      // Animation subcategories
      '国产动漫': 'chineseAnimation',
      '日韩动漫': 'japaneseKoreanAnimation',
      '欧美动漫': 'westernAnimation',
      '港台动漫': 'hkTaiwanAnimation',
      '海外动漫': 'internationalAnimation'
    };
    return categoryMap[chineseName] || chineseName;
  };

  // Add effect to scroll to top when videos change
  useEffect(() => {
    if (!loading && videos.length > 0) {
      scrollToTop();
    }
  }, [videos, loading, scrollToTop]);

  const [randomLoading, setRandomLoading] = useState(false);

  const handleRandomVideo = useCallback(async () => {
    setRandomLoading(true);
    try {
      // Create a clean copy of filters with unique values
      const cleanFilters = {
        ...filters,
        area: filters.area?.length > 0 ? [...new Set(filters.area)] : undefined,
        genre: filters.genre?.length > 0 ? [...new Set(filters.genre)] : undefined,
        category: categoryId || 'all',
        limit: 100
      };
      
      const result = await getVideosByCategory(
        cleanFilters.category,
        1,
        100,
        cleanFilters.sort,
        cleanFilters
      );

      if (result && result.videos && result.videos.length > 0) {
        const randomIndex = Math.floor(Math.random() * result.videos.length);
        const randomVideo = result.videos[randomIndex];
        setRandomVideo(randomVideo);
        setRandomDialogOpen(true);
      }
    } catch (error) {
      console.error('Error fetching random video:', error);
    } finally {
      setRandomLoading(false);
    }
  }, [filters, categoryId]);

  const handleRandomDialogClose = () => {
    setRandomDialogOpen(false);
    setRandomVideo(null);
  };

  const handleWatchRandom = () => {
    if (randomVideo) {
      navigate(`/watch/${randomVideo.vod_id}`);
      handleRandomDialogClose();
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {selectedMasterCategory
            ? `${selectedMasterCategory.type_name}${currentCategory && currentCategory.type_id !== selectedMasterCategory.type_id ? ` - ${currentCategory.type_name}` : ''} - 247看`
            : translations.browse.metaTitle}
        </title>
        <meta name="description" content={
          selectedMasterCategory
            ? `247看${selectedMasterCategory.type_name}频道，${(() => {
                switch(selectedMasterCategory.type_id) {
                  case 1: // 电影
                    return `提供最新电影、热映电影在线观看，1080P蓝光高清电影，支持手机观看、极速播放，无广告，免费在线播放，同步更新热门影片，支持多线路切换。`;
                  case 2: // 连续剧
                    return `提供${currentCategory ? currentCategory.type_name + '、' : ''}国产剧、韩剧、美剧、日剧、港剧、台剧、泰剧在线观看，支持追剧、极速播放，1080P蓝光画质，无广告，免费在线更新，多线路播放。`;
                  case 3: // 综艺
                    return `提供最新综艺节目、热门综艺在线观看，支持手机观看、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，支持多线路切换。`;
                  case 4: // 动漫
                    return `提供${currentCategory ? currentCategory.type_name + '、' : ''}新番动漫、热门动漫在线观看，支持追番、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，多线路播放。`;
                  case 5: // 短剧
                    return `提供最新短剧、热门短剧在线观看，支持手机观看、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，支持多线路切换。`;
                  case 6: // 纪录片
                    return `提供高清纪录片在线观看，支持手机观看、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，支持多线路切换。`;
                  default:
                    return `提供在线观看，1080P蓝光高清，支持手机观看、极速播放，无广告，免费在线播放，同步更新，支持多线路切换。`;
                }
              })()}`
            : translations.browse.metaDescription
        } />
        <meta name="keywords" content={
          selectedMasterCategory
            ? `${(() => {
                const commonKeywords = '1080P高清,蓝光画质,无广告播放,免费观看,在线播放,直接观看,无需下载,手机观看,免费高清,同步更新,实时更新,在线串流,极速播放,多线路';
                switch(selectedMasterCategory.type_id) {
                  case 1: // 电影
                    return `在线电影,高清电影,${selectedMasterCategory.type_name},${currentCategory ? currentCategory.type_name + ',' : ''}最新电影,热映电影,在线影视,电影在线观看,${commonKeywords}`;
                  case 2: // 连续剧
                    return `连续剧在线,剧集,${selectedMasterCategory.type_name},${currentCategory ? currentCategory.type_name + ',' : ''}追剧,国产剧,韩剧在线,美剧,日剧,港剧,台剧,泰剧,海外剧,热播剧集,${commonKeywords}`;
                  case 3: // 综艺
                    return `综艺节目,${selectedMasterCategory.type_name},${currentCategory ? currentCategory.type_name + ',' : ''}在线综艺,最新综艺,热门综艺,综艺在线观看,${commonKeywords}`;
                  case 4: // 动漫
                    return `动漫视频,${selectedMasterCategory.type_name},${currentCategory ? currentCategory.type_name + ',' : ''}追番,新番动漫,热门动漫,动漫在线观看,${commonKeywords}`;
                  case 5: // 短剧
                    return `短剧,${selectedMasterCategory.type_name},${currentCategory ? currentCategory.type_name + ',' : ''}最新短剧,热门短剧,短剧在线观看,${commonKeywords}`;
                  case 6: // 纪录片
                    return `纪录片,${selectedMasterCategory.type_name},${currentCategory ? currentCategory.type_name + ',' : ''}高清纪录片,纪录片在线观看,${commonKeywords}`;
                  default:
                    return `在线影视,${selectedMasterCategory.type_name},${currentCategory ? currentCategory.type_name + ',' : ''}${commonKeywords}`;
                }
              })()}`
            : translations.browse.metaKeywords
        } />
        <meta property="og:title" content={
          selectedMasterCategory
            ? `${selectedMasterCategory.type_name}${currentCategory && currentCategory.type_id !== selectedMasterCategory.type_id ? ` - ${currentCategory.type_name}` : ''} - 247看`
            : translations.browse.metaTitle
        } />
        <meta property="og:description" content={
          selectedMasterCategory
            ? `247看${selectedMasterCategory.type_name}频道，${(() => {
                switch(selectedMasterCategory.type_id) {
                  case 1: // 电影
                    return `提供最新电影、热映电影在线观看，1080P蓝光高清电影，支持手机观看、极速播放，无广告，免费在线播放，同步更新热门影片，支持多线路切换。`;
                  case 2: // 连续剧
                    return `提供${currentCategory ? currentCategory.type_name + '、' : ''}国产剧、韩剧、美剧、日剧、港剧、台剧、泰剧在线观看，支持追剧、极速播放，1080P蓝光画质，无广告，免费在线更新，多线路播放。`;
                  case 3: // 综艺
                    return `提供最新综艺节目、热门综艺在线观看，支持手机观看、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，支持多线路切换。`;
                  case 4: // 动漫
                    return `提供${currentCategory ? currentCategory.type_name + '、' : ''}新番动漫、热门动漫在线观看，支持追番、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，多线路播放。`;
                  case 5: // 短剧
                    return `提供最新短剧、热门短剧在线观看，支持手机观看、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，支持多线路切换。`;
                  case 6: // 纪录片
                    return `提供高清纪录片在线观看，支持手机观看、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，支持多线路切换。`;
                  default:
                    return `提供在线观看，1080P蓝光高清，支持手机观看、极速播放，无广告，免费在线播放，同步更新，支持多线路切换。`;
                }
              })()}`
            : translations.browse.metaDescription
        } />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Layout>
        <Container maxWidth="xl" sx={{ 
          py: { xs: 2, md: 4 },
          px: { xs: 0, md: 3 }
        }}>
          {/* Show floating discover button only on mobile */}
          {isMobile && (
            <Box sx={{ 
              position: 'fixed', 
              top: 'auto',
              bottom: { xs: 80, md: 100 },
              left: { xs: 16, md: 24 },
              zIndex: 1200,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}>
              <Tooltip title={showDiscovery ? "返回浏览" : "发现新内容"} placement="right">
                <Button
                  variant={showDiscovery ? "contained" : "outlined"}
                  color="primary"
                  onClick={() => setShowDiscovery(!showDiscovery)}
                  startIcon={!isMobile && <ExploreIcon />}
                  sx={{
                    borderRadius: 8,
                    px: { xs: 2, md: 2.5 },
                    py: { xs: 1.5, md: 1.5 },
                    boxShadow: showDiscovery ? 8 : 4,
                    bgcolor: showDiscovery ? 'primary.main' : 'background.paper',
                    border: !showDiscovery ? '2px solid' : 'none',
                    borderColor: 'primary.main',
                    animation: !showDiscovery ? 'pulse 2s infinite, bounce 1s infinite' : 'none',
                    '&:hover': {
                      boxShadow: 6,
                      transform: 'translateY(-2px)',
                      bgcolor: showDiscovery ? 'primary.dark' : 'background.paper'
                    },
                    transition: 'all 0.2s ease-in-out',
                    '@keyframes pulse': {
                      '0%': {
                        boxShadow: '0 0 0 0 rgba(25, 118, 210, 0.4)'
                      },
                      '70%': {
                        boxShadow: '0 0 0 10px rgba(25, 118, 210, 0)'
                      },
                      '100%': {
                        boxShadow: '0 0 0 0 rgba(25, 118, 210, 0)'
                      }
                    },
                    '@keyframes bounce': {
                      '0%, 100%': {
                        transform: 'translateY(0)'
                      },
                      '50%': {
                        transform: 'translateY(-5px)'
                      }
                    }
                  }}
                >
                  {!isMobile && (showDiscovery ? "返回浏览" : "发现新内容 ✨")}
                  {isMobile && (
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 0.5 
                    }}>
                      <ExploreIcon />
                      {!showDiscovery && "✨"}
                    </Box>
                  )}
                </Button>
              </Tooltip>
            </Box>
          )}

          {/* Master Categories Sidebar */}
          <Grid container spacing={isMobile ? 1 : 3}>
            {/* Remove the first desktop discover button */}
            {!showDiscovery && (
              <>
                {/* Mobile Category Navigation */}
                {isMobile && !showDiscovery && (
                  <>
                    {/* Main Categories - Mobile */}
                    <Box 
                      sx={{ 
                        width: '100%', 
                        bgcolor: 'background.paper',
                        position: 'sticky',
                        top: 56,
                        zIndex: 1100,
                        borderBottom: 1,
                        borderColor: 'divider',
                        px: 0,
                        // Add frosted glass effect
                        backdropFilter: 'blur(8px)',
                        backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.95),
                        // Add subtle shadow
                        boxShadow: (theme) => theme.palette.mode === 'light' 
                          ? '0 1px 3px rgba(0,0,0,0.12)'
                          : 'none'
                      }}
                    >
                      <Tabs
                        value={selectedMasterCategory ? String(selectedMasterCategory.type_id) : 'all'}
                        onChange={(_, newValue) => handleMasterCategoryChange(newValue)}
                        variant="scrollable"
                        scrollButtons={false}
                        TabIndicatorProps={{
                          sx: {
                            height: 2,
                            borderRadius: '2px 2px 0 0'
                          }
                        }}
                        sx={{
                          minHeight: 48,
                          '& .MuiTab-root': {
                            minHeight: 48,
                            py: 1.5,
                            px: 2.5,
                            minWidth: 'auto',
                            fontSize: '0.875rem',
                            fontWeight: 500,
                            textTransform: 'none',
                            letterSpacing: 0,
                            color: 'text.secondary',
                            '&.Mui-selected': {
                              color: 'primary.main',
                              fontWeight: 600
                            }
                          },
                          // Add custom scrollbar styling
                          '& .MuiTabs-scroller': {
                            '&::-webkit-scrollbar': {
                              display: 'none'
                            },
                            scrollbarWidth: 'none'
                          }
                        }}
                      >
                        <Tab 
                          label="全部" 
                          value="all"
                        />
                        {categories.map((category) => (
                          <Tab
                            key={category.type_id}
                            label={category.type_name}
                            value={String(category.type_id)}
                          />
                        ))}
                      </Tabs>
                    </Box>

                    {/* Subcategories - Mobile */}
                    {selectedMasterCategory && subcategories.length > 0 && (
                      <Box 
                        sx={{ 
                          width: '100%',
                          position: 'sticky',
                          top: 100,
                          zIndex: 1090,
                          mb: 1,
                          px: 0,
                          borderBottom: 1,
                          borderColor: 'divider',
                          // Add frosted glass effect
                          backdropFilter: 'blur(8px)',
                          backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.95),
                          // Add subtle shadow
                          boxShadow: (theme) => theme.palette.mode === 'light' 
                            ? '0 1px 2px rgba(0,0,0,0.08)'
                            : 'none'
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            py: 1.5,
                            px: 2,
                            overflowX: 'auto',
                            flexWrap: 'nowrap',
                            '&::-webkit-scrollbar': { display: 'none' },
                            scrollbarWidth: 'none',
                            // Add gap between chips
                            '& > *': {
                              mr: 1
                            }
                          }}
                        >
                          <Chip
                            label="全部"
                            onClick={() => handleCategoryChange(String(selectedMasterCategory.type_id))}
                            color={categoryId === String(selectedMasterCategory.type_id) ? "primary" : "default"}
                            variant={categoryId === String(selectedMasterCategory.type_id) ? "filled" : "outlined"}
                            size="small"
                            sx={{
                              height: 32,
                              borderRadius: 2,
                              fontSize: '0.875rem',
                              fontWeight: 500,
                              '&.MuiChip-filled': {
                                bgcolor: 'primary.main',
                                color: 'primary.contrastText',
                                '&:hover': {
                                  bgcolor: 'primary.dark'
                                }
                              },
                              '&.MuiChip-outlined': {
                                borderColor: 'divider',
                                color: 'text.secondary',
                                '&:hover': {
                                  bgcolor: 'action.hover'
                                }
                              }
                            }}
                          />
                          {subcategories.map((subCat) => (
                            <Chip
                              key={subCat.type_id}
                              label={subCat.type_name}
                              onClick={() => handleCategoryChange(String(subCat.type_id))}
                              color={categoryId === String(subCat.type_id) ? "primary" : "default"}
                              variant={categoryId === String(subCat.type_id) ? "filled" : "outlined"}
                              size="small"
                              sx={{
                                height: 32,
                                borderRadius: 2,
                                fontSize: '0.875rem',
                                fontWeight: 500,
                                '&.MuiChip-filled': {
                                  bgcolor: 'primary.main',
                                  color: 'primary.contrastText',
                                  '&:hover': {
                                    bgcolor: 'primary.dark'
                                  }
                                },
                                '&.MuiChip-outlined': {
                                  borderColor: 'divider',
                                  color: 'text.secondary',
                                  '&:hover': {
                                    bgcolor: 'action.hover'
                                  }
                                }
                              }}
                            />
                          ))}
                        </Stack>
                      </Box>
                    )}
                  </>
                )}

                {/* Desktop Category Navigation */}
                {!isMobile && !showDiscovery && (
                  <Grid item xs={12} md={2}>
                    <Paper 
                      elevation={0} 
                      sx={{ 
                        bgcolor: 'background.paper', 
                        position: 'sticky',
                        top: 80,
                        borderRadius: 2,
                        overflow: 'hidden',
                      }}
                    >
                      {/* Main Categories - Desktop */}
                      <List sx={{ p: 0 }}>
                        <ListItemButton
                          selected={!selectedMasterCategory}
                          onClick={() => handleMasterCategoryChange('all')}
                          sx={{ 
                            py: 1.5,
                            borderLeft: 3,
                            borderColor: !selectedMasterCategory ? 'primary.main' : 'transparent'
                          }}
                        >
                          <ListItemText 
                            primary="全部" 
                            primaryTypographyProps={{
                              fontSize: '0.95rem',
                              fontWeight: !selectedMasterCategory ? 600 : 400
                            }}
                          />
                        </ListItemButton>
                        
                        {categories.map((category) => (
                          <React.Fragment key={category.type_id}>
                            <ListItemButton
                              selected={selectedMasterCategory?.type_id === category.type_id}
                              onClick={() => handleMasterCategoryChange(String(category.type_id))}
                              sx={{ 
                                py: 1.5,
                                borderLeft: 3,
                                borderColor: selectedMasterCategory?.type_id === category.type_id ? 'primary.main' : 'transparent'
                              }}
                            >
                              <ListItemText 
                                primary={category.type_name}
                                primaryTypographyProps={{
                                  fontSize: '0.95rem',
                                  fontWeight: selectedMasterCategory?.type_id === category.type_id ? 600 : 400
                                }}
                              />
                              {subcategories.length > 0 && selectedMasterCategory?.type_id === category.type_id && (
                                <KeyboardArrowRightIcon 
                                  sx={{ 
                                    transform: 'rotate(-90deg)', 
                                    color: 'primary.main',
                                    transition: 'transform 0.2s'
                                  }} 
                                />
                              )}
                            </ListItemButton>

                            {/* Subcategories Collapse */}
                            {selectedMasterCategory?.type_id === category.type_id && subcategories.length > 0 && (
                              <Box sx={{ bgcolor: 'action.hover', py: 1 }}>
                                <List sx={{ p: 0 }}>
                                  <ListItemButton
                                    selected={categoryId === String(selectedMasterCategory.type_id)}
                                    onClick={() => handleCategoryChange(String(selectedMasterCategory.type_id))}
                                    sx={{ 
                                      py: 1,
                                      pl: 4,
                                      minHeight: 40
                                    }}
                                  >
                                    <ListItemText 
                                      primary="全部"
                                      primaryTypographyProps={{
                                        fontSize: '0.9rem',
                                        color: 'text.secondary'
                                      }}
                                    />
                                  </ListItemButton>
                                  {subcategories.map((subCat) => (
                                    <ListItemButton
                                      key={subCat.type_id}
                                      selected={categoryId === String(subCat.type_id)}
                                      onClick={() => handleCategoryChange(String(subCat.type_id))}
                                      sx={{ 
                                        py: 1,
                                        pl: 4,
                                        minHeight: 40
                                      }}
                                    >
                                      <ListItemText 
                                        primary={subCat.type_name}
                                        primaryTypographyProps={{
                                          fontSize: '0.9rem',
                                          color: 'text.secondary'
                                        }}
                                      />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Box>
                            )}
                          </React.Fragment>
                        ))}
                      </List>
                    </Paper>
                  </Grid>
                )}
              </>
            )}

            {/* Main Content Area */}
            <Grid item xs={12} md={showDiscovery ? 12 : 10}>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                minHeight: '100vh',
                position: 'relative',
                pt: { 
                  xs: selectedMasterCategory ? 
                      (subcategories.length > 0 ? 3 : 2) : 
                      1,
                  md: !isMobile ? 0 : 5
                },
                mt: { 
                  xs: !selectedMasterCategory || (selectedMasterCategory && subcategories.length === 0) ? 2 : 0,
                  md: 0
                }
              }}>
                {/* Quick Filter Tabs with integrated buttons */}
                {(!showDiscovery || !isMobile) && (
                  <Paper 
                    elevation={0} 
                    sx={{ 
                      mb: 1,
                      mt: { 
                        xs: selectedMasterCategory ? 
                            (subcategories.length > 0 ? 1 : 1) : 2, 
                        md: 6
                      },
                      bgcolor: 'background.paper',
                      borderRadius: 2,
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      position: 'relative',
                      zIndex: 1
                    }}
                  >
                    {!showDiscovery && (
                      <Tabs 
                        value={filters.top250 ? 'top250' : (
                          filters.sort === '-vod_douban_score' ? 'highscore' : 
                          filters.sort === '-vod_time' ? 'latest' : 'trending'
                        )}
                        onChange={(_, newValue) => {
                          if (newValue === 'top250') {
                            setFilters(prev => ({
                              ...prev,
                              top250: true,
                              sort: 'vod_top250_rank'
                            }));
                          } else if (newValue === 'trending') {
                            setFilters(prev => ({
                              ...prev,
                              top250: false,
                              sort: '-vod_level'
                            }));
                          } else if (newValue === 'latest') {
                            setFilters(prev => ({
                              ...prev,
                              top250: false,
                              sort: '-vod_time'
                            }));
                          } else if (newValue === 'highscore') {
                            setFilters(prev => ({
                              ...prev,
                              top250: false,
                              sort: '-vod_douban_score'
                            }));
                          }
                          setPage(1);
                          setVideoCache({});
                        }}
                        variant="scrollable"
                        scrollButtons={isMobile ? "auto" : false}
                        sx={{ 
                          px: { xs: 1, md: 2 },
                          flex: 1,
                          '& .MuiTab-root': {
                            minHeight: { xs: 48, md: 56 },
                            fontSize: { xs: '0.875rem', md: '1rem' },
                            px: { xs: 1, md: 2 }
                          }
                        }}
                      >
                        <Tab 
                          label={t('filters', 'trending')} 
                          value="trending"
                          icon={<TrendingUpIcon />}
                          iconPosition="start"
                        />
                        <Tab 
                          label={t('browse', 'latest')} 
                          value="latest"
                          icon={<UpdateIcon />}
                          iconPosition="start"
                        />
                        <Tab 
                          label="高分" 
                          value="highscore"
                          icon={<StarIcon />}
                          iconPosition="start"
                        />
                        {/* Only show Top 250 tab for movies (type_id: 1) */}
                        {(!selectedMasterCategory || selectedMasterCategory?.type_id === 1) && (
                          <Tab 
                            label={t('filters', 'top250')} 
                            value="top250"
                            icon={<StarIcon />}
                            iconPosition="start"
                          />
                        )}
                      </Tabs>
                    )}
                    
                    {/* Integrated buttons */}
                    <Stack direction="row" spacing={1} sx={{ px: 2, py: 1 }}>
                      {!isMobile && (
                        <Tooltip title={showDiscovery ? "返回浏览" : "发现新内容"}>
                          <Button
                            variant={showDiscovery ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => setShowDiscovery(!showDiscovery)}
                            startIcon={<ExploreIcon />}
                            sx={{
                              height: 48,
                              whiteSpace: 'nowrap',
                              animation: !showDiscovery ? 'pulse 2s infinite' : 'none',
                              '@keyframes pulse': {
                                '0%': {
                                  boxShadow: '0 0 0 0 rgba(25, 118, 210, 0.4)'
                                },
                                '70%': {
                                  boxShadow: '0 0 0 10px rgba(25, 118, 210, 0)'
                                },
                                '100%': {
                                  boxShadow: '0 0 0 0 rgba(25, 118, 210, 0)'
                                }
                              }
                            }}
                          >
                            {showDiscovery ? "返回浏览" : "发现新内容"}
                          </Button>
                        </Tooltip>
                      )}
                      {!showDiscovery && (
                        <Tooltip title="随机选择一部影片">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleRandomVideo}
                            disabled={randomLoading}
                            startIcon={
                              randomLoading ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                !isMobile && <ShuffleIcon />
                              )
                            }
                            sx={{
                              minWidth: isMobile ? 40 : 'auto',
                              height: { xs: 40, md: 48 },
                              whiteSpace: 'nowrap',
                              animation: randomLoading ? 'none' : 'pulse 2s infinite',
                              '@keyframes pulse': {
                                '0%': {
                                  boxShadow: '0 0 0 0 rgba(25, 118, 210, 0.4)'
                                },
                                '70%': {
                                  boxShadow: '0 0 0 10px rgba(25, 118, 210, 0)'
                                },
                                '100%': {
                                  boxShadow: '0 0 0 0 rgba(25, 118, 210, 0)'
                                }
                              }
                            }}
                          >
                            {!isMobile && '随机播放'}
                            {isMobile && <ShuffleIcon />}
                          </Button>
                        </Tooltip>
                      )}
                    </Stack>
                  </Paper>
                )}

                {/* Content Discovery Section */}
                {showDiscovery ? (
                  <Box sx={{ mb: 4 }}>
                    <Paper elevation={0} sx={{ p: 3, bgcolor: 'background.paper', mt: 1 }}>
                      <Typography variant="h5" gutterBottom sx={{ mb: 4, mt: 2 }}>
                        {t('discovery', 'title') || '发现新内容'}
                      </Typography>
                      <ContentDiscovery 
                        onMovieSelect={(movie) => {
                          navigate(`/search?q=${encodeURIComponent(movie.title)}`);
                          setShowDiscovery(false);
                        }}
                      />
                    </Paper>
                  </Box>
                ) : (
                  <>
                    {/* Top bar with filters */}
                    <Paper elevation={0} sx={{ p: 2, mb: 1, bgcolor: 'background.paper', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Button
                          startIcon={<TuneIcon />}
                          onClick={toggleFilterDrawer}
                          variant={isFilterDrawerOpen ? "contained" : "outlined"}
                          color="primary"
                        >
                          {t('filters', 'title')}
                          {activeFiltersCount > 0 && (
                            <Chip 
                              label={activeFiltersCount} 
                              size="small" 
                              color="primary"
                              sx={{ height: 20, fontSize: '0.75rem' }}
                            />
                          )}
                        </Button>
                        <ToggleButtonGroup
                          value={viewMode}
                          exclusive
                          onChange={(_, newMode) => newMode && setViewMode(newMode)}
                          size="small"
                        >
                          <ToggleButton value="grid" aria-label={t('filters', 'gridView')}>
                            <ViewModuleIcon />
                          </ToggleButton>
                          <ToggleButton value="list" aria-label={t('filters', 'listView')}>
                            <ViewListIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Stack>
                      
                      <Typography color="text.secondary" variant="body2">
                        {videos.length > 0 
                          ? t('filters', 'resultsCount').replace('${count}', videos.length)
                          : t('browse', 'noContent')}
                      </Typography>
                    </Paper>

                    {/* Active filters */}
                    {renderActiveFilters() && (
                      <Box sx={{ mb: 1 }}>
                        {renderActiveFilters()}
                      </Box>
                    )}

                    {/* Content */}
                    {loading ? (
                      <LoadingSkeleton viewMode={viewMode} isMobile={isMobile} />
                    ) : error ? (
                      <Alert severity="error" sx={{ mb: 1 }}>
                        {error}
                      </Alert>
                    ) : videos.length === 0 ? (
                      <Box sx={{ 
                        py: 2,
                        textAlign: 'center', 
                        color: 'text.secondary' 
                      }}>
                        <Typography variant="h6" gutterBottom>
                          {t('browse', 'noVideosFound')}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 3 }}>
                          {t('browse', 'tryDifferentFilters')}
                        </Typography>
                        {(activeFiltersCount > 0 || filters.top250 || filters.trending) && (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClearFilters}
                            startIcon={<ClearAllIcon />}
                          >
                            {t('filters', 'clearAllFilters')}
                          </Button>
                        )}
                      </Box>
                    ) : (
                      <>
                        <Grid container spacing={isMobile ? 1 : 2}>
                          {videos.map((video) => (
                            <Grid 
                              item 
                              xs={viewMode === 'list' ? 12 : 6} 
                              sm={viewMode === 'list' ? 12 : 6} 
                              md={viewMode === 'list' ? 12 : 4} 
                              lg={viewMode === 'list' ? 12 : 2.4} 
                              key={video.vod_id}
                            >
                              {viewMode === 'list' ? (
                                <Paper 
                                  elevation={1} 
                                  sx={{ 
                                    p: { xs: 1.5, md: 2 },
                                    '&:hover': {
                                      boxShadow: (theme) => theme.shadows[4],
                                      transform: 'translateY(-2px)',
                                    },
                                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                                  }}
                                >
                                  <MovieDetail 
                                    movie={video} 
                                    isListView={true} 
                                    onPlay={() => navigate(`/watch/${video.vod_id}`)}
                                    isMobile={isMobile}
                                  />
                                </Paper>
                              ) : (
                                <Box
                                  sx={{
                                    height: '100%',
                                    '&:hover': {
                                      transform: isMobile ? 'none' : 'translateY(-4px)',
                                      '& .MuiPaper-root': {
                                        boxShadow: (theme) => theme.shadows[isMobile ? 2 : 8],
                                      }
                                    },
                                    transition: 'transform 0.2s ease-in-out',
                                  }}
                                >
                                  <ContentCard 
                                    content={video} 
                                    isMobile={isMobile}
                                  />
                                </Box>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                        
                        {/* Pagination */}
                        {totalPages > 1 && (
                          <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'center',
                            mt: 1.5,
                            mb: { xs: 2, md: 1.5 },
                            '& .MuiPagination-ul': {
                              flexWrap: 'nowrap',
                            }
                          }}>
                            <Pagination
                              count={totalPages}
                              page={page}
                              onChange={handlePageChange}
                              color="primary"
                              size={isMobile ? "small" : "medium"}
                              showFirstButton
                              showLastButton
                              siblingCount={isMobile ? 0 : 1}
                              boundaryCount={isMobile ? 1 : 2}
                            />
                          </Box>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>

          {/* Filter drawer */}
          <Drawer
            anchor={isMobile ? 'bottom' : 'right'}
            open={isFilterDrawerOpen}
            onClose={toggleFilterDrawer}
            PaperProps={{
              sx: {
                width: isMobile ? '100%' : 350,
                height: isMobile ? '90vh' : '100%',
                p: { xs: 2, md: 2 },
                borderTopLeftRadius: isMobile ? 16 : 0,
                borderTopRightRadius: isMobile ? 16 : 0,
                overflowY: 'auto',
                bgcolor: 'background.paper',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: (theme) => theme.palette.divider,
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: (theme) => theme.palette.action.hover,
                },
              }
            }}
          >
            <Box sx={{ 
              position: 'sticky', 
              top: 0, 
              zIndex: 1,
              pb: 2 
            }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 2 
              }}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <FilterListIcon />
                  {t('filters', 'title')}
                  {activeFiltersCount > 0 && (
                    <Chip 
                      label={activeFiltersCount} 
                      size="small" 
                      color="primary"
                      sx={{ height: 20, fontSize: '0.75rem' }}
                    />
                  )}
                </Typography>
                <IconButton onClick={toggleFilterDrawer} size="small">
                  <CloseIcon />
                </IconButton>
              </Box>
              <Divider />
            </Box>
            {renderFilters()}
          </Drawer>
        </Container>
      </Layout>
      <RandomOverlay
        open={randomDialogOpen}
        onClose={handleRandomDialogClose}
        currentVideo={randomVideo}
        onTryAnother={handleRandomVideo}
        onWatch={handleWatchRandom}
        loading={randomLoading}
        currentCategory={currentCategory || selectedMasterCategory}
        activeFilters={filters}
        isMobile={isMobile}
      />
    </>
  );
};

export default Browse; 