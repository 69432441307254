import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Stack,
  Typography,
  Card,
  IconButton,
  TextField,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { getComments, addComment, updateCommentVote } from '../../services/api';
import { useLanguage } from '../../contexts/LanguageContext';
import { generateAnonymousName } from '../../utils/nameGenerator';
import Cookies from 'js-cookie';
import { useAuth } from '../../contexts/AuthContext';

// Maximum nesting level for comments
const MAX_NESTING_LEVEL = 3;

// Helper function to manage vote cookies
const manageVoteCookie = (commentId, voteType) => {
  const cookieName = `comment_vote_${commentId}`;
  const existingVote = Cookies.get(cookieName);
  
  if (existingVote) {
    return true; // Already voted on this comment
  }
  
  // Set the cookie with 30 days expiry
  Cookies.set(cookieName, voteType, { 
    expires: 30,
    path: '/',
    sameSite: 'Lax'
  });
  return false;
};

const Comment = ({ comment, level = 0, onReply, anonymousName, isLocalhost, loading, videoId, onCommentUpdate }) => {
  const { t } = useLanguage();
  const { user } = useAuth();
  const [replyContent, setReplyContent] = useState('');
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [codeId, setCodeId] = useState(null);
  const [captchaSvg, setCaptchaSvg] = useState('');
  const [verificationError, setVerificationError] = useState(null);

  // Reset verification state when showing/hiding form
  useEffect(() => {
    if (!showReplyForm) {
      setVerificationCode('');
      setVerificationError(null);
    } else {
      fetchVerificationCode();
    }
  }, [showReplyForm]);

  const fetchVerificationCode = async () => {
    try {
      const response = await fetch('/api/verification-code');
      const data = await response.json();
      if (data.status === 'success') {
        setCodeId(data.data.codeId);
        setCaptchaSvg(data.data.svgData);
      }
    } catch (error) {
      console.error('Failed to fetch verification code:', error);
      setVerificationError(t('common', 'verificationCodeError'));
    }
  };

  const handleReplySubmit = async (e) => {
    e.preventDefault();
    if (!replyContent.trim()) return;

    try {
      if (!verificationCode) {
        setVerificationError(t('common', 'invalidVerificationCode'));
        return;
      }

      await onReply(comment.comment_id, replyContent, codeId, verificationCode);
      setReplyContent('');
      setShowReplyForm(false);
      setVerificationCode('');
      fetchVerificationCode();
    } catch (error) {
      console.error('Failed to submit reply:', error);
      setVerificationError(error.message);
    }
  };

  const handleVote = async (voteType) => {
    try {
      // Check if already voted
      if (manageVoteCookie(comment.comment_id, voteType)) {
        return; // Already voted on this comment
      }
      
      await updateCommentVote(comment.comment_id, voteType);
      onCommentUpdate();
    } catch (error) {
      console.error('Failed to update vote:', error);
    }
  };

  return (
    <Box sx={{ ml: level * 4, mb: 2 }}>
      <Card 
        sx={{ 
          p: 2,
          mb: 2,
          '&:hover': {
            bgcolor: (theme) => theme.palette.action.hover
          }
        }}
      >
        <Stack spacing={1}>
          <Stack 
            direction="row" 
            spacing={2} 
            alignItems="center" 
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="subtitle2" sx={{ color: 'primary.main', fontWeight: 500 }}>
                {comment.comment_name || t('watch', 'anonymous')}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {new Date(comment.comment_time * 1000).toLocaleString()}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              {level < MAX_NESTING_LEVEL && (
                <IconButton 
                  size="small" 
                  onClick={() => setShowReplyForm(!showReplyForm)}
                  sx={{ color: 'text.secondary' }}
                >
                  <ReplyIcon fontSize="small" />
                </IconButton>
              )}
              <IconButton 
                size="small"
                onClick={() => handleVote('up')}
                sx={{ color: 'success.main' }}
              >
                <ThumbUpIcon fontSize="small" />
                <Typography variant="caption" sx={{ ml: 0.5 }}>
                  {comment.comment_up || 0}
                </Typography>
              </IconButton>
              <IconButton 
                size="small"
                onClick={() => handleVote('down')}
                sx={{ color: 'error.main' }}
              >
                <ThumbDownIcon fontSize="small" />
                <Typography variant="caption" sx={{ ml: 0.5 }}>
                  {comment.comment_down || 0}
                </Typography>
              </IconButton>
            </Stack>
          </Stack>
          
          <Typography variant="body2">
            {comment.comment_content}
          </Typography>

          {showReplyForm && (
            <Box component="form" onSubmit={handleReplySubmit} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                multiline
                rows={2}
                value={replyContent}
                onChange={(e) => setReplyContent(e.target.value)}
                placeholder={t('watch', 'replyPlaceholder')}
                size="small"
                sx={{ mb: 1 }}
              />
              <Box sx={{ mb: 1 }}>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                  mb: 1 
                }}>
                  <div 
                    dangerouslySetInnerHTML={{ __html: captchaSvg }}
                    onClick={fetchVerificationCode}
                    style={{ cursor: 'pointer' }}
                    title={t('watch', 'clickToRefresh')}
                  />
                </Box>
                <TextField
                  fullWidth
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder={t('watch', 'enterVerificationCode')}
                  size="small"
                  error={!!verificationError}
                  helperText={verificationError}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                size="small"
                disabled={!replyContent.trim() || !verificationCode || loading}
              >
                {loading ? t('watch', 'submitting') : t('watch', 'reply')}
              </Button>
              <Button
                size="small"
                onClick={() => setShowReplyForm(false)}
                sx={{ ml: 1 }}
              >
                {t('watch', 'cancel')}
              </Button>
            </Box>
          )}
        </Stack>
      </Card>

      {comment.replies?.map((reply) => (
        <Comment
          key={reply.comment_id}
          comment={reply}
          level={level + 1}
          onReply={onReply}
          anonymousName={anonymousName}
          isLocalhost={isLocalhost}
          loading={loading}
          videoId={videoId}
          onCommentUpdate={onCommentUpdate}
        />
      ))}
    </Box>
  );
};

// Add this function before the Comments component
const buildCommentTree = (comments, sortType = 'time') => {
  const commentMap = new Map();
  const rootComments = [];

  // First pass: Create a map of all comments and initialize replies array
  comments.forEach(comment => {
    commentMap.set(comment.comment_id, {
      ...comment,
      replies: []
    });
  });

  // Second pass: Build the tree structure
  comments.forEach(comment => {
    const commentWithReplies = commentMap.get(comment.comment_id);
    if (comment.comment_pid && comment.comment_pid !== 0 && commentMap.has(comment.comment_pid)) {
      // This is a reply, add it to parent's replies
      const parentComment = commentMap.get(comment.comment_pid);
      parentComment.replies.push(commentWithReplies);
    } else {
      // This is a root comment (comment_pid is 0 or null)
      rootComments.push(commentWithReplies);
    }
  });

  // Sort functions
  const getSortFunction = (sortType) => {
    switch (sortType) {
      case 'likes':
        // Sort by likes (up votes - down votes), then by time if likes are equal
        return (a, b) => {
          const aScore = (a.comment_up || 0) - (a.comment_down || 0);
          const bScore = (b.comment_up || 0) - (b.comment_down || 0);
          if (bScore !== aScore) {
            return bScore - aScore;
          }
          return b.comment_time - a.comment_time;
        };
      case 'time':
      default:
        // Sort by time descending (newest first)
        return (a, b) => b.comment_time - a.comment_time;
    }
  };

  // Sort comments recursively using the selected sort function
  const sortCommentsRecursive = (comments, sortFn) => {
    comments.sort(sortFn);
    comments.forEach(comment => {
      if (comment.replies.length > 0) {
        // Always sort replies by time to maintain conversation flow
        sortCommentsRecursive(comment.replies, getSortFunction('time'));
      }
    });
    return comments;
  };

  return sortCommentsRecursive(rootComments, getSortFunction(sortType));
};

const Comments = ({ videoId }) => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalComments, setTotalComments] = useState(0);
  const [sortBy, setSortBy] = useState('time');
  const [commentContent, setCommentContent] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [codeId, setCodeId] = useState(null);
  const [captchaSvg, setCaptchaSvg] = useState('');
  const [verificationError, setVerificationError] = useState(null);
  const [error, setError] = useState(null);
  const [anonymousName, setAnonymousName] = useState(generateAnonymousName());
  const { t } = useLanguage();
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLocalhost = window.location.hostname === 'localhost';

  const fetchComments = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getComments(videoId, page, 10, sortBy);
      // Transform flat comments into hierarchical structure with sort type
      const commentTree = buildCommentTree(data.comments, sortBy);
      setComments(commentTree);
      setTotalPages(Math.ceil(data.total / 10));
      setTotalComments(data.total);
    } catch (error) {
      console.error('Failed to fetch comments:', error);
    } finally {
      setLoading(false);
    }
  }, [videoId, page, sortBy]);

  useEffect(() => {
    fetchComments();
  }, [videoId, page, sortBy]);

  useEffect(() => {
    if (!user) {
      let name = Cookies.get('anonymousName');
      if (!name) {
        name = generateAnonymousName();
        Cookies.set('anonymousName', name, { expires: 30, path: '/', sameSite: 'Lax' });
      }
      setAnonymousName(name);
    }
  }, [user]);

  const fetchVerificationCode = useCallback(async () => {
    try {
      const response = await fetch('/api/verification-code');
      const data = await response.json();
      if (data.status === 'success') {
        setCodeId(data.data.codeId);
        setCaptchaSvg(data.data.svgData);
      }
    } catch (error) {
      console.error('Failed to fetch verification code:', error);
      setError(t('common', 'verificationCodeError'));
    }
  }, [t]);

  useEffect(() => {
    // Always fetch verification code
    fetchVerificationCode();
  }, [fetchVerificationCode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!commentContent.trim()) return;

    try {
      setLoading(true);
      setError(null);

      if (!verificationCode) {
        setError(t('common', 'invalidVerificationCode'));
        return;
      }

      const response = await fetch(`/api/videos/${videoId}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          content: commentContent,
          codeId,
          verificationCode,
          anonymousName: user ? user.username : anonymousName,
          user_id: user ? user.user_id : null
        })
      });

      const data = await response.json();
      if (data.status === 'error') throw new Error(data.message);

      setCommentContent('');
      setVerificationCode('');
      fetchVerificationCode();
      // Refresh comments
      fetchComments();
    } catch (error) {
      console.error('Failed to add comment:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleReply = useCallback(async (parentId, content, codeId, verificationCode) => {
    try {
      setLoading(true);
      const response = await fetch(`/api/videos/${videoId}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          content,
          comment_pid: parentId,
          codeId,
          verificationCode,
          anonymousName: user ? user.username : anonymousName,
          user_id: user ? user.user_id : null
        })
      });

      const data = await response.json();
      if (data.status === 'error') throw new Error(data.message);

      // Refresh comments
      fetchComments();
    } catch (error) {
      console.error('Failed to add reply:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [videoId, user, anonymousName, fetchComments]);

  const handlePageChange = (event, value) => {
    setPage(value);
    // Scroll to comments section
    const commentsSection = document.getElementById('comments-section');
    if (commentsSection) {
      commentsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
    setPage(1); // Reset to first page when sorting changes
  };

  return (
    <Box sx={{ mt: 4 }} id="comments-section">
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h6">
          {t('watch', 'comments')} ({totalComments})
        </Typography>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>{t('watch', 'sortBy')}</InputLabel>
          <Select
            value={sortBy}
            label={t('watch', 'sortBy')}
            onChange={handleSortChange}
          >
            <MenuItem value="time">{t('watch', 'newest')}</MenuItem>
            <MenuItem value="likes">{t('watch', 'mostLiked')}</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Box component="form" onSubmit={handleSubmit} sx={{ mb: 3 }}>
        <TextField
          fullWidth
          multiline
          rows={3}
          value={commentContent}
          onChange={(e) => setCommentContent(e.target.value)}
          placeholder={t('watch', 'writeComment')}
          error={!!verificationError}
          helperText={verificationError}
          sx={{ mb: 2 }}
        />
        <Box sx={{ mb: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
            mb: 1 
          }}>
            <div 
              dangerouslySetInnerHTML={{ __html: captchaSvg }}
              onClick={fetchVerificationCode}
              style={{ cursor: 'pointer' }}
              title={t('watch', 'clickToRefresh')}
            />
          </Box>
          <TextField
            fullWidth
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder={t('watch', 'enterVerificationCode')}
            size="small"
          />
        </Box>
        <Button
          type="submit"
          variant="contained"
          disabled={!commentContent.trim() || !verificationCode || loading}
        >
          {loading ? t('watch', 'submitting') : t('watch', 'postComment')}
        </Button>
      </Box>

      {comments.length === 0 ? (
        <Box sx={{ textAlign: 'center', py: 4, color: 'text.secondary' }}>
          <Typography variant="body1">
            {t('watch', 'noComments')}
          </Typography>
        </Box>
      ) : (
        <>
          <Stack spacing={2}>
            {comments.map((comment) => (
              <Comment
                key={comment.comment_id}
                comment={comment}
                anonymousName={anonymousName}
                isLocalhost={isLocalhost}
                loading={loading}
                videoId={videoId}
                onCommentUpdate={fetchComments}
                onReply={handleReply}
              />
            ))}
          </Stack>

          {totalPages > 1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 2 }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                disabled={loading}
                siblingCount={1}
                size={isMobile ? "small" : "medium"}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Comments; 