import React, { useRef } from 'react';
import { Box, useTheme, useScrollTrigger, Fade, Backdrop, CircularProgress, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import AnnouncementOverlay from './AnnouncementOverlay';

const Layout = ({ children }) => {
  const location = useLocation();
  const theme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const announcementRef = useRef();
  
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [location.pathname]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const isWatchPage = location.pathname.startsWith('/watch');

  return (
    <Box 
      sx={{ 
        minHeight: '100vh',
        bgcolor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        transition: theme.transitions.create('background-color', {
          duration: theme.transitions.duration.standard,
        }),
      }}
    >
      <Navbar scrolled={trigger} announcementRef={announcementRef} />
      
      <Backdrop
        sx={{ 
          color: 'primary.main',
          zIndex: theme.zIndex.drawer + 1,
          backdropFilter: 'blur(4px)',
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Fade in={!loading} timeout={500}>
        <Box 
          component="main" 
          sx={{ 
            flex: 1,
            position: 'relative',
            width: '100%',
            maxWidth: theme.breakpoints.values.xl,
            mx: 'auto',
            ...(isWatchPage && {
              height: '100vh',
              overflow: 'hidden'
            }),
            '& > *': {
              transition: theme.transitions.create(['opacity'], {
                duration: theme.transitions.duration.standard,
              }),
            }
          }}
        >
          {children}
        </Box>
      </Fade>
      
      <Footer />
      <AnnouncementOverlay 
        ref={announcementRef}
      />
    </Box>
  );
};

export default Layout; 