import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Box,
  Typography,
  Grid,
  Button,
  Skeleton,
  Alert,
  useTheme,
  useMediaQuery,
  alpha,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ContentCard from '../components/ContentCard';
import Layout from '../components/Layout';
import HeroSection from '../components/HeroSection';
import { useLanguage } from '../contexts/LanguageContext';

// Loading skeleton component for content sections
const ContentSkeleton = ({ count = 6 }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const itemCount = isSmallScreen ? Math.min(count, 4) : count;

  return (
    <Grid container spacing={1.5}>
      {[...Array(itemCount)].map((_, index) => (
        <Grid item xs={6} sm={4} md={2} key={index}>
          <Skeleton
            variant="rectangular"
            height={isSmallScreen ? 200 : 300}
            sx={{
              borderRadius: 2,
              transform: 'none',
              animation: 'wave',
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

// Category section component with optimized rendering
const CategorySection = React.memo(({ category, videos = [], onViewAll }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useLanguage();

  // Move useMemo before any conditional returns
  const mappedVideos = useMemo(() => {
    if (!category?.type_name || !Array.isArray(videos)) {
      return [];
    }

    const validVideos = videos.filter(video => 
      video && 
      video.vod_id && 
      video.vod_name
    );

    return validVideos.map(video => ({
      vod_id: video.vod_id,
      vod_name: video.vod_name,
      vod_pic: video.vod_pic || '/default-poster.jpg',
      vod_score: Number(video.vod_score || 0),
      vod_year: video.vod_year,
      vod_remarks: video.vod_remarks,
      type_id: video.type_id,
      category_name: video.category_name
    }));
  }, [category, videos]);

  // Early validation after useMemo
  if (!category?.type_name || mappedVideos.length === 0) {
    return null;
  }

  return (
    <Box 
      sx={{ 
        mb: { xs: 3, sm: 4, md: 6 },
        position: 'relative',
        '&:hover .category-header': {
          transform: { xs: 'none', sm: 'translateX(8px)' },
        },
      }}
    >
      <Box 
        className="category-header"
        sx={{ 
          display: 'flex', 
          flexDirection: 'row',
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: { xs: 1.5, sm: 2, md: 3 },
          gap: { xs: 1, sm: 2 },
          transition: 'transform 0.3s ease',
          px: { xs: 1, sm: 0 },
        }}
      >
        <Typography 
          variant="h5" 
          sx={{ 
            fontWeight: 800,
            background: theme.palette.mode === 'dark' 
              ? 'linear-gradient(45deg, #fff 30%, #e0e0e0 90%)'
              : 'linear-gradient(45deg, #2c3e50 30%, #34495e 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            letterSpacing: '-0.5px',
            fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' },
          }}
        >
          {t('categories', category.type_name)}
        </Typography>

        <Button
          onClick={() => onViewAll(category)}
          endIcon={<ArrowForwardIcon />}
          sx={{
            color: 'text.secondary',
            '&:hover': {
              color: 'primary.main',
              transform: 'translateX(4px)',
              bgcolor: alpha(theme.palette.primary.main, 0.08),
            },
            transition: 'all 0.3s ease',
            borderRadius: '16px',
            px: { xs: 1.5, sm: 2.5, md: 3 },
            py: { xs: 0.5, sm: 0.75, md: 1 },
            fontSize: { xs: '0.875rem', sm: '0.95rem', md: '1rem' },
            fontWeight: 500,
            backdropFilter: 'blur(4px)',
            border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
            minWidth: { xs: 'auto', sm: '120px' },
            height: { xs: 32, sm: 36, md: 40 },
            ml: 'auto',
            '& .MuiButton-endIcon': {
              ml: { xs: 0.5, sm: 1 },
              '& > *': { 
                fontSize: { xs: '1.25rem', sm: '1.5rem' }
              }
            },
          }}
        >
          {t('common', 'viewAll')}
        </Button>
      </Box>

      <Grid 
        container 
        spacing={{ xs: 1.5, sm: 2, md: 3 }}
        sx={{ 
          '& .MuiGrid-item': {
            display: 'flex',
            transform: 'scale(1)',
            transition: 'transform 0.3s ease',
            '&:hover': {
              transform: { xs: 'scale(1.01)', sm: 'scale(1.02)' },
              zIndex: 1,
            }
          },
        }}
      >
        {mappedVideos.slice(0, isSmallScreen ? 4 : 6).map((video) => (
          <Grid item xs={6} sm={4} md={2} key={video.vod_id}>
            <ContentCard 
              content={video}
              sx={{
                width: '100%',
                '& .MuiCard-root': {
                  boxShadow: { xs: theme.shadows[4], sm: theme.shadows[8] },
                  transition: 'all 0.3s ease',
                  borderRadius: { xs: '12px', sm: '16px' },
                  overflow: 'hidden',
                  background: theme.palette.mode === 'dark' 
                    ? 'linear-gradient(to bottom, rgba(255,255,255,0.05), rgba(255,255,255,0.02))'
                    : 'linear-gradient(to bottom, rgba(255,255,255,0.9), rgba(255,255,255,0.7))',
                  backdropFilter: 'blur(10px)',
                  '&:hover': {
                    boxShadow: { xs: theme.shadows[8], sm: theme.shadows[16] },
                    transform: { xs: 'translateY(-2px)', sm: 'translateY(-4px)' },
                  }
                },
                '& .MuiCardMedia-root': {
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: { xs: 'scale(1.03)', sm: 'scale(1.05)' },
                  }
                },
                '& .MuiCardContent-root': {
                  padding: { xs: 1.5, sm: 2 },
                }
              }} 
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [homeData, setHomeData] = useState({
    featured: null,
    latest: [],
    trending: [],
    categories: []
  });
  const [featuredMovies, setFeaturedMovies] = useState([]);

  // Fetch home data with error handling and retries
  const fetchHomeData = useCallback(async (retryCount = 0) => {
    try {
      const response = await fetch('/api/home');
      if (!response.ok) {
        throw new Error('Failed to fetch home data');
      }
      const text = await response.text();
      try {
        const data = JSON.parse(text);
        if (data.status === 'success') {
          setHomeData(data.data);
          // Set featured movies from the response if available
          if (data.data.featured) {
            // Get featured movies with TMDB backdrop
            const featuredMovies = Array.isArray(data.data.featured) 
              ? data.data.featured 
              : [data.data.featured];
            
            const featuredWithBackdrops = featuredMovies.map(movie => ({
              ...movie,
              vod_tmdb_backdrop: movie.vod_tmdb_backdrop || movie.vod_pic
            }));
            setFeaturedMovies(featuredWithBackdrops);
          } else if (data.data.trending && data.data.trending.length > 0) {
            // If no featured, use trending movies with TMDB backdrop (up to 5)
            const trendingWithBackdrops = data.data.trending
              .filter(movie => movie.vod_tmdb_backdrop || movie.vod_pic)
              .slice(0, 5)
              .map(movie => ({
                ...movie,
                vod_tmdb_backdrop: movie.vod_tmdb_backdrop || movie.vod_pic
              }));
            setFeaturedMovies(trendingWithBackdrops);
          }
          setError(null);
        } else {
          throw new Error(data.message || 'Failed to load content');
        }
      } catch (parseError) {
        console.error('Error parsing home data response:', text);
        throw parseError;
      }
    } catch (err) {
      console.error('Error fetching home data:', err);
      if (retryCount < 2) {
        setTimeout(() => {
          fetchHomeData(retryCount + 1);
        }, Math.pow(2, retryCount) * 1000);
      } else {
        setError(t('home', 'loadError'));
      }
    } finally {
      setLoading(false);
    }
  }, [t]);

  useEffect(() => {
    fetchHomeData();
  }, [fetchHomeData]);

  const handleViewAll = useCallback((category) => {
    navigate(`/browse?category=${category.type_id}`);
  }, [navigate]);

  if (loading) {
    return (
      <Layout>
        <Box sx={{ height: { xs: '60vh', md: '80vh' }, mb: 4 }}>
          <Skeleton 
            variant="rectangular" 
            width="100%" 
            height="100%" 
            animation="wave"
          />
        </Box>
        <Box sx={{ py: 4 }}>
          {[...Array(4)].map((_, index) => (
            <Box key={index} sx={{ mb: 8 }}>
              <Skeleton width={200} height={40} sx={{ mb: 4 }} />
              <ContentSkeleton />
            </Box>
          ))}
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Box sx={{ py: 4 }}>
          <Alert 
            severity="error" 
            sx={{ mb: 4 }}
            action={
              <Button color="inherit" size="small" onClick={() => fetchHomeData()}>
                Retry
              </Button>
            }
          >
            {error}
          </Alert>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Helmet>
        <title>247看 - 免费在线高清影视平台 | 电影 | 电视剧 | 动漫 | 综艺</title>
        <meta name="description" content="247看 - 随时随地，想看就看。提供1080P蓝光画质在线观看，免费高清电影、连续剧、动漫、综艺、纪录片等。支持手机观看，无需下载，极速播放，同步更新。" />
        <meta name="keywords" content="247看,在线影视,高清电影,连续剧在线,动漫视频,综艺节目,纪录片,短剧,剧集,1080P高清,蓝光画质,无广告播放,免费观看,在线播放,直接观看,无需下载,手机观看,免费高清,追剧,追番,最新电影,热播剧集,热门动漫,新番动漫,热映电影,同步更新,实时更新" />
        <meta property="og:title" content="247看 - 免费在线高清影视平台" />
        <meta property="og:description" content="随时随地，想看就看。提供1080P蓝光画质在线观看，免费高清影视内容。" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://247kan.com" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Language" content="zh-CN" />
      </Helmet>
      {/* Hero Section */}
      <HeroSection featuredMovies={featuredMovies.length > 0 ? featuredMovies : [homeData?.featured]} />

      {/* Main Content */}
      <Box
        component="section"
        sx={{
          position: 'relative',
          zIndex: 4,
          mt: { xs: -2, sm: -3 },
          bgcolor: theme.palette.background.default,
          borderRadius: { xs: '16px 16px 0 0', sm: '24px 24px 0 0' },
          flex: 1,
          width: '100vw',
          left: '50%',
          right: '50%',
          marginLeft: '-50vw',
          marginRight: '-50vw',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: { xs: -16, sm: -24 },
            left: 0,
            right: 0,
            height: { xs: 16, sm: 24 },
            background: theme.palette.background.default,
            borderRadius: { xs: '16px 16px 0 0', sm: '24px 24px 0 0' },
            zIndex: 0,
          },
        }}
      >
        <Box
          sx={{
            maxWidth: theme.breakpoints.values.xl,
            mx: 'auto',
            width: '100%',
            py: { xs: 2, sm: 3, md: 4 },
            px: { xs: 1, sm: 2, md: 3 },
          }}
        >
          {/* Trending Section */}
          {homeData.trending && homeData.trending.length > 0 && (
            <CategorySection
              category={{ 
                type_name: t('home', 'hotRecommendations'), 
                type_id: 'trending' 
              }}
              videos={homeData.trending}
              onViewAll={() => navigate('/browse?sort=-vod_level')}
            />
          )}

          {/* Latest Section */}
          {homeData.latest && homeData.latest.length > 0 && (
            <CategorySection
              category={{ 
                type_name: t('home', 'latestReleases'), 
                type_id: 'latest' 
              }}
              videos={homeData.latest}
              onViewAll={() => navigate('/browse?sort=-vod_time')}
            />
          )}

          {/* Category Sections */}
          {homeData.categories.map((category) => (
            <CategorySection
              key={category.type_id}
              category={{
                type_name: t('categories', category.type_name),
                type_id: category.type_id
              }}
              videos={category.videos}
              onViewAll={handleViewAll}
            />
          ))}
        </Box>
      </Box>
    </Layout>
  );
};

export default Home; 