import React, { useState, useMemo } from 'react';
import { Grid, Button, useMediaQuery, useTheme, Box, Stack, Typography } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const EPISODES_PER_PAGE = 24; // Show 24 episodes initially

const EpisodeSelector = ({
  episodes = [],
  selectedEpisode,
  selectedRoute,
  onEpisodeSelect,
  video
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isReversed, setIsReversed] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const currentRoute = video?.available_routes?.[selectedRoute];

  const routeEpisodes = useMemo(() => {
    if (!episodes?.length || !currentRoute) return [];
    return episodes.filter(ep => ep.route === currentRoute);
  }, [episodes, currentRoute]);

  const getVisibleEpisodes = (episodes) => {
    if (showAll) return episodes;
    if (!episodes.length) return [];

    const selectedIndex = episodes.findIndex(ep => ep.episode === selectedEpisode);
    const halfRange = Math.floor(EPISODES_PER_PAGE / 2);
    
    let startIndex = Math.max(0, selectedIndex - halfRange);
    let endIndex = startIndex + EPISODES_PER_PAGE;
    
    // Adjust if we're near the end
    if (endIndex > episodes.length) {
      endIndex = episodes.length;
      startIndex = Math.max(0, endIndex - EPISODES_PER_PAGE);
    }
    
    return episodes.slice(startIndex, endIndex);
  };

  const visibleEpisodes = useMemo(() => {
    const episodes = getVisibleEpisodes(routeEpisodes);
    return isReversed ? [...episodes].reverse() : episodes;
  }, [routeEpisodes, selectedEpisode, isReversed, showAll]);

  if (!episodes?.length || !currentRoute || !routeEpisodes.length) return null;

  const totalEpisodes = routeEpisodes.length;
  const hasMoreEpisodes = totalEpisodes > EPISODES_PER_PAGE;

  return (
    <Stack spacing={2}>
      <Stack 
        direction="row" 
        justifyContent="space-between" 
        alignItems="center"
        spacing={2}
      >
        <Typography 
          variant="subtitle1" 
          sx={{ 
            fontWeight: 600,
            fontSize: { xs: '0.875rem', sm: '1rem' }
          }}
        >
          选集
        </Typography>
        <Button
          variant="outlined"
          size={isMobile ? "small" : "medium"}
          onClick={() => setIsReversed(!isReversed)}
          startIcon={<SwapVertIcon />}
          sx={{
            minWidth: 'auto',
            borderRadius: 1,
            px: { xs: 1.5, sm: 2 },
          }}
        >
          {isReversed ? '正序' : '倒序'}
        </Button>
      </Stack>

      <Box>
        <Grid container spacing={1}>
          {visibleEpisodes.map((episode) => (
            <Grid item key={episode.episode}>
              <Button
                variant={selectedEpisode === episode.episode ? "contained" : "outlined"}
                onClick={() => onEpisodeSelect(episode.episode)}
                sx={{
                  minWidth: 'auto',
                  height: isMobile ? 40 : 48,
                  borderRadius: 1,
                  px: 2,
                  fontSize: { xs: '0.813rem', sm: '0.875rem' },
                  fontWeight: selectedEpisode === episode.episode ? 600 : 500,
                }}
              >
                {episode.name || `第${episode.episode}集`}
              </Button>
            </Grid>
          ))}
        </Grid>

        {hasMoreEpisodes && (
          <Button
            fullWidth
            onClick={() => setShowAll(!showAll)}
            sx={{
              mt: 2,
              color: 'text.secondary',
              '&:hover': {
                bgcolor: theme => theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.05)' 
                  : 'rgba(0, 0, 0, 0.05)'
              }
            }}
            endIcon={showAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            {showAll ? '收起' : `展开全部 ${totalEpisodes} 集`}
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export default EpisodeSelector; 