import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Chip,
  Stack,
  CircularProgress,
  Divider,
  Paper,
  useTheme,
  IconButton,
  Tooltip,
  Fade,
  Skeleton,
  Alert,
  Tabs,
  Tab,
  useMediaQuery,
  Breadcrumbs,
  Rating,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StarIcon from '@mui/icons-material/Star';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TranslateIcon from '@mui/icons-material/Translate';
import PersonIcon from '@mui/icons-material/Person';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import DownloadIcon from '@mui/icons-material/Download';
import TheatersIcon from '@mui/icons-material/Theaters';
import LanguageIcon from '@mui/icons-material/Language';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { useLanguage } from '../contexts/LanguageContext';
import Layout from '../components/Layout';
import { getVideoById } from '../services/api';
import { alpha } from '@mui/material/styles';
import EpisodeSelector from '../components/VideoPlayer/EpisodeSelector';
import RouteSelector from '../components/VideoPlayer/RouteSelector';
import { useAuth } from '../contexts/AuthContext';
import {
  addToFavorites,
  removeFromFavorites,
  addToWatchLater,
  removeFromWatchLater,
  getFavorites,
  getWatchLater
} from '../services/api';

const LoadingSkeleton = () => (
  <Box>
    <Box
      sx={{
        height: { xs: '400px', md: '600px' },
        bgcolor: 'background.paper',
        position: 'relative',
      }}
    >
      <Container maxWidth="xl" sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} sm={4} md={3}>
            <Skeleton variant="rectangular" height={400} sx={{ borderRadius: 2 }} />
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Stack spacing={2}>
              <Skeleton variant="text" height={60} width="80%" />
              <Skeleton variant="text" height={30} width="60%" />
              <Stack direction="row" spacing={1}>
                {[1, 2, 3, 4].map((i) => (
                  <Skeleton key={i} variant="rectangular" width={100} height={32} sx={{ borderRadius: 4 }} />
                ))}
              </Stack>
              <Skeleton variant="text" height={100} />
              <Stack direction="row" spacing={1}>
                {[1, 2, 3].map((i) => (
                  <Skeleton key={i} variant="rectangular" width={120} height={40} sx={{ borderRadius: 2 }} />
                ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </Box>
);

const MovieContext = createContext();

const MovieDetail = ({ movie: propMovie, isListView, onPlay }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useLanguage();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [movie, setMovie] = useState(propMovie || null);
  const [loading, setLoading] = useState(!propMovie);
  const [error, setError] = useState(null);
  const [selectedEpisode, setSelectedEpisode] = useState(1);
  const [currentTab, setCurrentTab] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(0);
  const [isTextTruncated, setIsTextTruncated] = useState(false);
  const descriptionRef = useRef(null);
  const { isAuthenticated } = useAuth();
  const [isFavorite, setIsFavorite] = useState(false);
  const [isWatchLater, setIsWatchLater] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [watchLaterLoading, setWatchLaterLoading] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);

  // Function to find the first 蓝光 route index
  const findFirstBluRayRouteIndex = (routes) => {
    if (!routes?.length) return 0;
    const bluRayPriority = ['heimuer', 'ffm3u8', 'tym3u8', 'ikm3u8', '1080zyk'];
    for (const route of bluRayPriority) {
      const index = routes.indexOf(route);
      if (index !== -1) return index;
    }
    return 0;
  };

  // Move cleanHtmlTags function before it's used
  const cleanHtmlTags = React.useCallback((text) => {
    if (!text) return 'No Description';
    return text
      .replace(/<\/?[^>]+(>|$)/g, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/\s+/g, ' ')
      .trim() || 'No Description';
  }, []);

  // Process movie data
  const processedMovie = React.useMemo(() => {
    if (!movie) return null;

    // Extract and clean the type
    const genres = typeof movie.vod_class === 'string' ? movie.vod_class.split(',').map(g => g.trim()).filter(Boolean) : [];
    const typeFromName = movie.type_name || '';
    const cleanType = genres.length > 0 ? genres[0] : typeFromName;

    // Process episodes data
    let processedEpisodes = [];
    let availableRoutes = [];
    
    if (Array.isArray(movie.episodes)) {
      processedEpisodes = movie.episodes;
      availableRoutes = movie.available_routes || [];
    } else if (movie.vod_play_url && movie.vod_play_from) {
      // Handle old format where episodes are in vod_play_url
      const routes = movie.vod_play_from.split('$$$');
      const urls = movie.vod_play_url.split('$$$');
      
      availableRoutes = routes;
      processedEpisodes = [];
      
      routes.forEach((route, routeIndex) => {
        const episodeUrls = urls[routeIndex]?.split('#') || [];
        const routeEpisodes = episodeUrls.map((ep, index) => {
          const [name, url] = ep.split('$');
          return { episode: index + 1, name, url, route };
        });
        processedEpisodes = [...processedEpisodes, ...routeEpisodes];
      });
    }

    // Clean and validate actor/director lists
    const cleanList = (str) => {
      if (!str) return [];
      if (Array.isArray(str)) return str.map(item => String(item).trim());
      if (typeof str !== 'string') return [String(str).trim()];
      return str.split(',')
        .map(item => item.trim())
        .filter(Boolean);
    };

    return {
      vod_id: movie.vod_id || '',
      vod_name: movie.vod_name || 'Untitled',
      vod_sub: movie.vod_tag || movie.vod_sub || '',
      vod_pic: movie.vod_pic || '/placeholder.jpg',
      vod_douban_score: (() => {
        const doubanScore = parseFloat(movie.vod_douban_score);
        return !isNaN(doubanScore) ? doubanScore : 0;
      })(),
      vod_year: movie.vod_year || '',
      vod_area: movie.vod_area || '',
      vod_lang: movie.vod_lang || '',
      vod_actor: cleanList(movie.vod_actor),
      vod_director: cleanList(movie.vod_director),
      vod_content: cleanHtmlTags(movie.vod_content || movie.vod_blurb || 'No Description'),
      vod_class: typeof movie.vod_class === 'string' ? movie.vod_class : '',
      vod_remarks: movie.vod_remarks?.trim() || '',
      episodes: processedEpisodes,
      available_routes: availableRoutes,
      total_episodes: parseInt(movie.total_episodes) || processedEpisodes.length,
      vod_en: movie.vod_en || '',
      type_name: cleanType,
      is_anime: cleanType.includes('动漫') || cleanType.includes('漫画'),
      update_time: movie.vod_time || movie.update_time,
      vod_play_from: movie.vod_play_from || '',
    };
  }, [movie, cleanHtmlTags]);

  useEffect(() => {
    if (propMovie) {
      setMovie(propMovie);
      return;
    }

    const fetchMovie = async () => {
      setLoading(true);
      try {
        const data = await getVideoById(id);
        if (!data) {
          throw new Error('No data received from API');
        }
        // Set initial route to first 蓝光 route
        if (data.available_routes?.length > 0) {
          setSelectedRoute(findFirstBluRayRouteIndex(data.available_routes));
        }
        setMovie(data);
      } catch (err) {
        console.error('Error fetching movie:', err);
        setError(err.message || 'Failed to load movie details');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchMovie();
    }
  }, [id, propMovie]);

  // Group episodes by route and season if applicable
  const groupedEpisodes = React.useMemo(() => {
    if (!processedMovie?.episodes?.length) return [];
    
    // Get current route
    const currentRoute = processedMovie.available_routes[selectedRoute];
    
    // Filter episodes for current route
    const routeEpisodes = processedMovie.episodes.filter(
      ep => ep.route === currentRoute
    );
    
    // Reset selected episode when changing routes if current episode doesn't exist in new route
    const episodeExistsInNewRoute = routeEpisodes.some(ep => ep.episode === selectedEpisode);
    if (!episodeExistsInNewRoute && routeEpisodes.length > 0) {
      // Find first episode in new route
      const firstEpisodeInRoute = routeEpisodes[0];
      setSelectedEpisode(firstEpisodeInRoute.episode);
    }
    
    const hasSeasons = routeEpisodes.some(ep => ep.season);
    if (!hasSeasons) return [{ episodes: routeEpisodes }];

    // Group by seasons
    return Object.values(
      routeEpisodes.reduce((acc, episode) => {
        const season = episode.season || 1;
        if (!acc[season]) {
          acc[season] = { season, episodes: [] };
        }
        acc[season].episodes.push(episode);
        return acc;
      }, {})
    ).sort((a, b) => a.season - b.season);
  }, [processedMovie?.episodes, processedMovie?.available_routes, selectedRoute]);

  // Add routeNames mapping
  const routeNames = {
    'heimuer': '蓝光H',
    'tym3u8': '蓝光T',
    'ffm3u8': '蓝光F',
    'ikm3u8': '蓝光I',
    '1080zyk': '蓝光Y',
    'bfzym3u8': '备用B',
    'okm3u8': '备用O',
  };

  // Update episode selection to include route
  const handleEpisodeSelect = (episode) => {
    setSelectedEpisode(episode);
    if (processedMovie) {
      const searchParams = new URLSearchParams();
      searchParams.set('route', selectedRoute.toString());
      searchParams.set('ep', episode.toString());
      navigate(`/watch/${processedMovie.vod_id}?${searchParams.toString()}`);
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: processedMovie.vod_name,
        text: processedMovie.vod_content,
        url: window.location.href,
      });
    }
  };

  const handleImageError = () => {
    setImageError(true);
    setIsImageLoaded(true);
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  // Add an effect to handle route changes
  useEffect(() => {
    // Reset to first episode when route changes
    setSelectedEpisode(1);
  }, [selectedRoute]);

  // Update handleRouteSelect function to not redirect
  const handleRouteSelect = (routeIndex) => {
    setSelectedRoute(routeIndex);
    const newRoute = processedMovie.available_routes[routeIndex];
    const firstEpisodeInRoute = processedMovie.episodes.find(
      ep => ep.route === newRoute
    );
    
    if (firstEpisodeInRoute) {
      setSelectedEpisode(firstEpisodeInRoute.episode);
    }
  };

  // Add useEffect to check if text is truncated
  useEffect(() => {
    const checkTextTruncation = () => {
      if (descriptionRef.current) {
        const element = descriptionRef.current;
        setIsTextTruncated(element.scrollHeight > element.clientHeight);
      }
    };

    checkTextTruncation();
    window.addEventListener('resize', checkTextTruncation);
    return () => window.removeEventListener('resize', checkTextTruncation);
  }, [processedMovie?.vod_content]);

  // Check user lists (favorites and watch later) when component mounts
  useEffect(() => {
    const checkUserLists = async () => {
      if (!isAuthenticated || !movie?.vod_id) return;

      try {
        const [favorites, watchLater] = await Promise.all([
          getFavorites(),
          getWatchLater()
        ]);

        setIsFavorite(favorites.data?.some(item => item.vod_id === movie.vod_id) || false);
        setIsWatchLater(watchLater.data?.some(item => item.vod_id === movie.vod_id) || false);
      } catch (error) {
        console.error('Error checking user lists:', error);
      }
    };

    checkUserLists();
  }, [isAuthenticated, movie?.vod_id]);

  const handleFavoriteClick = async () => {
    if (!isAuthenticated) {
      setSnackbarMessage(t('common', 'pleaseLoginFirst'));
      return;
    }

    if (favoriteLoading) return;

    setFavoriteLoading(true);
    try {
      if (isFavorite) {
        await removeFromFavorites(movie.vod_id);
        setSnackbarMessage(t('movieDetail', 'removeFromFavorites'));
      } else {
        await addToFavorites(movie.vod_id);
        setSnackbarMessage(t('movieDetail', 'addToFavorites'));
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error('Error updating favorites:', error);
      setSnackbarMessage(error.message || t('common', 'errorOccurred'));
    } finally {
      setFavoriteLoading(false);
    }
  };

  const handleWatchLaterClick = async () => {
    if (!isAuthenticated) {
      setSnackbarMessage(t('common', 'pleaseLoginFirst'));
      return;
    }

    if (watchLaterLoading) return;

    setWatchLaterLoading(true);
    try {
      if (isWatchLater) {
        await removeFromWatchLater(movie.vod_id);
        setSnackbarMessage(t('member', 'removedFromWatchLater'));
      } else {
        await addToWatchLater(movie.vod_id);
        setSnackbarMessage(t('member', 'addedToWatchLater'));
      }
      setIsWatchLater(!isWatchLater);
    } catch (error) {
      console.error('Error updating watch later:', error);
      setSnackbarMessage(error.message || t('common', 'errorOccurred'));
    } finally {
      setWatchLaterLoading(false);
    }
  };

  const handleReportClick = () => {
    setOpenReportDialog(true);
  };

  const handleReportConfirm = () => {
    setOpenReportDialog(false);
    navigate(`/guestbook?title=${encodeURIComponent(processedMovie.vod_name)}&content=${encodeURIComponent(`影片ID: ${processedMovie.vod_id}\n问题描述: `)}`);
  };

  // Add these buttons to the movie actions section
  const renderMovieActions = () => (
    <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
      <Button
        variant={isFavorite ? "contained" : "outlined"}
        onClick={handleFavoriteClick}
        disabled={favoriteLoading}
        startIcon={isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        sx={{
          bgcolor: isFavorite ? 'error.main' : 'transparent',
          color: isFavorite ? 'error.contrastText' : 'error.main',
          borderColor: 'error.main',
          '&:hover': {
            bgcolor: isFavorite ? 'error.dark' : alpha(theme.palette.error.main, 0.1),
            borderColor: 'error.main'
          },
          '&.Mui-disabled': {
            bgcolor: isFavorite ? 'error.main' : 'transparent',
            opacity: 0.7,
            borderColor: 'error.main'
          }
        }}
      >
        {t('movieDetail', isFavorite ? 'removeFromFavorites' : 'addToFavorites')}
      </Button>

      <Button
        variant={isWatchLater ? "contained" : "outlined"}
        onClick={handleWatchLaterClick}
        disabled={watchLaterLoading}
        startIcon={isWatchLater ? <WatchLaterIcon /> : <WatchLaterOutlinedIcon />}
        sx={{
          bgcolor: isWatchLater ? 'primary.main' : 'transparent',
          color: isWatchLater ? 'primary.contrastText' : 'primary.main',
          '&:hover': {
            bgcolor: isWatchLater ? 'primary.dark' : alpha(theme.palette.primary.main, 0.1)
          },
          '&.Mui-disabled': {
            bgcolor: isWatchLater ? 'primary.main' : 'transparent',
            opacity: 0.7
          }
        }}
      >
        {t('movieDetail', isWatchLater ? 'removeFromWatchLater' : 'addToWatchLater')}
      </Button>
    </Stack>
  );

  if (isListView && processedMovie) {
    return (
      <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
        {/* Poster */}
        <Box 
          component="img"
          src={processedMovie.vod_pic || '/default-poster.jpg'}
          alt={processedMovie.vod_name}
          sx={{ 
            width: 120,
            height: 180,
            objectFit: 'cover',
            borderRadius: 1,
            flexShrink: 0
          }}
        />

        {/* Content */}
        <Box sx={{ flex: 1, minWidth: 0 }}>
          {/* Title and Rating */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, alignItems: 'flex-start' }}>
            <Box sx={{ minWidth: 0 }}>
              <Typography variant="h6" sx={{ mb: 0.5, fontWeight: 600 }} noWrap>
                {processedMovie.vod_name}
              </Typography>
              {processedMovie.vod_en && (
                <Typography variant="body2" color="text.secondary" noWrap>
                  {processedMovie.vod_en}
                </Typography>
              )}
            </Box>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ flexShrink: 0, ml: 2 }}>
              {processedMovie.vod_douban_score > 0 && (
                <Chip 
                  icon={<StarIcon sx={{ fontSize: '16px !important' }} />} 
                  label={processedMovie.vod_douban_score.toFixed(1)} 
                  size="small" 
                  color="warning"
                  sx={{ height: 24 }}
                />
              )}
            </Stack>
          </Box>

          {/* Location and Language Tags */}
          <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
            {processedMovie.vod_area && (
              <Chip 
                label={processedMovie.vod_area}
                size="small"
                variant="outlined"
                sx={{ height: 24 }}
              />
            )}
            {processedMovie.vod_lang && (
              <Chip 
                label={processedMovie.vod_lang}
                size="small"
                variant="outlined"
                sx={{ height: 24 }}
              />
            )}
            {processedMovie.vod_year && (
              <Chip 
                label={processedMovie.vod_year}
                size="small"
                variant="outlined"
                sx={{ height: 24 }}
              />
            )}
          </Stack>

          {/* Genre Tags */}
          {processedMovie.vod_class && typeof processedMovie.vod_class === 'string' && (
            <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
              {processedMovie.vod_class.split(',').map((genre, index) => (
                <Chip
                  key={index}
                  label={genre.trim()}
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => navigate(`/browse?tag=${encodeURIComponent(genre.trim())}`)}
                  sx={{ 
                    height: 24,
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: alpha(theme.palette.primary.main, 0.1),
                    }
                  }}
                />
              ))}
            </Stack>
          )}

          {/* Status Tag */}
          {processedMovie.vod_remarks && (
            <Chip 
              label={processedMovie.vod_remarks}
              size="small"
              color="secondary"
              sx={{ height: 24, mb: 1 }}
            />
          )}

          {/* Cast Info */}
          <Grid container spacing={1} sx={{ mb: 1 }}>
            {processedMovie.vod_actor && processedMovie.vod_actor.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary" noWrap>
                  <PersonIcon sx={{ fontSize: 16, mr: 0.5, verticalAlign: 'text-bottom' }} />
                  {Array.isArray(processedMovie.vod_actor) ? processedMovie.vod_actor.slice(0, 3).join(', ') : processedMovie.vod_actor}
                </Typography>
              </Grid>
            )}
            {processedMovie.vod_director && processedMovie.vod_director.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary" noWrap>
                  <LocalMoviesIcon sx={{ fontSize: 16, mr: 0.5, verticalAlign: 'text-bottom' }} />
                  {Array.isArray(processedMovie.vod_director) ? processedMovie.vod_director.slice(0, 2).join(', ') : processedMovie.vod_director}
                </Typography>
              </Grid>
            )}
          </Grid>

          {/* Action Buttons */}
          <Box sx={{ mt: 'auto', pt: 1 }}>
            <Button
              variant="contained"
              size="small"
              onClick={onPlay}
              startIcon={<PlayArrowIcon />}
              sx={{ mr: 1 }}
            >
              {t('movieDetail', 'playNow')}
            </Button>
            <IconButton size="small" onClick={() => navigate(`/detail/${processedMovie.vod_id}`)}>
              <InfoOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  }

  if (loading) {
    return (
      <Layout>
        <LoadingSkeleton />
      </Layout>
    );
  }

  if (error || !processedMovie) {
    return (
      <Layout>
        <Container maxWidth="xl" sx={{ py: 8 }}>
          <Alert 
            severity="error" 
            action={
              <Button color="inherit" component={Link} to="/">
                {t('common', 'backToHome')}
              </Button>
            }
          >
            {error || t('movieDetail', 'notFound')}
          </Alert>
        </Container>
      </Layout>
    );
  }

  return (
    <MovieContext.Provider value={{
      processedMovie,
      selectedRoute,
      setSelectedRoute,
      routeNames
    }}>
      <Helmet>
        <title>{`${processedMovie.vod_name} ${processedMovie.vod_year ? `(${processedMovie.vod_year})` : ''} 高清在线观看 - 1080P蓝光免费观看 | 247看`}</title>
        <meta name="description" content={`《${processedMovie.vod_name}》${processedMovie.vod_year ? `(${processedMovie.vod_year})` : ''}在线观看，${processedMovie.vod_content?.slice(0, 100)}... 提供1080P蓝光高清画质，支持手机/平板观看，无广告，免费在线播放，同步更新。主演：${processedMovie.vod_actor?.slice(0, 3).join(',')}，导演：${processedMovie.vod_director?.slice(0, 2).join(',')}`} />
        <meta name="keywords" content={`${processedMovie.vod_name},${processedMovie.vod_en || ''},${processedMovie.type_name || ''},在线观看,高清${processedMovie.type_name},免费观看,1080P蓝光,无广告播放,手机观看,${processedMovie.vod_actor?.slice(0, 5).join(',') || ''},${processedMovie.vod_director?.slice(0, 3).join(',') || ''},${processedMovie.vod_year || ''},${processedMovie.vod_area || ''},247看,${typeof processedMovie.vod_class === 'string' ? processedMovie.vod_class.split(',').slice(0, 5).join(',') : ''}`} />
        <meta property="og:title" content={`${processedMovie.vod_name} ${processedMovie.vod_year ? `(${processedMovie.vod_year})` : ''} - 247看 在线免费观看`} />
        <meta property="og:description" content={`${processedMovie.vod_content?.slice(0, 200)}...，1080P蓝光画质，免费高清无广告。`} />
        <meta property="og:image" content={processedMovie.vod_pic} />
        <meta property="og:type" content="video.movie" />
        <meta property="og:site_name" content="247看" />
        <meta property="og:locale" content="zh_CN" />
        {processedMovie.vod_pubdate && (
          <meta property="article:published_time" content={new Date(processedMovie.vod_pubdate).toISOString()} />
        )}
        {processedMovie.vod_time && (
          <meta property="article:modified_time" content={new Date(processedMovie.vod_time * 1000).toISOString()} />
        )}
        <meta property="video:duration" content={processedMovie.vod_duration || ''} />
        {processedMovie.vod_area && <meta property="video:region" content={processedMovie.vod_area} />}
        {processedMovie.vod_lang && <meta property="video:language" content={processedMovie.vod_lang} />}
        {processedMovie.vod_total > 0 && <meta property="video:episodes" content={processedMovie.vod_total} />}
        {processedMovie.vod_douban_score > 0 && <meta property="video:rating" content={processedMovie.vod_douban_score} />}
        <link rel="canonical" href={window.location.href} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": processedMovie.type_name === "电影" ? "Movie" : "TVSeries",
            "name": processedMovie.vod_name,
            "alternateName": processedMovie.vod_sub || processedMovie.vod_en || undefined,
            "description": processedMovie.vod_content,
            "datePublished": processedMovie.vod_pubdate ? new Date(processedMovie.vod_pubdate).toISOString() : undefined,
            "dateModified": processedMovie.vod_time ? new Date(processedMovie.vod_time * 1000).toISOString() : undefined,
            "image": processedMovie.vod_pic,
            "duration": processedMovie.vod_duration ? `PT${processedMovie.vod_duration}M` : undefined,
            "numberOfEpisodes": processedMovie.vod_total > 0 ? processedMovie.vod_total : undefined,
            "contentRating": "PG",
            "inLanguage": processedMovie.vod_lang || "zh",
            "countryOfOrigin": processedMovie.vod_area,
            "yearPublished": processedMovie.vod_year,
            "sameAs": processedMovie.vod_douban_id ? `https://movie.douban.com/subject/${processedMovie.vod_douban_id}/` : undefined,
            "aggregateRating": processedMovie.vod_douban_score > 0 ? {
              "@type": "AggregateRating",
              "ratingValue": (() => {
                const score = parseFloat(processedMovie.vod_douban_score);
                if (isNaN(score) || score < 1) return undefined;
                if (score > 10) return 10;
                return score;
              })(),
              "bestRating": "10",
              "worstRating": "1",
              "ratingCount": Math.max(1, parseInt(processedMovie.vod_hits) || 1)
            } : undefined,
            "director": processedMovie.vod_director?.map(name => ({
              "@type": "Person",
              "name": name
            })),
            "actor": processedMovie.vod_actor?.map(name => ({
              "@type": "Person", 
              "name": name
            })),
            "genre": typeof processedMovie.vod_class === 'string' ? processedMovie.vod_class.split(',') : [],
            "productionCompany": {
              "@type": "Organization",
              "name": "247看"
            }
          })}
        </script>
      </Helmet>
      <Layout>
        {/* Hero Section */}
        <Box
          sx={{
            position: 'relative',
            minHeight: { xs: 'auto', sm: '500px', md: '600px' },
            width: '100%',
            overflow: 'hidden',
            pb: { xs: 4, sm: 0 },
            bgcolor: 'background.paper',
            mt: { xs: 0, sm: 4 },
            mx: { xs: 0, sm: 3, md: 4 },
            borderRadius: { xs: 0, sm: '24px' },
            border: theme => `1px solid ${alpha(theme.palette.divider, 0.1)}`
          }}
        >
          {/* Background Container */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: 'hidden',
              borderRadius: 'inherit',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: theme => theme.palette.mode === 'dark'
                  ? `linear-gradient(135deg, ${alpha(theme.palette.background.paper, 0.9)} 0%, ${alpha(theme.palette.background.paper, 0.8)} 50%, ${alpha(theme.palette.background.paper, 0.9)} 100%)`
                  : 'linear-gradient(to right, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.85) 50%, rgba(255,255,255,0.95) 100%)',
                opacity: theme => theme.palette.mode === 'dark' ? 0.95 : 0.98,
                zIndex: 2
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: theme => theme.palette.mode === 'dark'
                  ? 'url("data:image/svg+xml,%3Csvg viewBox=\'0 0 100 100\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cfilter id=\'noiseFilter\'%3E%3CfeTurbulence type=\'fractalNoise\' baseFrequency=\'0.7\' numOctaves=\'3\' stitchTiles=\'stitch\'/%3E%3C/filter%3E%3Crect width=\'100\' height=\'100\' filter=\'url(%23noiseFilter)\' opacity=\'0.08\'/%3E%3C/svg%3E")'
                  : 'url("data:image/svg+xml,%3Csvg viewBox=\'0 0 100 100\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cfilter id=\'noiseFilter\'%3E%3CfeTurbulence type=\'fractalNoise\' baseFrequency=\'0.9\' numOctaves=\'3\' stitchTiles=\'stitch\'/%3E%3C/filter%3E%3Crect width=\'100\' height=\'100\' filter=\'url(%23noiseFilter)\' opacity=\'0.04\'/%3E%3C/svg%3E")',
                backgroundRepeat: 'repeat',
                opacity: theme => theme.palette.mode === 'dark' ? 0.2 : 0.5,
                mixBlendMode: theme => theme.palette.mode === 'dark' ? 'soft-light' : 'overlay',
                zIndex: 3
              }
            }}
          >
            <Box
              component="img"
              src={processedMovie.vod_pic}
              alt=""
              onLoad={handleImageLoad}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                opacity: theme => theme.palette.mode === 'dark' ? 0.3 : 0.3,
                transition: 'opacity 0.5s ease-in-out',
                transform: 'scale(1.1)',
                filter: theme => theme.palette.mode === 'dark'
                  ? 'brightness(0.4) contrast(1.1) saturate(0.7)'
                  : 'brightness(0.6) contrast(1.1)'
              }}
            />
          </Box>

          {/* Content Container */}
          <Container 
            maxWidth={false}
            sx={{ 
              position: 'relative',
              height: '100%',
              display: 'flex',
              alignItems: { xs: 'flex-start', sm: 'center' },
              zIndex: 4,
              pt: { xs: 6, sm: 6 },
              px: { xs: 2, sm: 4, md: 5 }
            }}
          >
            <Grid 
              container 
              spacing={{ xs: 0, sm: 4 }}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
            >
              {/* Poster */}
              <Grid 
                item 
                xs={12}
                sm={4} 
                md={3} 
                lg={2.5}
                sx={{
                  mb: { xs: 2, sm: 0 },
                  display: 'flex',
                  justifyContent: { xs: 'center', sm: 'flex-start' },
                  mt: { xs: 2, sm: 0 },
                }}
              >
                <Fade in={isImageLoaded} timeout={1000}>
                  <Paper
                    elevation={0}
                    sx={{
                      width: { xs: '50%', sm: '100%' },
                      maxWidth: { xs: '200px', sm: 'none' },
                      margin: { xs: '0 auto', sm: 0 },
                      paddingTop: { xs: '75%', sm: '150%' },
                      position: 'relative',
                      overflow: 'hidden',
                      borderRadius: { xs: '12px', sm: '12px' },
                      transition: 'all 0.3s ease-in-out',
                      border: theme => `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                    }}
                  >
                    {imageError ? (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bgcolor: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
                          gap: 2,
                        }}
                      >
                        <Box
                          component="img"
                          src="/icon.png"
                          alt="247看"
                          sx={{
                            width: '40%',
                            height: 'auto',
                            maxWidth: 120,
                            opacity: 0.8,
                          }}
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 600,
                            letterSpacing: '.05rem',
                            opacity: 0.8,
                          }}
                        >
                          247看
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        component="img"
                        src={processedMovie.vod_pic}
                        alt={processedMovie.vod_name}
                        onError={handleImageError}
                        onLoad={handleImageLoad}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    )}
                  </Paper>
                </Fade>
              </Grid>

              {/* Info */}
              <Grid item xs={12} sm={8} md={9} lg={9.5}>
                <Fade in={isImageLoaded} timeout={1000}>
                  <Stack spacing={{ xs: 3, sm: 3 }}>
                    {/* Title and Rating */}
                    <Stack spacing={{ xs: 2, sm: 2 }}>
                      <Typography 
                        variant="h3" 
                        sx={{
                          color: theme => theme.palette.mode === 'dark' ? '#fff' : theme.palette.grey[900],
                          fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3rem' },
                          lineHeight: { xs: 1.3, sm: 1.2 },
                          letterSpacing: '-0.02em',
                          textAlign: { xs: 'center', sm: 'left' },
                          fontWeight: 800,
                          textShadow: theme => theme.palette.mode === 'dark'
                            ? '0 2px 4px rgba(0,0,0,0.6), 0 4px 8px rgba(0,0,0,0.4)'
                            : 'none',
                        }}
                      >
                        {processedMovie.vod_name}
                      </Typography>

                      {/* Rating and Year */}
                      <Stack 
                        direction="row" 
                        spacing={2} 
                        alignItems="center" 
                        sx={{ 
                          flexWrap: 'wrap', 
                          gap: 1,
                          justifyContent: { xs: 'center', sm: 'flex-start' }
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            bgcolor: theme => alpha(
                              theme.palette.mode === 'dark' ? '#fff' : '#000',
                              theme.palette.mode === 'dark' ? 0.15 : 0.08
                            ),
                            color: theme => theme.palette.mode === 'dark' ? '#fff' : theme.palette.grey[900],
                            backdropFilter: 'blur(8px)',
                            px: { xs: 1.5, sm: 2 },
                            py: { xs: 0.5, sm: 1 },
                            borderRadius: 2,
                            border: '1px solid',
                            borderColor: theme => alpha(
                              theme.palette.mode === 'dark' ? '#fff' : '#000',
                              theme.palette.mode === 'dark' ? 0.2 : 0.15
                            ),
                          }}
                        >
                          <StarIcon sx={{ 
                            color: 'inherit',
                            fontSize: { xs: '1rem', sm: '1.2rem' }
                          }} />
                          <Typography
                            variant="h6"
                            sx={{
                              color: 'inherit',
                              fontWeight: 600,
                              fontSize: { xs: '0.875rem', sm: '1rem' },
                              textShadow: theme => theme.palette.mode === 'dark'
                                ? '0 1px 2px rgba(0,0,0,0.6)'
                                : 'none',
                            }}
                          >
                            {processedMovie.vod_douban_score > 0 ? processedMovie.vod_douban_score.toFixed(1) : '暂无评分'}
                          </Typography>
                        </Box>
                        {processedMovie.vod_year && (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              bgcolor: theme => alpha(
                                theme.palette.mode === 'dark' ? '#fff' : '#000',
                                theme.palette.mode === 'dark' ? 0.15 : 0.08
                              ),
                              color: theme => theme.palette.mode === 'dark' ? '#fff' : theme.palette.grey[900],
                              backdropFilter: 'blur(8px)',
                              px: { xs: 1.5, sm: 2 },
                              py: { xs: 0.5, sm: 1 },
                              borderRadius: 2,
                              border: '1px solid',
                              borderColor: theme => alpha(
                                theme.palette.mode === 'dark' ? '#fff' : '#000',
                                theme.palette.mode === 'dark' ? 0.2 : 0.15
                              ),
                            }}
                          >
                            <CalendarTodayIcon sx={{ 
                              color: 'inherit',
                              fontSize: { xs: '1rem', sm: '1.2rem' }
                            }} />
                            <Typography
                              variant="h6"
                              sx={{
                                color: 'inherit',
                                fontWeight: 600,
                                fontSize: { xs: '0.875rem', sm: '1rem' },
                                textShadow: theme => theme.palette.mode === 'dark'
                                  ? '0 1px 2px rgba(0,0,0,0.6)'
                                  : 'none',
                              }}
                            >
                              {processedMovie.vod_year}
                            </Typography>
                          </Box>
                        )}
                      </Stack>

                      {/* Genres */}
                      <Stack 
                        direction="row" 
                        spacing={1} 
                        flexWrap="wrap" 
                        sx={{ 
                          gap: 1,
                          justifyContent: { xs: 'center', sm: 'flex-start' },
                          width: '100%',
                          '& .MuiChip-root': {
                            bgcolor: theme => alpha(
                              theme.palette.mode === 'dark' ? '#fff' : '#000',
                              theme.palette.mode === 'dark' ? 0.15 : 0.08
                            ),
                            color: theme => theme.palette.mode === 'dark' ? '#fff' : theme.palette.grey[900],
                            fontWeight: 600,
                            backdropFilter: 'blur(8px)',
                            border: '1px solid',
                            borderColor: theme => alpha(
                              theme.palette.mode === 'dark' ? '#fff' : '#000',
                              theme.palette.mode === 'dark' ? 0.2 : 0.15
                            ),
                            textShadow: theme => theme.palette.mode === 'dark'
                              ? '0 1px 2px rgba(0,0,0,0.6)'
                              : 'none',
                          }
                        }}
                      >
                        {processedMovie.vod_class && typeof processedMovie.vod_class === 'string' && processedMovie.vod_class.split(',').map((genre, index) => (
                          <Chip
                            key={index}
                            label={genre.trim()}
                            size={isMobile ? "small" : "medium"}
                            onClick={() => navigate(`/browse?tag=${encodeURIComponent(genre.trim())}`)}
                          />
                        ))}
                      </Stack>
                    </Stack>

                    {/* Description */}
                    <Box>
                      <Typography
                        ref={descriptionRef}
                        variant="body1"
                        sx={{
                          maxWidth: '800px',
                          display: '-webkit-box',
                          WebkitLineClamp: showFullDescription ? 'unset' : { xs: 2, sm: 3 },
                          WebkitBoxOrient: 'vertical',
                          overflow: showFullDescription ? 'visible' : 'hidden',
                          textOverflow: 'ellipsis',
                          lineHeight: 1.6,
                          fontSize: { xs: '0.875rem', sm: '1rem' },
                          opacity: 1,
                          textAlign: { xs: 'center', sm: 'left' },
                          mx: { xs: 'auto', sm: 0 },
                          color: theme => theme.palette.mode === 'dark' ? 'grey.100' : 'grey.900',
                          fontWeight: 500,
                          textShadow: theme => theme.palette.mode === 'dark'
                            ? '0 1px 2px rgba(0,0,0,0.8)'
                            : 'none',
                          letterSpacing: '0.01em',
                        }}
                      >
                        {cleanHtmlTags(processedMovie.vod_content)}
                      </Typography>
                      {isTextTruncated && (
                        <Button
                          onClick={() => setShowFullDescription(!showFullDescription)}
                          sx={{
                            mt: 1,
                            textTransform: 'none',
                            color: 'primary.main',
                            p: 0,
                            minWidth: 'auto',
                            fontWeight: 500,
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                            display: 'block',
                            mx: { xs: 'auto', sm: 0 },
                            '&:hover': {
                              background: 'none',
                              textDecoration: 'underline',
                            }
                          }}
                        >
                          {showFullDescription ? t('movieDetail', 'showLess') || 'Show Less' : t('movieDetail', 'showMore') || 'Show More'}
                        </Button>
                      )}
                    </Box>

                    {/* Meta Info */}
                    <Stack spacing={{ xs: 1.5, sm: 2 }} sx={{ width: '100%' }}>
                      {/* Director Info */}
                      {(processedMovie.vod_director && processedMovie.vod_director.length > 0) ? (
                        <Stack 
                          direction={{ xs: 'column', sm: 'row' }} 
                          spacing={{ xs: 0.5, sm: 2 }} 
                          alignItems={{ xs: 'center', sm: 'flex-start' }}
                          sx={{ 
                            textAlign: { xs: 'center', sm: 'left' },
                            width: '100%'
                          }}
                        >
                          <Typography 
                            sx={{ 
                              minWidth: { sm: 60 },
                              fontSize: { xs: '0.875rem', sm: '1rem' },
                              color: theme => theme.palette.mode === 'dark' ? 'grey.300' : 'grey.700',
                              fontWeight: 600,
                            }}
                          >
                            {t('movieDetail', 'director')}:
                          </Typography>
                          <Stack 
                            direction="row" 
                            spacing={1} 
                            flexWrap="wrap" 
                            sx={{ 
                              gap: 1,
                              justifyContent: { xs: 'center', sm: 'flex-start' },
                              width: '100%',
                              '& .MuiChip-root': {
                                bgcolor: theme => alpha(theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black, 0.15),
                                color: theme => theme.palette.mode === 'dark' ? 'common.white' : 'common.black',
                                fontWeight: 500,
                                '&:hover': {
                                  bgcolor: theme => alpha(theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black, 0.25),
                                }
                              }
                            }}
                          >
                            {processedMovie.vod_director.slice(0, isMobile ? 2 : undefined).map((director) => (
                              <Chip
                                key={director}
                                label={director}
                                size={isMobile ? "small" : "medium"}
                                onClick={() => navigate(`/browse?director=${encodeURIComponent(director)}`)}
                              />
                            ))}
                            {isMobile && processedMovie.vod_director.length > 2 && (
                              <Chip
                                label={`+${processedMovie.vod_director.length - 2}`}
                                size="small"
                                sx={{
                                  bgcolor: alpha(theme.palette.common.white, 0.05),
                                  color: 'grey.400',
                                  height: 28,
                                  fontSize: '0.75rem',
                                  border: '1px solid',
                                  borderColor: alpha(theme.palette.common.white, 0.1),
                                }}
                              />
                            )}
                          </Stack>
                        </Stack>
                      ) : (
                        <Stack 
                          direction={{ xs: 'column', sm: 'row' }} 
                          spacing={{ xs: 0.5, sm: 2 }} 
                          alignItems={{ xs: 'center', sm: 'flex-start' }}
                          sx={{ 
                            textAlign: { xs: 'center', sm: 'left' },
                            width: '100%'
                          }}
                        >
                          <Typography 
                            sx={{ 
                              minWidth: { sm: 60 },
                              fontSize: { xs: '0.875rem', sm: '1rem' },
                              color: theme => theme.palette.mode === 'dark' ? 'grey.300' : 'grey.700',
                              fontWeight: 600,
                            }}
                          >
                            {t('movieDetail', 'director')}:
                          </Typography>
                          <Typography 
                            sx={{ 
                              fontSize: { xs: '0.875rem', sm: '1rem' },
                              color: 'text.secondary',
                            }}
                          >
                            暂无数据
                          </Typography>
                        </Stack>
                      )}

                      {/* Cast Info */}
                      {(processedMovie.vod_actor && processedMovie.vod_actor.length > 0) ? (
                        <Stack 
                          direction={{ xs: 'column', sm: 'row' }} 
                          spacing={{ xs: 0.5, sm: 2 }} 
                          alignItems={{ xs: 'center', sm: 'flex-start' }}
                          sx={{ 
                            textAlign: { xs: 'center', sm: 'left' },
                            width: '100%'
                          }}
                        >
                          <Typography 
                            sx={{ 
                              minWidth: { sm: 60 },
                              fontSize: { xs: '0.875rem', sm: '1rem' },
                              color: theme => theme.palette.mode === 'dark' ? 'grey.300' : 'grey.700',
                              fontWeight: 600,
                            }}
                          >
                            {t('movieDetail', 'cast')}:
                          </Typography>
                          <Stack 
                            direction="row" 
                            spacing={1} 
                            flexWrap="wrap" 
                            sx={{ 
                              gap: 1,
                              justifyContent: { xs: 'center', sm: 'flex-start' },
                              width: '100%',
                              '& .MuiChip-root': {
                                bgcolor: theme => alpha(theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black, 0.15),
                                color: theme => theme.palette.mode === 'dark' ? 'common.white' : 'common.black',
                                fontWeight: 500,
                                '&:hover': {
                                  bgcolor: theme => alpha(theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black, 0.25),
                                }
                              }
                            }}
                          >
                            {processedMovie.vod_actor.slice(0, isMobile ? 3 : undefined).map((actor) => (
                              <Chip
                                key={actor}
                                label={actor}
                                size={isMobile ? "small" : "medium"}
                                onClick={() => navigate(`/browse?actor=${encodeURIComponent(actor)}`)}
                              />
                            ))}
                            {isMobile && processedMovie.vod_actor.length > 3 && (
                              <Chip
                                label={`+${processedMovie.vod_actor.length - 3}`}
                                size="small"
                                sx={{
                                  bgcolor: alpha(theme.palette.common.white, 0.1),
                                  color: 'grey.400',
                                  height: 28,
                                  fontSize: '0.75rem',
                                  backdropFilter: 'blur(8px)',
                                  border: '1px solid',
                                  borderColor: alpha(theme.palette.common.white, 0.1),
                                }}
                              />
                            )}
                          </Stack>
                        </Stack>
                      ) : (
                        <Stack 
                          direction={{ xs: 'column', sm: 'row' }} 
                          spacing={{ xs: 0.5, sm: 2 }} 
                          alignItems={{ xs: 'center', sm: 'flex-start' }}
                          sx={{ 
                            textAlign: { xs: 'center', sm: 'left' },
                            width: '100%'
                          }}
                        >
                          <Typography 
                            sx={{ 
                              minWidth: { sm: 60 },
                              fontSize: { xs: '0.875rem', sm: '1rem' },
                              color: theme => theme.palette.mode === 'dark' ? 'grey.300' : 'grey.700',
                              fontWeight: 600,
                            }}
                          >
                            {t('movieDetail', 'cast')}:
                          </Typography>
                          <Typography 
                            sx={{ 
                              fontSize: { xs: '0.875rem', sm: '1rem' },
                              color: 'text.secondary',
                            }}
                          >
                            暂无数据
                          </Typography>
                        </Stack>
                      )}
                    </Stack>

                    {/* Action Buttons */}
                    <Stack 
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1.5, sm: 2 }} 
                      alignItems="center"
                      sx={{ 
                        mt: { xs: 2, sm: 3 },
                        width: '100%'
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        startIcon={<PlayArrowIcon />}
                        onClick={() => navigate(`/watch/${processedMovie.vod_id}?route=${selectedRoute}&ep=${selectedEpisode}`)}
                        fullWidth={isMobile}
                        sx={{
                          bgcolor: theme => theme.palette.mode === 'dark' ? 'primary.main' : 'primary.main',
                          color: '#fff',
                          px: { xs: 4, sm: 4 },
                          py: { xs: 1.75, sm: 1.5 },
                          borderRadius: { xs: 3, sm: 2 },
                          fontWeight: 600,
                          textTransform: 'none',
                          fontSize: { xs: '1.1rem', sm: '1.1rem' },
                          width: { xs: '100%', sm: 'auto' },
                          maxWidth: { xs: '320px', sm: 'none' },
                          boxShadow: theme => `0 8px 32px ${alpha(theme.palette.primary.main, 0.3)}`,
                          transition: 'all 0.3s ease-in-out',
                          '&:hover': {
                            bgcolor: theme => theme.palette.mode === 'dark' ? 'primary.dark' : 'primary.dark',
                            transform: 'translateY(-2px)',
                            boxShadow: theme => `0 12px 48px ${alpha(theme.palette.primary.main, 0.4)}`,
                          }
                        }}
                      >
                        {t('movieDetail', 'playNow')}
                      </Button>

                      {/* Action Icons */}
                      <Stack 
                        direction="row" 
                        spacing={2}
                        sx={{ 
                          width: { xs: '100%', sm: 'auto' },
                          justifyContent: 'center',
                          mt: { xs: 1, sm: 0 }
                        }}
                      >
                        <IconButton
                          size={isMobile ? "large" : "large"}
                          onClick={handleShare}
                          sx={{
                            bgcolor: theme => alpha(
                              theme.palette.mode === 'dark' ? '#fff' : '#000',
                              theme.palette.mode === 'dark' ? 0.08 : 0.04
                            ),
                            color: theme => theme.palette.mode === 'dark' ? '#fff' : theme.palette.grey[900],
                            backdropFilter: 'blur(20px)',
                            WebkitBackdropFilter: 'blur(20px)',
                            border: theme => `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                            '&:hover': {
                              bgcolor: theme => alpha(
                                theme.palette.mode === 'dark' ? '#fff' : '#000',
                                theme.palette.mode === 'dark' ? 0.12 : 0.08
                              ),
                            }
                          }}
                        >
                          <ShareIcon />
                        </IconButton>
                        <IconButton
                          size={isMobile ? "large" : "large"}
                          onClick={handleFavoriteClick}
                          sx={{
                            bgcolor: theme => alpha(
                              theme.palette.mode === 'dark' ? '#fff' : '#000',
                              theme.palette.mode === 'dark' ? 0.08 : 0.04
                            ),
                            color: theme => theme.palette.mode === 'dark' ? '#fff' : theme.palette.grey[900],
                            backdropFilter: 'blur(20px)',
                            WebkitBackdropFilter: 'blur(20px)',
                            border: theme => `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                            '&:hover': {
                              bgcolor: theme => alpha(
                                theme.palette.mode === 'dark' ? '#fff' : '#000',
                                theme.palette.mode === 'dark' ? 0.12 : 0.08
                              ),
                            }
                          }}
                        >
                          {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                        </IconButton>
                        <IconButton
                          size={isMobile ? "large" : "large"}
                          onClick={handleWatchLaterClick}
                          sx={{
                            bgcolor: theme => alpha(
                              theme.palette.mode === 'dark' ? '#fff' : '#000',
                              theme.palette.mode === 'dark' ? 0.08 : 0.04
                            ),
                            color: theme => theme.palette.mode === 'dark' ? '#fff' : theme.palette.grey[900],
                            backdropFilter: 'blur(20px)',
                            WebkitBackdropFilter: 'blur(20px)',
                            border: theme => `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                            '&:hover': {
                              bgcolor: theme => alpha(
                                theme.palette.mode === 'dark' ? '#fff' : '#000',
                                theme.palette.mode === 'dark' ? 0.12 : 0.08
                              ),
                            }
                          }}
                        >
                          {isWatchLater ? <WatchLaterIcon /> : <WatchLaterOutlinedIcon />}
                        </IconButton>
                        <Tooltip title={t('movieDetail', 'reportIssue')} arrow>
                          <IconButton
                            size={isMobile ? "large" : "large"}
                            onClick={handleReportClick}
                            sx={{
                              bgcolor: theme => alpha(
                                theme.palette.mode === 'dark' ? '#fff' : '#000',
                                theme.palette.mode === 'dark' ? 0.08 : 0.04
                              ),
                              color: theme => theme.palette.mode === 'dark' ? '#fff' : theme.palette.grey[900],
                              backdropFilter: 'blur(20px)',
                              WebkitBackdropFilter: 'blur(20px)',
                              border: theme => `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                              '&:hover': {
                                bgcolor: theme => alpha(
                                  theme.palette.mode === 'dark' ? '#fff' : '#000',
                                  theme.palette.mode === 'dark' ? 0.12 : 0.08
                                ),
                              }
                            }}
                          >
                            <ReportProblemOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </Stack>
                </Fade>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Report Dialog */}
        <Dialog
          open={openReportDialog}
          onClose={() => setOpenReportDialog(false)}
          PaperProps={{
            sx: {
              borderRadius: 2,
              width: '100%',
              maxWidth: { xs: '90%', sm: '400px' },
              bgcolor: theme => theme.palette.mode === 'dark' ? 'background.paper' : 'background.paper',
              backgroundImage: 'none',
              backdropFilter: 'blur(20px)',
              border: theme => `1px solid ${alpha(theme.palette.divider, 0.1)}`,
            }
          }}
        >
          <DialogTitle sx={{ 
            pb: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: 'warning.main'
          }}>
            <ReportProblemOutlinedIcon color="warning" />
            {t('movieDetail', 'reportIssueTitle')}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {t('movieDetail', 'reportIssueDescription')}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t('movieDetail', 'reportIssueNote')}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button 
              onClick={() => setOpenReportDialog(false)}
              sx={{ color: 'text.secondary' }}
            >
              {t('common', 'cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={handleReportConfirm}
              color="warning"
              startIcon={<ReportProblemOutlinedIcon />}
            >
              {t('movieDetail', 'confirmReport')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Content Tabs */}
        <Container 
          maxWidth={false} 
          sx={{ 
            mt: { xs: 2, sm: 3 },
            mx: { xs: 0, sm: 3, md: 4 },
            px: 0,
          }}
        >
          <Paper 
            elevation={0} 
            sx={{ 
              bgcolor: theme => alpha(theme.palette.background.paper, 0.8),
              borderRadius: { xs: 0, sm: '16px' },
              overflow: 'hidden',
              border: theme => `1px solid ${alpha(theme.palette.divider, 0.1)}`,
              backdropFilter: 'blur(20px)',
              WebkitBackdropFilter: 'blur(20px)',
            }}
          >
            <Tabs
              value={currentTab}
              onChange={(_, value) => setCurrentTab(value)}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                px: { xs: 1, sm: 2 },
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 500,
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  minHeight: { xs: 48, sm: 56 },
                  color: 'text.secondary',
                  '&.Mui-selected': {
                    color: 'primary.main',
                  },
                },
                '& .MuiTabs-indicator': {
                  height: 3,
                  borderRadius: '3px 3px 0 0',
                },
              }}
            >
              <Tab 
                icon={<TheatersIcon />} 
                iconPosition="start" 
                label={t('movieDetail', 'episodes')}
                sx={{ minHeight: { xs: 48, sm: 56 } }}
              />
            </Tabs>

            {/* Episodes */}
            {currentTab === 0 && (
              <Box sx={{ 
                p: { xs: 1.5, sm: 3 },
                '& .MuiChip-root': {
                  height: { xs: 32, sm: 36 },
                  fontSize: { xs: '0.875rem', sm: '1rem' }
                }
              }}>
                {processedMovie?.available_routes?.length > 0 && (
                  <RouteSelector
                    routes={processedMovie.available_routes}
                    selectedRoute={selectedRoute}
                    onRouteSelect={handleRouteSelect}
                    routeNames={routeNames}
                  />
                )}

                {groupedEpisodes.map((season, index) => (
                  <Box key={index} sx={{ mb: groupedEpisodes.length > 1 ? 4 : 0 }}>
                    {groupedEpisodes.length > 1 && (
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          mb: 2,
                          fontWeight: 600,
                          color: 'text.primary',
                          fontSize: { xs: '1.125rem', sm: '1.25rem' }
                        }}
                      >
                        第 {season.season} 季
                      </Typography>
                    )}
                    <EpisodeSelector
                      episodes={season.episodes}
                      selectedEpisode={selectedEpisode}
                      selectedRoute={selectedRoute}
                      onEpisodeSelect={handleEpisodeSelect}
                      video={processedMovie}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </Paper>
        </Container>
      </Layout>
      <Snackbar
        open={Boolean(snackbarMessage)}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          bottom: { xs: '24px', sm: '32px' },
          width: { xs: 'calc(100% - 32px)', sm: 'auto' },
          maxWidth: { xs: '100%', sm: '400px' }
        }}
        ContentProps={{
          sx: {
            bgcolor: theme => snackbarMessage === t('common', 'pleaseLoginFirst') 
              ? alpha(theme.palette.error.main, theme.palette.mode === 'dark' ? 0.9 : 0.95)
              : theme.palette.mode === 'dark' 
                ? alpha(theme.palette.primary.main, 0.85)
                : alpha(theme.palette.primary.main, 0.9),
            color: 'common.white',
            fontWeight: { xs: 400, sm: 500 },
            fontSize: { xs: '0.9375rem', sm: '1rem' },
            px: { xs: 2, sm: 3 },
            py: { xs: 1.25, sm: 1.5 },
            borderRadius: { xs: 1.5, sm: 2 },
            backdropFilter: 'blur(8px)',
            border: 1,
            borderColor: theme => alpha(theme.palette.common.white, theme.palette.mode === 'dark' ? 0.1 : 0.15),
            boxShadow: theme => theme.palette.mode === 'dark'
              ? `0 4px 20px ${alpha('#000', 0.4)}`
              : `0 4px 16px ${alpha(theme.palette.primary.main, 0.16)}`,
            '& .MuiSnackbarContent-message': {
              textAlign: 'center',
              width: '100%',
              lineHeight: 1.5
            }
          }
        }}
      />
    </MovieContext.Provider>
  );
};

export default MovieDetail; 